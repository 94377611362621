.home {
    position: relative;
    background-size: 60vw;
    &Header {
        position: relative;
        z-index: 1;
        padding-top: 120px;
        min-height: 950px;

        &Carousel {
            opacity: 0;

            &.slick-initialized {
                opacity: 1;
            }

            .slick-track {
                display: flex;
                align-items: center;
            }

            .slick-dots {
                bottom: -35px;

                li {
                    width: 10px;
                    height: 10px;
                    margin: 0 5px;

                    &.slick-active {
                        button {
                            background: $cPrimary;
                        }
                    }

                    button {
                        width: 10px;
                        height: 10px;
                        background: #cccccc;
                        padding: 0;
                        @include border-radius(50%);

                        &:before {
                            display: none;
                        }
                    }
                }
            }
            .slick-prev, .slick-next {
                font-size: 30px;
                color: white;
                i {
                    text-shadow: 1px 1px 10px rgba(0,0,0,0.5);
                }
                &:before {
                    display: none;
                }
            }

            &Item {
                display: flex;
                align-items: center;
                & > div {
                    flex: 1;

                    &:first-child {
                        margin-right: 20px;
                        max-width: 435px;
                    }
                }

                &Logo {
                    margin-bottom: 35px;
                    max-width: 150px;
                    display: inline-block !important;
                }

                &Picture {
                    max-height: 455px;
                    margin: 0 auto;
                }

                &Title {
                    font-size: 2.8rem;
                    font-weight: 700;
                    color: $cPrimary;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 1.6rem;
                    line-height: 2rem;
                    color: $cSecondary;
                }

                .btn-primary {
                    margin-top: 10px;
                }
            }

            &Logo {
                max-width: 110px;
                margin: 0 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                position: relative;
                border: 1px solid transparent;
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                opacity: 0.28;
                @include transition(all 0.2s);

                img {
                    max-height: 65px;
                }

                &:after {
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $cPrimary;
                    position: absolute;
                    bottom: -15px;
                    left: 50%;
                    @include transform(translateX(-50%));
                    display: none;
                }

                &.active,
                &:hover {
                    border-color: $cPrimary;
                    filter: none;
                    -webkit-filter: none;
                    opacity: 1;

                    &:after {
                        display: block;
                    }
                }

                &Container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 50px;
                }
            }
        }
    }
    &Equation {
        .media  {
            i {
                font-size: 2.5rem;
                color: white;
                margin-right: 20px;
            }
        }
    }
}
