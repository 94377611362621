@media screen and (max-width: 991px) {
    .homeHeaderCarouselItem {
        &Title {
            font-size: 2rem;
        }
        p {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
    }
}

@media screen and (max-width: 767px) {
    .homeHeaderCarouselItem {
        display: block;
        padding: 0 30px;
    }
    .slick-next {
        right: -10px;
    }
    .slick-prev {
        left: -10px;
    }
    .h2, h2 {
        font-size: 1.6rem;
    }
    .card-style08 {
        padding: 30px 20px 20px;
    }
    section {
        padding: 60px 0 30px;
    }
}

@media screen and (max-width: 575px) {
    .h2, h2, .text-content h2 {
        font-size: 1.4rem;
    }
    .h3, h3 {
        font-size: 1.2rem;
    }
    .navbar-brand img {
        max-height: 33px;
    }
    .card {
        h3 {
            font-size: 1.4rem;
        }
    }
    section ul:not(.slick-dots) li {
        font-size: 13px;
    }
}
