@import './abstract/mixins';
@import './abstract/helpers';
@import './abstract/variables';

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import '../amava/css/plugins.css';
@import '../amava/css/styles.scss';
@import '../amava/css/custom.scss';

@import './pages/_homepage.scss';
@import './pages/_news.scss';
@import './_responsive.scss';
