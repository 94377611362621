/*-----------------------------------------------------------------------------------
 *
 * Theme Name: Amava - Startup Agency and SasS Business Template
 * Description: Startup Agency and SasS Business Template
 * Author: Chitrakoot Web
 * Version: 3.0
 *
 * ----------------------------------------------------------------------------------- */
/* ----------------------------------
 *
 * Table of contents
 *
 * 01. Common styles
 * 02. Listing styles
 * 03. Button styles
 * 04. Navigation
 * 05. Section heading
 * 06. Main banner styles
 * 07. Page title
 * 08. Card styles
 * 09. Video
 * 10. Mobile App Demo
 * 11. Client section
 * 12. Counter box
 * 13. Testmonials
 * 14. Owl carousel
 * 15. Team styles
 * 16. Project grid
 * 17. Portfolio
 * 18. Accordion style
 * 19. Tabs
 * 20. Pricing table
 * 21. Widget block
 * 22. Other
 * 23. Blog
 * 24. Contact
 * 25. Footer
 *
 * ---------------------------------- */

$PrimaryColor: #00a3b5;
$PrimaryRgbColor: rgb(41, 45, 194);
$SecondaryColor: #0084e9;

$desk-xl-screen: 1599px;
$desk-ml-screen: 1400px;
$desk-lg-screen: 1399px;
$desk-md-screen: 1199px;
$desk-small-screen: 1024px;
$desk-sm-screen: 992px;
$desk-xs-screen: 991px;
$tablet-xs-screen: 767px;
$mobile-sm-screen: 575px;

/*==============================
 *    Common styles
 *    ================================ */

/* loader */
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: #fff;
  display: table;
  text-align: center;
}

.loader {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  width: 200px;
  height: 200px;
}

.loader-icon {
  width: 80px;
  height: 80px;
  border: 5px solid $PrimaryColor;
  border-right-color: #eee;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate 1s linear infinite;
  margin: 0 auto;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* theme color and basic options */
a {
  &:hover, &:active, &:focus {
    color: $PrimaryColor;
    text-decoration: none;
  }
}

.bg-theme, .theme-overlay[data-overlay-dark]:before {
  background: $PrimaryColor;
  background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
}

.theme-overlay-180[data-overlay-dark]:before {
  background: $PrimaryColor;
  background: -webkit-linear-gradient(180deg, $PrimaryColor, $SecondaryColor);
  background: -moz-linear-gradient(180deg, $PrimaryColor, $SecondaryColor);
  background: -o-linear-gradient(180deg, $PrimaryColor, $SecondaryColor);
  background: linear-gradient(180deg, $PrimaryColor, $SecondaryColor);
}

.theme-overlay-90[data-overlay-dark]:before, .bg-theme-90 {
  background: $PrimaryColor;
  background: -webkit-linear-gradient(90deg, $PrimaryColor, $SecondaryColor);
  background: -moz-linear-gradient(90deg, $PrimaryColor, $SecondaryColor);
  background: -o-linear-gradient(90deg, $PrimaryColor, $SecondaryColor);
  background: linear-gradient(90deg, $PrimaryColor, $SecondaryColor);
}

.bg-theme-solid, .theme-overlay-solid[data-overlay-dark]:before {
  background: $PrimaryColor;
}

.bg-theme-lighter {
  background: rgba($PrimaryRgbColor, 0.15);
}

.bg-theme-light {
  background: #f5fafe;
}

.text-purple {
  color: $PrimaryColor;
}

.text-default {
  color: #575a7b;
}

.h-100vh {
  height: 100vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.badge {
  font-size: 13px;
  padding: 5px 20px;
  line-height: 1.6;
  font-weight: 600;
}

.badge-primary-soft {
  background-color: rgba($PrimaryRgbColor, 0.1);
  color: $PrimaryColor;
}

.badge-white-soft {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.header-position {
  margin-top: -85px;
}

.text-yellow {
  color: #ffcc6f;
}

.text-green {
  color: #06df9e;
}

.text-orange {
  color: #ff6262;
}

.text-blue {
  color: #15b2ec;
}

.text-color-md-blue {
  color: #243f67;
}

.text-color-light-blue {
  color: #596982;
}

.bg-purple {
  background: rgba($PrimaryRgbColor, 0.2);
}

.bg-yellow {
  background: rgba(255, 204, 111, 0.2);
}

.bg-green {
  background: rgba(6, 223, 158, 0.1);
}

.bg-orange {
  background: rgba(255, 98, 98, 0.1);
}

.bg-blue {
  background: rgba(21, 178, 236, 0.1);
}

.separator-dashed-line-horrizontal-full {
  border-bottom: 1px dashed #cee1f8 !important;
}

.text-primary {
  color: $PrimaryColor !important;
}

.text-white-90 {
  color: rgba(255, 255, 255, 0.9);
}

.text-white-80 {
  color: rgba(255, 255, 255, 0.8);
}

.text-white-70 {
  color: rgba(255, 255, 255, 0.7);
}

.text-white-60 {
  color: rgba(255, 255, 255, 0.6);
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5);
}

.hover-white:hover {
  color: #ffffff;
}

.line-height-normal {
  line-height: normal;
}

.text-highlight {
  padding: 0;
  background-image: linear-gradient(45deg, rgba($PrimaryRgbColor, 0.22) 0, rgba(0, 132, 233, 0.24) 100%);
  background-repeat: no-repeat;
  background-position: 0 80%;
  position: relative;
  animation-delay: 1s;
  background-size: 100% .3em;
}

.read-more {
  font-size: 14px;
  color: $PrimaryColor;
  letter-spacing: 0.8px;
  font-weight: 500;

  &:hover {
    color: #1d184a;
  }
}

.transition-hover {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover, &:focus {
    transform: translateY(-8px);
  }
}

.service-icon {
  height: 50px;
  width: 50px;
  line-height: 52px;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin-right: 15px;
  color: $PrimaryColor;
  font-size: 22px;
  transition: all 0.3s ease 0s;
  background: rgba($PrimaryRgbColor, 0.15);
}

.hover-icon:hover .service-icon {
  background: $PrimaryColor;
  background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  color: #fff;
  transition: all 0.3s ease 0s;
}

.img-effect {
  transition-duration: .3s;
  transition-property: transform;
  transition-timing-function: ease-out;

  &:hover {
    transform: translateY(-8px);
  }
}

.hover-style1 {
  transition: all .3s ease-in-out;

  &:hover {
    transform: translate3d(-10px, 0, 0);
  }
}

.hover-style2 {
  transition: all .3s ease-out;

  &:hover {
    box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.18);
    transform: translate(0, -10px);
  }
}

.hover-style3 {
  transition: all .3s ease-in-out;

  &:hover {
    transform: translate(0, -10px);
  }
}

.hover-style4 {
  transition: all .3s ease-in-out;

  &:hover {
    transform: translate3d(10px, 0, 0);
  }
}

.shadow-primary {
  -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.line-through {
  text-decoration: line-through;
}

.cursor-pointer {
  cursor: pointer;
}

.height-100vh {
  height: 100vh;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.small-title {
  font-size: 18px;
  line-height: 34px;
}

.inner-title {
  font-size: 16px;
  line-height: 34px;
}

.sm-avatar {
  width: 2.75rem;
  height: 2.75rem;
}

.md-avatar {
  width: 3.75rem;
  height: 3.75rem;
}

.story-video {
  height: 100%;
}

.min-height-auto {
  min-height: auto;
}

.title-box {
  position: relative;
  padding: 20px 0;
  font-size: 60px;
  line-height: 1;
  width: 100px;
  text-align: right;
}

.box-circle {
  left: 0;
  bottom: 0;
  width: 1.37em;
  height: 1.37em;
  position: absolute;
  border-radius: 50%;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}

@media screen and (min-width: $desk-ml-screen) {
  .container.lg-container {
    max-width: 80%;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .header-position {
    margin-top: -71px;
  }

  .title-box {
    font-size: 54px;
    width: 95px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .inner-title {
    font-size: 15px;
    line-height: 30px;
  }

  .small-title {
    font-size: 16px;
    line-height: 30px;
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .title-box {
    font-size: 48px;
    width: 85px;
  }
}

/* scroll to top */
.scroll-to-top {
  font-size: 20px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  border-radius: 50%;
  background: #000;
  border: 1px solid #2a2a2a;
  width: 35px;
  height: 35px;
  line-height: 30px;
  z-index: 9999;
  outline: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all 0.3s ease;

  i {
    color: #fff;
  }

  &:hover {
    color: #1d184a;
    background: #fff;

    i {
      color: #1d184a;
    }
  }

  &:visited {
    color: #1d184a;
    text-decoration: none;
  }
}

/* infinite animation */
.infinite-floating {
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-name: Floating;
  animation-timing-function: ease-in-out;

  &.right-top {
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 0;
  }

  &.right-bottom {
    position: absolute;
    right: 15px;
    bottom: -50px;
    z-index: 0;
  }

  &.left-top {
    position: absolute;
    left: -50px;
    top: -50px;
    z-index: 0;
  }

  .circle {
    border: 3rem solid rgba(255, 255, 255, 0.1);
    display: inline-block;
    border-radius: 100%;
    width: 22rem;
    height: 22rem;
  }

  &.medium .circle {
    border: 5rem solid rgba(255, 255, 255, 0.05);
    width: 32rem;
    height: 32rem;
  }

  &.small .circle {
    border: 3rem solid rgba(255, 255, 255, 0.05);
    width: 16rem;
    height: 16rem;
  }
}

@keyframes Floating {
  0% {
    transform: translate(0px, 0px);
  }

  65% {
    transform: translate(0px, 30px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@media screen and (max-width: $desk-md-screen) {
  .infinite-floating {
    .circle {
      width: 20rem;
      height: 20rem;
    }

    &.medium .circle {
      width: 30rem;
      height: 30rem;
    }

    &.small .circle {
      width: 14rem;
      height: 14rem;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .infinite-floating {
    .circle {
      width: 18rem;
      height: 18rem;
    }

    &.medium .circle {
      width: 26rem;
      height: 26rem;
      border-width: 4rem;
    }

    &.small .circle {
      width: 12rem;
      height: 12rem;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .infinite-floating {
    .circle {
      width: 15rem;
      height: 15rem;
      border-width: 2rem;
    }

    &.medium .circle {
      width: 22rem;
      height: 22rem;
      border-width: 3rem;
    }

    &.small .circle {
      width: 10rem;
      height: 10rem;
      border-width: 2rem;
    }
  }
}

/* icon styles */
.icon-box {
  position: relative;

  .box-circle {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 82px;
    height: 82px;
    background: rgba(78, 74, 200, 0.1);
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  i {
    font-size: 48px;
    margin-bottom: 20px;
    display: inline-block;
  }

  &:hover .box-circle {
    transform: scale(1.2);
  }

  .text-yellow {
    color: #ffcc6f;
  }

  .text-green {
    color: #06df9e;
  }

  .text-orange {
    color: #ff6262;
  }

  .text-blue {
    color: #15b2ec;
  }

  .bg-yellow {
    background: rgba(255, 204, 111, 0.1);
  }

  .bg-green {
    background: rgba(6, 223, 158, 0.1);
  }

  .bg-orange {
    background: rgba(255, 98, 98, 0.1);
  }

  .bg-blue {
    background: rgba(21, 178, 236, 0.1);
  }
}

/* icon square */
.icon-square {
  position: relative;
  text-align: center;
  border: 2px solid transparent;
  vertical-align: top;
  width: 60px;
  height: 60px;
  background: rgba($PrimaryRgbColor, 0.1);
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  line-height: 70px;
  color: $PrimaryColor;

  &:after {
    width: 73px;
    height: 73px;
    content: "";
    position: absolute;
    top: -9px;
    left: -9px;
    opacity: 0.8;
    border-radius: 4px;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition: all 0.3s;
    border: 2px solid transparent;
  }

  &:hover:after {
    border-color: $PrimaryColor;
    -webkit-transform: scale(1);
    transform: scale(1);
    border: 2px solid;
  }

  i {
    font-size: 28px;
  }

  &.yellow {
    background: rgba(255, 204, 111, 0.1);
    color: #ffcc6f;
  }

  &.green {
    background: rgba(6, 223, 158, 0.1);
    color: #06df9e;
  }

  &.orange {
    background: rgba(255, 98, 98, 0.1);
    color: #ff6262;
  }

  &.blue {
    background: rgba(21, 178, 236, 0.1);
    color: #15b2ec;
  }
}

/* circle btn */
.circle-btn {
  a {
    display: inline-block;
    border: 1px solid #243f67;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;

    &:hover {
      color: #fff;
      background: #243f67;
      border-color: #243f67;

      i {
        color: #fff;
      }
    }
  }

  i {
    color: #243f67;
  }
}

/* icon circle */
.icon-circle {
  vertical-align: top;
  width: 75px;
  height: 75px;
  background: rgba($PrimaryRgbColor, 0.1);
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 85px;
  color: $PrimaryColor;

  i, span {
    font-size: 28px;
  }

  &.yellow {
    background: rgba(255, 204, 111, 0.1);
    color: #ffcc6f;
  }

  &.green {
    background: rgba(6, 223, 158, 0.1);
    color: #06df9e;
  }

  &.orange {
    background: rgba(255, 98, 98, 0.1);
    color: #ff6262;
  }

  &.blue {
    background: rgba(21, 178, 236, 0.1);
    color: #15b2ec;
  }

  .text {
    font-size: 28px;
    line-height: 75px;
    font-weight: 600;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .icon-circle {
    width: 65px;
    height: 65px;
    line-height: 75px;

    .text {
      font-size: 24px;
      line-height: 65px;
    }
  }
}

 /* icon play */
.icon-play {
  width: 55px;
  height: 55px;
  border: solid 1px #fff;
  border-radius: 55px;
  display: inline-block;
  position: relative;

  &:before {
    content: "";
    width: 10px;
    height: 26px;
    border-left: 2px solid #fff;
    border-radius: 5px;
    position: absolute;
    top: 14px;
    left: 19px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 16px;
    left: 11px;
    width: 24px;
    height: 21px;
    -webkit-transform: rotate(45deg) skew(-5deg, -5deg);
    transform: rotate(45deg) skew(-5deg, -5deg);
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-radius: 5px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .icon-play {
    width: 50px;
    height: 50px;
    border-radius: 50px;

    &:before {
      width: 10px;
      height: 25px;
      top: 11px;
      left: 17px;
    }

    &:after {
      top: 13px;
      left: 9px;
      width: 22px;
      height: 21px;
    }
  }
}

/* icon style */
.icon-style {
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 20px;
  z-index: 3;
  text-align: center;
  display: inline-block;
  position: relative;
  transition: all 0.4s;
  color: $PrimaryColor;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 15px;
    background: rgba($PrimaryRgbColor, 0.15);
    z-index: -1;
    transform: rotate(45deg);
    transition: all 0.4s;
  }

  &:hover {
    color: #fff;

    &:after {
      background: $PrimaryColor;
      transform: rotate(0deg);
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .icon-style {
    font-size: 18px;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .icon-style {
    font-size: 16px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .icon-style {
    font-size: 14px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

/*==============================
 *    Listing styles
 *    ================================ */

/* primary listing */
.list-style {
  list-style: none;

  li {
    line-height: 24px;
    margin-bottom: 12px;
    position: relative;
    padding-left: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: 4px;
      top: 8px;
      width: 8px;
      height: 8px;
      background: $PrimaryColor;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -o-border-radius: 100px;
      border-radius: 100px;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0px;
      top: 4px;
      width: 16px;
      height: 16px;
      background: rgba($PrimaryRgbColor, 0.35);
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -o-border-radius: 100px;
      border-radius: 100px;
      z-index: 1;
    }

    &:hover {
      padding-left: 35px;
    }
  }
}

/* list style2 */
.list-style2 {
  list-style: none;

  li {
    line-height: 24px;
    margin-bottom: 10px;
    position: relative;
    padding-left: 25px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: 2px;
      top: 8px;
      width: 8px;
      height: 8px;
      background: $PrimaryColor;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px;
      z-index: 2;
      transform: rotate(45deg);
    }

    &:after {
      content: '';
      position: absolute;
      left: 0px;
      top: 6px;
      width: 12px;
      height: 12px;
      background: rgba($PrimaryRgbColor, 0.35);
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -o-border-radius: 4px;
      border-radius: 4px;
      z-index: 1;
      transform: rotate(45deg);
    }

    &:hover {
      padding-left: 35px;
    }
  }
}


/* list style3 */
.list-style3 {
  list-style: none;

  li {
    line-height: 24px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    position: relative;
    padding-left: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }

    &:before {
      content: '';
      position: absolute;
      left: 4px;
      top: 8px;
      width: 8px;
      height: 8px;
      background: $PrimaryColor;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -o-border-radius: 100px;
      border-radius: 100px;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0px;
      top: 4px;
      width: 16px;
      height: 16px;
      background: rgba($PrimaryRgbColor, 0.35);
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -o-border-radius: 100px;
      border-radius: 100px;
      z-index: 1;
    }

    &:hover {
      padding-left: 35px;
    }
  }
}

/* list style4 */
.list-style4 {
  list-style: none;
  padding: 0;

  li {
    font-weight: 500;
    position: relative;
    padding: 0 0 0 40px;
    margin: 0 0 14px 0;

    span {
      position: absolute;
      top: 3px;
      right: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: "\e64c";
      font-family: 'themify';
      color: $PrimaryColor;
      font-size: 12px;
      background: rgba(115, 193, 44, 0.1);
      margin-right: 12px;
      border-radius: 30px;
      padding: 6px;
      position: absolute;
      left: 0;
      top: 2px;
      line-height: 12px;
    }

    i {
      color: $PrimaryColor;
      font-size: 12px;
      background: rgba(115, 193, 44, 0.1);
      margin-right: 12px;
      border-radius: 30px;
      padding: 6px;
      position: absolute;
      left: 0;
      top: 2px;
      line-height: 12px;
    }
  }
}

/* list style5 */
.list-style5 {
  list-style: none;

  li {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    h3 {
      font-weight: 500;
    }

    .short-desc {
      display: inline-block;
      width: 80%;
      vertical-align: top;
      padding-left: 30px;

      p {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .list-style5 li {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .list-style5 li .short-desc {
    width: 75%;
    padding-left: 20px;
  }
}

/* list style6 */
.list-style6 {
  list-style: none;

  li {
    font-size: 16px;
    line-height: 34px;
    padding-left: 30px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: "\e64c";
      font-family: 'themify';
      font-size: 15px;
      position: absolute;
      top: 0;
      left: 0;
      line-height: 35px;
      color: #06df9e;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .list-style6 li {
    font-size: 15px;
    line-height: 32px;
  }
}

/* list style7 */
.list-style7 {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding: 0 0 8px 40px;
    margin: 0 0 8px 0;
    font-size: 16px;

    span {
      position: absolute;
      top: 3px;
      right: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    &:before {
      content: "";
    }

    i {
      color: $SecondaryColor;
      font-size: 12px;
      background: rgba(0, 132, 233, 0.1);
      margin-right: 12px;
      border-radius: 30px;
      padding: 6px;
      position: absolute;
      left: 0;
      top: 2px;
      line-height: 12px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .list-style7 li {
    font-size: 15px;
  }
}

/* list style8 */
.list-style8 {
  list-style: none;
  margin-bottom: 0;

  li {
    font-size: 15px;
    line-height: 30px;
    padding-left: 30px;
    position: relative;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: "\e64c";
      font-family: 'themify';
      font-size: 15px;
      position: absolute;
      top: 0;
      left: 0;
      line-height: 34px;
      color: #06df9e;
    }
  }

  &.icon-white li:before {
    color: #fff;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .list-style8 li {
    font-size: 14px;
    line-height: 30px;
  }
}

/* list style9 */
.list-style9 {
  margin-bottom: 0;
  padding-bottom: 0;

  li {
    border-right: 1px solid #d5d5d5;
    padding-right: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    display: inline-block;
    font-size: 14px;

    &:last-child {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }
  }
}

/*==============================
 *    Button styles
 *    ================================ */

/* common styles */
.butn {
  background: $PrimaryColor;
  background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  cursor: pointer;
  font-weight: 600;
  color: #fff;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 20px;
  padding: 14px 28px;
  border-radius: 30px;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  -moz-transform: perspective(1px) translateZ(0);
  -o-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  -webkit-transition: all linear 400ms;
  -moz-transition: all linear 400ms;
  -o-transition: all linear 400ms;
  transition: all linear 400ms;
  text-align: center;
  border: none;

  &:hover {
    color: #fff;
  }
}

a.butn {
  &:hover, &:active, &:focus {
    color: #fff;
  }
}

.butn {
  &:before {
    background: $PrimaryColor;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: inline-block;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 51%;
    -ms-transform-origin: 51%;
    -o-transform-origin: 51%;
    transform-origin: 51%;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    border-radius: 22px;
    border: none;
  }

  &:hover:before, &:focus:before, &:active:before {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
  }

  &.white {
    color: $PrimaryColor;
    background: #ffffff;
    background: -webkit-linear-gradient(-45deg, #e0e0e0, #ffffff);
    background: -moz-linear-gradient(-45deg, #e0e0e0, #ffffff);
    background: -o-linear-gradient(-45deg, #e0e0e0, #ffffff);
    background: linear-gradient(-45deg, #e0e0e0, #ffffff);

    &:hover {
      color: $PrimaryColor;
    }
  }
}

a.butn.white {
  &:hover, &:active, &:focus {
    color: $PrimaryColor;
  }
}

.butn {
  &.white:before {
    background: #fff;
  }

  &.small {
    font-size: 12px;
    line-height: 18px;
    padding: 12px 24px;
  }

  &.very-small {
    padding: 8px 20px;
    font-size: 11px;
  }
}

.btn.bordered {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}

.butn.grey {
  color: #666;
  background: #f0f0f0;
  display: inline-block;
  padding: 11px 28px;
  border-radius: 4px;
  line-height: 28px;
  font-weight: 500;
  text-transform: inherit;

  &.small {
    padding: 7px 20px;
  }

  &:before {
    content: none;
  }

  &:hover, &:active, &:focus, &.active {
    background: $PrimaryColor;
    color: #fff;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .butn {
    padding: 12px 24px;
    font-size: 13px;
  }
}

/* button style one */
.butn {
  &.style-one {
    color: $PrimaryColor;
    background: rgba($PrimaryRgbColor, 0.15);
    display: inline-block;
    padding: 11px 28px;
    border-radius: 4px;
    line-height: 28px;
    font-weight: 500;
    text-transform: inherit;

    &.small {
      padding: 7px 20px;
    }

    &:before {
      content: none;
    }

    &:hover, &:active, &:focus, &.active {
      background: $PrimaryColor;
      color: #fff;
    }

    &.fill {
      background: $PrimaryColor;
      color: #fff;
      box-shadow: 0px 15px 25px 0px rgba(0, 0, 30, 0.15);

      &:hover {
        box-shadow: none;
      }
    }

    &.yellow {
      color: #ffcc6f;
      background: rgba(255, 204, 111, 0.3);

      &:hover, &:active, &:focus, &.active {
        background: #ffcc6f;
        color: #fff;
      }
    }

    &.green {
      color: #06df9e;
      background: rgba(6, 223, 158, 0.15);

      &:hover, &:active, &:focus, &.active {
        background: #06df9e;
        color: #fff;
      }
    }

    &.orange {
      color: #ff6262;
      background: rgba(255, 98, 98, 0.15);

      &:hover, &:active, &:focus, &.active {
        background: #ff6262;
        color: #fff;
      }
    }

    &.blue {
      color: #15b2ec;
      background: rgba(21, 178, 236, 0.15);

      &:hover, &:active, &:focus, &.active {
        background: #15b2ec;
        color: #fff;
      }
    }
  }

  &.yellow-solid {
    color: #fff;
    background: rgba(255, 204, 111, 1);
    display: inline-block;
    padding: 11px 28px;
    border-radius: 4px;
    line-height: 28px;
    font-weight: 500;
    text-transform: inherit;
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 30, 0.15);

    &.small {
      padding: 7px 20px;
    }

    &:before {
      content: none;
    }

    &:hover, &:active, &:focus, &.active {
      background: rgba(255, 204, 111, 1);
      color: #fff;
    }

    &:hover {
      box-shadow: none;
    }
  }

  &.green-solid {
    color: #fff;
    background: rgba(6, 223, 158, 1);
    display: inline-block;
    padding: 11px 28px;
    border-radius: 4px;
    line-height: 28px;
    font-weight: 500;
    text-transform: inherit;
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 30, 0.15);

    &.small {
      padding: 7px 20px;
    }

    &:before {
      content: none;
    }

    &:hover, &:active, &:focus, &.active {
      background: rgba(6, 223, 158, 1);
      color: #fff;
    }

    &:hover {
      box-shadow: none;
    }
  }

  &.orange-solid {
    color: #fff;
    background: rgba(255, 98, 98, 1);
    display: inline-block;
    padding: 11px 28px;
    border-radius: 4px;
    line-height: 28px;
    font-weight: 500;
    text-transform: inherit;
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 30, 0.15);

    &.small {
      padding: 7px 20px;
    }

    &:before {
      content: none;
    }

    &:hover, &:active, &:focus, &.active {
      background: rgba(255, 98, 98, 1);
      color: #fff;
    }

    &:hover {
      box-shadow: none;
    }
  }

  &.blue-solid {
    color: #fff;
    background: rgba(21, 178, 236, 1);
    display: inline-block;
    padding: 11px 28px;
    border-radius: 4px;
    line-height: 28px;
    font-weight: 500;
    text-transform: inherit;
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 30, 0.15);

    &.small {
      padding: 7px 20px;
    }

    &:before {
      content: none;
    }

    &:hover, &:active, &:focus, &.active {
      background: rgba(21, 178, 236, 1);
      color: #fff;
    }

    &:hover {
      box-shadow: none;
    }
  }

  &.style-one.white {
    color: $PrimaryColor;
    background: rgba(255, 255, 255, 1);

    &:hover, &:active, &:focus, &.active {
      background: #fff;
      color: $PrimaryColor;
    }
  }
}

/* button style two */
.butn.style-two {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 4px;
  display: inline-block;
  padding: 11px 32px;
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
  text-transform: inherit;

  &:before {
    content: none;
  }

  &:hover, &:active, &:focus {
    background: #fff;
    border-radius: 4px;
    color: #1d184a;
  }

  &.reverse {
    background: transparent;
    color: #1d184a;
    border: 1px solid #5a5d80;
    border-radius: 4px;
    display: inline-block;
    padding: 11px 32px;
    font-size: 15px;
    line-height: 28px;
    font-weight: 500;
    text-transform: inherit;
  }

  &:before {
    content: none;
  }

  &.reverse {
    &:hover, &:active, &:focus, &:active {
      background: #1d184a;
      border-radius: 4px;
      color: #fff;
    }
  }

  &.small {
    padding: 6px 20px;
    font-size: 13px;
    line-height: 24px;
  }

  &.medium {
    padding: 8px 22px;
    font-size: 14px;
    line-height: 26px;
  }

  &.md-lg {
    padding: 11px 28px;
    font-size: 14px;
    line-height: 26px;
  }

  &.fill {
    background: $PrimaryColor;

    &:hover, &:active, &:focus {
      color: #fff;
      background: $SecondaryColor;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .butn.style-two {
    padding: 8px 24px;
  }
}

/* button style three */
.butn.style-three {
  color: #fff;
  display: inline-block;
  padding: 11px 28px;
  border-radius: 4px;
  line-height: 28px;
  font-weight: 500;
  text-transform: inherit;

  &:before {
    content: none;
  }

  &:hover, &:active, &:focus, &.active {
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 30, 0.15);
  }

  &.bordered {
    border: 1px solid $PrimaryColor;
    color: $PrimaryColor;

    &:hover {
      background: $PrimaryColor;
      border-color: transparent;
      color: #fff;
    }
  }
}

/*==============================
 *    Navigation
 *    ================================ */

.navbar-nav {
  li.current > a, > li.active > a {
    color: $PrimaryColor;
  }
}

.navbar {
  > ul > li.current > a:after {
    border-color: transparent $PrimaryColor $PrimaryColor transparent;
  }

  ul ul li.active > a {
    color: $PrimaryColor;
  }
}

.white-header-mobile .navbar-nav > li > a {
  font-size: 14px;
}

@media screen and (min-width: $desk-sm-screen) {
  .transparent-header {
    .navbar-nav > li {
      &.current > a, &.active > a {
        color: #fff;
      }
    }

    &.scrollHeader {
      .navbar-nav > li {
        &.active > a, &.current > a {
          color: $PrimaryColor;
        }
      }

      .navbar > ul > li.has-sub.current > a:after {
        border-color: transparent $PrimaryColor $PrimaryColor transparent;
      }
    }
  }

  .white-header-mobile .navbar-nav > li > a {
    padding: 21px 0.2rem;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .navbar ul ul li.active > a {
    color: $PrimaryColor;
  }

  .navbar-toggler {
    &:after {
      border-top: 2px solid $PrimaryColor;
      border-bottom: 2px solid $PrimaryColor;
    }

    &:before {
      background: $PrimaryColor;
    }

    &.menu-opened {
      &:after, &:before {
        background: $PrimaryColor;
      }
    }

    &.dark {
      background: $PrimaryColor;
    }
  }

  .white-header-mobile {
    .navbar-toggler {
      top: 14px;
      background: $PrimaryColor;

      &:after {
        border-color: #fff;
      }

      &:before, &.menu-opened:after {
        background: #fff;
      }
    }

    &.transparent-header li.search > a {
      color: #1d184a;
    }

    .attr-nav ul li.search {
      margin-top: 8px;
    }
  }

  .sm-bg-theme {
    background: $PrimaryColor;
    background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  }

  .sm-bg-theme-solid {
    background: $PrimaryColor;
  }

  .sm-bg-theme-90 {
    background: $PrimaryColor;
    background: -webkit-linear-gradient(90deg, $PrimaryColor, $SecondaryColor);
    background: -moz-linear-gradient(90deg, $PrimaryColor, $SecondaryColor);
    background: -o-linear-gradient(90deg, $PrimaryColor, $SecondaryColor);
    background: linear-gradient(90deg, $PrimaryColor, $SecondaryColor);
  }

  .sm-bg-theme-180 {
    background: $PrimaryColor;
    background: -webkit-linear-gradient(180deg, $PrimaryColor, $SecondaryColor);
    background: -moz-linear-gradient(180deg, $PrimaryColor, $SecondaryColor);
    background: -o-linear-gradient(180deg, $PrimaryColor, $SecondaryColor);
    background: linear-gradient(180deg, $PrimaryColor, $SecondaryColor);
  }
}

/* one page */
.onepage-header {
  &.scrollHeader .navbar {
    background: $PrimaryColor;
    background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  }

  &.dark {
    .navbar .icon-bar {
      color: #1d184a;
    }

    .navbar-nav .nav-link {
      color: #1d184a;
      font-size: 15px;
      font-weight: 500;
      text-transform: none;

      &:hover, &.active {
        color: $PrimaryColor;
        opacity: 1;
      }
    }

    &.scrollHeader {
      .navbar {
        background: $PrimaryColor;
      }

      .navbar-nav .nav-link {
        color: #fff;

        &:hover {
          color: #fff;
          opacity: 0.65;
        }
      }

      .navbar .navbar-nav .nav-link.active {
        color: #fff;
        opacity: 0.65;
      }
    }
  }
}

@media screen and (min-width: $desk-sm-screen) {
  .onepage-header.mobile-app .navbar .nav-item {
    margin-left: 15px;

    .nav-link {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .onepage-header {
    .navbar-nav .nav-link.active {
      color: $PrimaryColor;
    }

    &.scrollHeader {
      .navbar-nav .nav-link:hover, .navbar .navbar-nav .nav-link.active {
        color: $PrimaryColor;
      }
    }

    &.dark {
      &.scrollHeader {
        .navbar-nav .nav-link:hover, .navbar .navbar-nav .nav-link.active {
          color: $PrimaryColor;
          opacity: 1;
        }

        .navbar-nav .nav-link {
          color: #1d184a;
        }
      }

      .navbar-toggler {
        background: rgba($PrimaryRgbColor, 0.15);

        &:after {
          border-color: $PrimaryColor;
        }

        &:before {
          background: $PrimaryColor;
        }

        &.menu-opened {
          &:after, &:before {
            background: $PrimaryColor;
          }
        }
      }
    }

    &.scrollHeader.dark .navbar-toggler {
      background: #f4f4f4;

      &:after {
        border-color: $PrimaryColor;
      }

      &:before {
        background: $PrimaryColor;
      }

      &.menu-opened {
        &:after, &:before {
          background: $PrimaryColor;
        }
      }
    }
  }
}

/*==============================
 *    Section heading
 *    ================================ */

/* section heading */
.section-heading {
  margin-bottom: 50px;
  text-align: center;

  &.half {
    margin-bottom: 30px;
  }

  h1, h2, h3, h4, h5, h6 {
    position: relative;
    margin-bottom: 0;
    font-weight: 600;
    position: relative;
    line-height: 140%;
  }

  &.white {
    h1, h2, h3, h4, h5, h6 {
      color: #fff;
    }
  }

  &.grey {
    h1, h2, h3, h4, h5, h6 {
      color: #b7b7b7;
    }

    h1:after, h2:after, h3:after, h4:after, h5:after, h6:after {
      background: #b7b7b7;
    }
  }

  &.left {
    text-align: left;
  }

  strong {
    font-weight: 600;
  }

  &.white p {
    color: #fff;
  }

  p {
    font-size: 15px;
    font-weight: 400;
    padding-top: 15px;
    margin: 0 auto;
  }

  span {
    position: relative;
    font-size: 15px;
    font-weight: 500;
    color: $PrimaryColor;
    line-height: 200%;
    margin-bottom: 10px;
  }

  &.white span {
    color: #fff;
  }

  &.grey span {
    color: #b7b7b7;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .section-heading {
    margin-bottom: 30px;

    p {
      padding-top: 10px;
      font-size: 14px;
    }
  }
}

/* section heading style2 */
.section-heading2 {
  margin-bottom: 50px;
  text-align: center;

  &.half {
    margin-bottom: 30px;
  }

  h1, h2, h3, h4, h5, h6 {
    position: relative;
    padding-bottom: 15px;
    font-weight: 600;
    margin-bottom: 0;
    position: relative;
    line-height: 140%;
  }

  h1:after, h2:after, h3:after, h4:after, h5:after, h6:after {
    content: '';
    display: block;
    width: 100px;
    height: 2px;
    background: $PrimaryColor;
    background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.white {
    h1, h2, h3, h4, h5, h6 {
      color: #fff;
    }

    h1:after, h2:after, h3:after, h4:after, h5:after, h6:after {
      background: #fff;
    }
  }

  &.grey {
    h1, h2, h3, h4, h5, h6 {
      color: #b7b7b7;
    }

    h1:after, h2:after, h3:after, h4:after, h5:after, h6:after {
      background: #b7b7b7;
    }
  }

  &.left {
    margin-bottom: 30px;
    text-align: left;

    h1, h2, h3, h4, h5, h6 {
      padding-bottom: 15px;
    }

    h1:after, h2:after, h3:after, h4:after, h5:after, h6:after {
      margin: 0;
      left: 0;
    }
  }

  strong {
    font-weight: 600;
  }

  &.white p {
    color: #fff;
  }

  p {
    padding-top: 15px;
    margin: 0 auto;
  }

  span {
    position: relative;
    font-size: 15px;
    font-weight: 600;
    color: $PrimaryColor;
    line-height: 200%;
    margin-bottom: 5px;
    letter-spacing: 1px;
    display: inline-block;
  }

  &.white span {
    color: #fff;
  }

  &.grey span {
    color: #b7b7b7;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .section-heading2 {
    margin-bottom: 30px;

    span {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .section-heading2 p {
    font-size: 14px;
  }
}

/* section heading style3*/
.section-heading3 {
  text-align: center;
  margin-bottom: 50px;

  h1, h2, h3, h4, h5, h6 {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .section-heading3 {
    margin-bottom: 40px;

    h1, h2, h3, h4, h5, h6 {
      font-size: 36px;
      line-height: 48px;
    }

    p {
      font-size: 15px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .section-heading3 {
    margin-bottom: 35px;

    h1, h2, h3, h4, h5, h6 {
      font-size: 28px;
      line-height: 40px;
    }
  }
}

/* section heading style4*/
.section-title .title-count {
  position: absolute;
  text-align: center;
  font-weight: bolder;
  top: -18px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  font-size: 160px;
  z-index: 0;
  opacity: 0.1;
  color: #999;
  width: 160px;
  height: 160px;
  line-height: 160px;
}

@media screen and (max-width: $desk-xs-screen) {
  .section-title .title-count {
    font-size: 130px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .section-title .title-count {
    font-size: 110px;
    line-height: 110px;
  }
}

/* line title */
.line-title {
  position: relative;
  padding-bottom: 1.2rem;

  &:after {
    content: '';
    display: block;
    width: 75px;
    height: 2px;
    background: $PrimaryColor;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.white {
    color: #fff;

    &:after {
      background: #fff;
    }
  }
}

/*==============================
 *    Main banner styles
 *    ================================ */

/* main banner style01*/
.main-banner-style01 {
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: none;
    z-index: 3;
  }

  .right-bg {
    float: right;
    width: 55.76%;
    right: 1px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      background: $PrimaryColor;
      background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
      background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
      background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
      background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    }
  }

  .header-shape-bg {
    position: absolute;
    top: 0px;
    min-height: 500px;
    z-index: 2;
    width: 100%;
  }

  .header-text {
    position: absolute;
    width: 100%;
    top: 34%;
    z-index: 4;

    h1 {
      font-weight: 700;
      font-size: 36px;
      color: $PrimaryColor;
      line-height: 44px;
      letter-spacing: 1.4px;
      margin-bottom: 25px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 1px;
      margin-bottom: 30px;

      &.small {
        font-size: 12px;
      }
    }

    .email-box {
      width: 85%;
      position: relative;
      background: none;
      overflow: hidden;

      input {
        width: 99%;
        height: 45px;
        min-height: auto;
        margin-bottom: 7px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        -o-border-radius: 100px;
        border-radius: 100px;
        border: 1px solid rgba(78, 78, 84, 0.2);
        outline: none;
        padding-left: 20px;
        padding-right: 120px;
        z-index: 1;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.67px;
      }

      .butn {
        position: absolute;
        top: 0;
        right: 0px;
        z-index: 2;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        color: #fff;
        outline: none;
        cursor: pointer;
        padding: 13px 18px;
        border-top-left-radius: 0;
        box-shadow: none;
        border-bottom-left-radius: 0;

        &:hover:before, &:focus:before {
          padding: 14px 18px 14px 18px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      .input .butn:active:before {
        padding: 14px 18px 14px 18px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      span {
        display: block;
        font-size: 13px;
        padding-left: 5px;
      }
    }
  }

  .play-button-wrapper {
    position: absolute;
    width: 100%;
    top: 45%;
    -webkit-transform: perspective(1px) translateY(-45%);
    -moz-transform: perspective(1px) translateY(-45%);
    -ms-transform: perspective(1px) translateY(-45%);
    -o-transform: perspective(1px) translateY(-45%);
    transform: perspective(1px) translateY(-45%);
    z-index: 4;
    width: 55.5%;
    right: 0px;

    .btn-play {
      width: 60px;
      height: 60px;
      line-height: 56px;
      text-align: center;
      font-size: 22px;
      color: #fff;
      margin: auto;
      left: 14%;
      right: 0px;
      display: block;
      border: 2px solid #fff;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -o-border-radius: 100px;
      border-radius: 100px;
      position: absolute;
      overflow: hidden;
      top: 0;
      bottom: 0;
      transform: perspective(1px) translateY(-50%);
      z-index: 3;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        background: #fff;
        opacity: .19;
        z-index: 1;
      }

      i {
        padding-left: 5px;
        position: relative;
        z-index: 2;
      }
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .main-banner-style01 {
    .right-bg {
      right: 0;
    }

    .header-text {
      top: 30%;

      h1 {
        font-size: 32px;
        line-height: 42px;
      }

      .email-box {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .main-banner-style01 {
    .right-bg {
      width: 100%;
      max-height: 550px;
      height: 100%;
      min-height: 410px;

      &:before {
        opacity: 0.8;
      }
    }

    .header-shape-bg {
      display: none;
    }

    .header-text {
      top: 0;
      position: absolute;
      margin: 11rem 0;

      h1 {
        font-weight: 600;
        font-size: 26px;
        line-height: 34px;
        margin-bottom: 10px;
        color: #fff;
        text-align: center;
      }

      p {
        color: #fff;
        text-align: center;
        margin-bottom: 20px;
        line-height: 24px;
        font-size: 14px;
      }

      .email-box {
        width: 100%;

        span {
          color: #fff;
          text-align: center;
        }
      }
    }

    .play-button-wrapper {
      display: none;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .main-banner-style01 {
    .right-bg img {
      width: auto !important;
      max-width: 144%;
    }

    .header-text {
      margin: 10rem 0;

      h1 {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .main-banner-style01 .header-text {
    margin: 26% 0 50px 0;
  }
}

/* main banner style02 */
.main-banner-style02 {
  background: #edf5ff;
  padding: 15% 0;

  .banner-img img {
    position: absolute;
    left: -15px;
    top: -38%;
  }

  .btn-play {
    width: 50px;
    height: 50px;
    line-height: 46px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    margin: auto;
    display: inline-block;
    border: 2px solid #fff;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 3;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      background: #fff;
      opacity: .19;
      z-index: 1;
    }

    i {
      padding-left: 5px;
      position: relative;
      z-index: 2;
    }
  }

  .header-shape {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    img {
      max-width: none;
    }
  }

  .btn-play-theme {
    width: 50px;
    height: 50px;
    line-height: 46px;
    text-align: center;
    font-size: 18px;
    color: $PrimaryColor;
    margin: auto;
    display: inline-block;
    border: 2px solid $PrimaryColor;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 3;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      background: #fff;
      opacity: .19;
      z-index: 1;
    }

    i {
      padding-left: 5px;
      position: relative;
      z-index: 2;
    }
  }
}

@media screen and (max-width: $desk-lg-screen) {
  .main-banner-style02 {
    padding: 17% 0;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .main-banner-style02 .banner-img img {
    top: -18%;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .main-banner-style02 {
    padding: 100px 0 50px 0;

    .banner-img img {
      position: relative;
      width: auto;
      float: none !important;
      left: 0;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .main-banner-style02 {
    .btn-play, .btn-play-theme {
      width: 40px;
      height: 40px;
      line-height: 36px;
      font-size: 16px;
    }

    .header-shape {
      bottom: -2px;
    }
  }
}

/* main banner style03 */
.main-banner-style03 {
  padding: 15% 0 17% 0;

  .creative-banner-img {
    position: absolute;
    left: -15px;
    top: -50%;
  }

  .btn-play {
    width: 50px;
    height: 50px;
    line-height: 44px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    margin: auto;
    display: inline-block;
    border: 2px solid #fff;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 3;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      background: #fff;
      opacity: .19;
      z-index: 1;
    }

    i {
      padding-left: 5px;
      position: relative;
      z-index: 2;
    }
  }

  .wave-area {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: white;
    z-index: 1;
  }

  .wave {
    background: url("../img/banner/wave.svg") repeat-x;
    position: absolute;
    top: -100px;
    width: 6400px;
    height: 100px;
    animation: wave 35s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);

    &:nth-of-type(2) {
      top: -56px;
      animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
      opacity: 1;
    }
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0, -45px, 0);
  }

  50% {
    transform: translate3d(0, 5px, 0);
  }
}

@media screen and (max-width: $desk-lg-screen) {
  .main-banner-style03 {
    padding: 18% 0 22% 0;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .main-banner-style03 .creative-banner-img {
    top: -28%;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .main-banner-style03 {
    padding: 120px 0 50px 0;

    .creative-banner-img {
      position: relative;
      width: auto;
      float: none !important;
      left: 0;
      top: 0;
      text-align: center;

      img {
        float: none !important;
      }
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .main-banner-style03 {
    padding: 90px 0 50px 0;

    h1 {
      font-size: 2.0rem;
    }

    .btn-play i {
      padding-left: 3px;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .main-banner-style03 h1 {
    font-size: 1.8rem;
  }
}

/* main banner style04 */
.main-banner-style04 {
  position: relative;
  z-index: 1;

  .container {
    padding-top: 12%;
  }

  .banner-wrapper-position {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 50%;
    width: 100%;
    height: 100%;
  }

  .banner-wrapper {
    position: relative;
    z-index: 5;

    .slogan {
      font-weight: 600;
      letter-spacing: 0.5px;
      display: inline-block;
      background: rgba($PrimaryRgbColor, 0.07);
      border: 1px solid transparent;
      line-height: 32px;
      padding: 0 20px;
      font-size: 15px;
      border-radius: 18px;

      span {
        color: $PrimaryColor;
      }
    }

    .main-title {
      font-size: 60px;
      line-height: 80px;
      padding: 25px 0 35px;
      margin-bottom: 0;
      letter-spacing: 0;
    }

    .sub-title {
      font-size: 20px;
      line-height: 32px;
      padding-bottom: 28px;
    }
  }

  .btn-play {
    width: 50px;
    height: 50px;
    line-height: 46px;
    text-align: center;
    font-size: 18px;
    color: $PrimaryColor;
    margin: auto;
    display: inline-block;
    border: 2px solid $PrimaryColor;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 3;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      background: #fff;
      opacity: .19;
      z-index: 1;
    }

    i {
      padding-left: 5px;
      position: relative;
      z-index: 2;
    }

    &:hover {
      background: rgba($PrimaryRgbColor, 0.15);
    }
  }

  .screen-one {
    position: absolute;
    right: 8%;
    top: 12%;
    z-index: 1;
    width: 80%;
  }

  .screen-two {
    position: absolute;
    right: 24%;
    top: 27%;
    z-index: 1;
    width: 22%;
  }
}

@media screen and (max-width: $desk-xl-screen) {
  .main-banner-style04 .screen-one {
    top: 17%;
  }
}

@media screen and (max-width: $desk-lg-screen) {
  .main-banner-style04 .banner-wrapper .main-title {
    font-size: 54px;
    line-height: 70px;
    padding: 20px 0 30px;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .main-banner-style04 {
    .container {
      padding-top: 15%;
    }

    .screen-one {
      top: 25%;
    }

    .banner-wrapper {
      .main-title {
        font-size: 48px;
        line-height: 65px;
      }

      .sub-title {
        font-size: 18px;
        padding-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .main-banner-style04 {
    min-height: auto !important;

    .container {
      padding-top: 0;
      position: relative;
      left: 0;
      top: 0;
      transform: none;
    }

    .banner-wrapper-position {
      position: relative;
      margin: 90px auto 70px auto;
      max-width: 75%;
      text-align: center;
    }

    .banner-wrapper {
      .main-title {
        font-size: 42px;
        line-height: 58px;
        padding-bottom: 20px;
      }

      .sub-title {
        padding-bottom: 15px;
      }
    }

    .screen-one {
      position: relative;
      top: 0;
      margin: 0 auto;
      left: 0;
      right: 0;
    }

    .shape-three {
      left: 0;
      right: inherit;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .main-banner-style04 {
    .banner-wrapper {
      .main-title {
        font-size: 36px;
        line-height: 48px;
      }

      .sub-title {
        font-size: 16px;
      }

      .slogan {
        font-size: 14px;
      }
    }

    .banner-wrapper-position {
      margin-bottom: 50px;
      max-width: 80%;
    }

    .screen-one {
      width: 95%;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .main-banner-style04 .banner-wrapper {
    .main-title {
      font-size: 26px;
      line-height: 36px;
    }

    .sub-title {
      padding-bottom: 10px;
    }
  }
}


/* main banner style5 */
.main-banner-style05 {
  width: 100%;
  position: relative;
  padding: 5% 0 21% 0;

  .container {
    margin-bottom: 50px;
  }

  .header-text h1 {
    font-size: 58px;
    font-weight: 700;
    line-height: 80px;
    margin-bottom: 20px;
  }

  .banner-content-img {
    width: 55%;
    height: auto;
    text-align: center;
    position: absolute;
    bottom: -200px;
    right: 0;
    z-index: 9;
    left: 0;
    margin: 0 auto;
  }

  .header-shape img {
    max-width: none;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .main-banner-style05 {
    .header-text h1 {
      line-height: 70px;
      font-size: 52px;
    }

    .banner-content-img {
      bottom: -150px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .main-banner-style05 {
    .header-text h1 {
      line-height: 65px;
      font-size: 42px;
      margin-bottom: 15px;
    }

    .banner-content-img {
      bottom: -150px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .main-banner-style05 {
    .banner-content-img {
      bottom: -120px;
      width: 60%;
    }

    .header-text h1 {
      line-height: 46px;
      font-size: 32px;
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .main-banner-style05 {
    padding: 10% 0 25% 0;

    .header-text h1 {
      line-height: 36px;
      font-size: 24px;
      margin-bottom: 15px;
    }

    .banner-content-img {
      bottom: -100px;
      width: 80%;
    }
  }
}

/* banner animation */
.home_bubble .bubble {
  position: absolute;
  border-radius: 50%;

  &.b_one, &.b_four {
    width: 10px;
    height: 10px;
  }

  &.b_two, &.b_five {
    width: 30px;
    height: 30px;
  }

  &.b_three, &.b_six {
    width: 14px;
    height: 14px;
  }

  &.b_two {
    background: #a485fd;
    top: 36%;
    left: 155px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation: spin1 2s infinite alternate;
    animation: spin1 2s infinite alternate;
  }

  &.b_three {
    background: #ff6262;
    top: 85%;
    left: 255px;
    -webkit-animation: spin1 1s infinite alternate;
    animation: spin1 1s infinite alternate;
  }

  &.b_four {
    background: #d27dfa;
    top: 15%;
    right: 85px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation: spin1 2s infinite alternate;
    animation: spin1 2s infinite alternate;
  }

  &.b_six {
    background: #f1d53b;
    top: 52%;
    right: 55px;
    -webkit-animation: spin2 2s infinite alternate;
    animation: spin2 2s infinite alternate;
  }
}

.square-shape1 {
  opacity: .5;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #007cb7;
  position: absolute;
  top: 30%;
  right: 155px;
  animation: spin1 3s infinite alternate;

  &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: -10px;
    top: 10px;
    border: 10px solid transparent;
    border-top-color: #007cb7;
  }
}

.square-shape2 {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #00c99c;
  top: 15%;
  left: 85px;
  animation: spin2 2s infinite alternate;
}

@media screen and (max-width: $mobile-sm-screen) {
  .square-shape2 {
    left: 20px;
  }
}

.triangle-shape1 {
  left: 25%;
  top: 400px;
  position: absolute;
  opacity: .5;
  position: relative;
  background-color: #0ee8af;
  text-align: left;
  -webkit-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  width: 15px;
  height: 15px;
  border-top-right-radius: 30%;

  &:before {
    -webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  }

  &:after, &:before {
    content: '';
    position: absolute;
    background-color: inherit;
  }

  &:after {
    -webkit-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
    width: 15px;
    height: 15px;
    border-top-right-radius: 30%;
  }

  &:before {
    width: 15px;
    height: 15px;
    border-top-right-radius: 30%;
  }
}

.triangle-shape2 {
  right: -70%;
  top: 600px;
  position: absolute;
  opacity: .5;
  position: relative;
  background-color: #fca249;
  text-align: left;
  -webkit-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  width: 15px;
  height: 15px;
  border-top-right-radius: 30%;
  animation: spin2 2s infinite alternate;

  &:before {
    -webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  }

  &:after, &:before {
    content: '';
    position: absolute;
    background-color: inherit;
  }

  &:after {
    -webkit-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
    width: 15px;
    height: 15px;
    border-top-right-radius: 30%;
  }

  &:before {
    width: 15px;
    height: 15px;
    border-top-right-radius: 30%;
  }
}

@-webkit-keyframes spin1 {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@keyframes spin1 {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@-webkit-keyframes spin2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

@keyframes spin2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

/* main banner style6 */
.main-banner-style06 {
  height: 100%;
  overflow: hidden;
  background: $PrimaryColor;
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: url(../img/banner/creative-shape.svg) no-repeat scroll center (bottom / cover);
    z-index: 0;
  }

  .stratup-image {
    position: absolute;
    width: 48%;
    height: 100%;
    right: 10px;
    z-index: 0;
    top: 12%;
  }

  .right-bottom-bg {
    position: absolute;
    left: -50px;
    bottom: 0;
    transform: rotate(180deg);
  }

  h1 {
    font-size: 56px;
    line-height: 70px;
    font-weight: 400;
    margin-bottom: 20px;

    strong {
      font-weight: 700;
    }
  }

  p {
    line-height: 40px;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .main-banner-style06 {
    h1 {
      font-size: 46px;
      line-height: 58px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .stratup-image {
      top: 25%;
    }

    .right-bottom-bg {
      left: -90px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .main-banner-style06 {
    min-height: auto !important;

    .stratup-image {
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      top: 0;
      text-align: center;
      height: auto;
      max-width: 80%;
      margin: 100px auto 75px auto;
    }

    &:before {
      width: 140%;
    }

    .min-vh-100 {
      min-height: auto !important;
      margin-bottom: 70px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .main-banner-style06 {
    h1 {
      font-size: 42px;
      margin-bottom: 15px;
    }

    .stratup-image {
      margin: 100px auto 60px auto;
    }

    .right-bottom-bg {
      left: -120px;
    }

    p {
      font-size: 16px;
      line-height: 32px;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .main-banner-style06 {
    .stratup-image {
      margin: 100px auto 50px auto;
    }

    &:before {
      width: 180%;
    }

    h1 {
      font-size: 36px;
      line-height: 50px;
    }

    .min-vh-100 {
      margin-bottom: 60px;
    }
  }
}

@media screen and (max-width: 480px) {
  .main-banner-style06 {
    .stratup-image {
      margin: 100px auto 30px auto;
    }

    &:before {
      width: 220%;
    }

    h1 {
      font-size: 30px;
      line-height: 46px;
    }
  }
}

/* main banner style7 */
.main-banner-style07 {
  height: 100%;
  overflow: hidden;
  position: relative;

  .container {
    position: relative;
    z-index: 9;
  }

  .stratup-image {
    position: absolute;
    width: 48%;
    right: 10px;
    top: 22%;
    z-index: 9;
  }

  h1 {
    font-size: 48px;
    line-height: 62px;
    font-weight: 400;
    margin-bottom: 15px;
    color: #fff;

    strong {
      font-weight: 700;
    }
  }

  .header-shape {
    position: absolute;
    top: auto;
    bottom: -1px;
    left: 0;
    right: 0;

    svg {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
  }
}

@media screen and (max-width: $desk-lg-screen) {
  .main-banner-style07 .header-shape {
    bottom: 53px;

    svg {
      -webkit-transform: scale(1.25);
      transform: scale(1.25);
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .main-banner-style07 {
    h1 {
      font-size: 42px;
      line-height: 54px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .header-shape {
      bottom: 52px;
    }

    .stratup-image {
      top: 25%;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .main-banner-style07 {
    min-height: auto !important;

    h1 {
      font-size: 38px;
      line-height: 48px;
    }

    .stratup-image {
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      top: 0;
      text-align: center;
      height: auto;
      max-width: 80%;
      margin: 100px auto 75px auto;
    }

    &:before {
      width: 140%;
    }

    .min-vh-100 {
      min-height: auto !important;
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .main-banner-style07 {
    h1 {
      font-size: 36px;
      margin-bottom: 15px;
    }

    .stratup-image {
      margin: 100px auto 60px auto;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .main-banner-style07 {
    .stratup-image {
      margin: 100px auto 50px auto;
    }

    &:before {
      width: 180%;
    }

    h1 {
      font-size: 36px;
      line-height: 50px;
    }

    .min-vh-100 {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 480px) {
  .main-banner-style07 {
    .stratup-image {
      margin: 100px auto 30px auto;
    }

    &:before {
      width: 220%;
    }

    h1 {
      font-size: 32px;
      line-height: 46px;
    }
  }
}

/* main banner style8 */
.main-banner-style08 {
  overflow: hidden;
  position: relative;
  padding: 12% 0;
  background: transparent;

  .cd-headline {
    font-size: 18px;
    color: $PrimaryColor;
  }
}

.cd-headline.clip .cd-words-wrapper:after {
  content: none;
}

.main-banner-style08 {
  .header-text h1 {
    font-size: 60px;
    letter-spacing: 1.4px;
    margin-bottom: 30px;
    display: inline-block;
    font-weight: 600;
  }

  .left-top-bg {
    position: absolute;
    left: 0;
    top: 0;
  }

  .right-bottom-bg {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .triangle-shape {
    position: absolute;
    right: 8%;
    top: 10%;
    left: inherit;
  }
}

@media screen and (max-width: $desk-lg-screen) {
  .main-banner-style08 {
    .left-top-bg {
      width: 30%;
    }

    .right-bottom-bg {
      width: 12%;
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .main-banner-style08 {
    padding: 18% 0;

    .left-top-bg {
      width: 25%;
    }

    .right-bottom-bg {
      width: 10%;
    }

    .header-text h1 {
      font-size: 48px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .main-banner-style08 {
    padding: 20% 0 18% 0;

    .header-text h1 {
      font-size: 42px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .main-banner-style08 {
    padding: 25% 0 22% 0;

    .header-text h1 {
      font-size: 36px;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .main-banner-style08 {
    padding: 40% 0 30% 0;

    .header-text h1 {
      font-size: 30px;
    }
  }
}

/* shape animation */
.shape-one {
  position: absolute;
  right: 0;
  top: 145px;
  z-index: 0;
  opacity: 0.65;
}

.shape-two {
  position: absolute;
  bottom: 12%;
  left: 31%;
  animation: rotated 20s infinite linear;
}

.shape-three {
  position: absolute;
  bottom: 35%;
  right: 76%;
  z-index: -1;
  animation: rotated 50s infinite linear;
  width: 14%;
}

.shape-four {
  position: absolute;
  top: 17%;
  right: 37%;
  z-index: -1;
  animation: rotatedHalf 30s infinite linear;

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 95px;
    width: 95px;
    border: 2px solid $SecondaryColor;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    opacity: .3;
    animation: pulse-border 1500ms ease-out infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 80px;
    width: 80px;
    border: 2px solid $SecondaryColor;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    opacity: .3;
    animation: pulse-border 1500ms ease-out infinite;
  }
}

.shape-five {
  position: absolute;
  bottom: 5%;
  left: 58%;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: #e5a9ff;
  -webkit-animation: rotate3d 10s linear infinite;
  -moz-animation: rotate3d 10s linear infinite;
  -o-animation: rotate3d 10s linear infinite;
  animation: rotate3d 10s linear infinite;
}

.triangle-shape {
  top: 15%;
  left: 36%;
  position: absolute;
  animation: animationFramesOne 30s infinite linear;
  opacity: .5;
  position: relative;
  background-color: #0ee8af;
  text-align: left;
  -webkit-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  width: 15px;
  height: 15px;
  border-top-right-radius: 30%;

  &:before {
    -webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  }

  &:after, &:before {
    content: '';
    position: absolute;
    background-color: inherit;
  }

  &:after {
    -webkit-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
    width: 15px;
    height: 15px;
    border-top-right-radius: 30%;
  }

  &:before {
    width: 15px;
    height: 15px;
    border-top-right-radius: 30%;
  }

  &:after, &:before {
    content: '';
    position: absolute;
    background-color: inherit;
  }
}

.square-shape {
  opacity: .5;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #007cb7;
  position: absolute;
  top: 23%;
  left: 8%;
  animation: animationFramesTwo 13s infinite linear;

  &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: -10px;
    top: 10px;
    border: 10px solid transparent;
    border-top-color: #007cb7;
  }
}

.square-shape-two {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #a3eead;
  bottom: 42%;
  left: 6%;
  animation: animationFramesTwo 25s infinite linear;
}

.round-shape-one {
  position: absolute;
  right: 6%;
  top: 10%;
  width: 225px;
  height: 225px;
  border-radius: 50%;
  border: 1px dashed rgba($PrimaryRgbColor, 0.1);
  animation: rotated 30s infinite linear;

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: -5px;
    background: #ddabf6;
    border-radius: 50%;
  }
}

.round-shape-two {
  position: absolute;
  right: -350px;
  top: 15%;
  width: 500px;
  height: 500px;
  border-radius: 100%;
  border: 2px dashed rgba($PrimaryRgbColor, 0.09);
  animation: rotated 50s infinite linear;

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: -5px;
    background: #ddabf6;
    border-radius: 50%;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .round-shape-two {
    right: -450px;
    top: 35%;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .round-shape-two {
    right: -400px;
    top: 35%;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .round-shape-two {
    width: 450px;
    height: 450px;
    right: -370px;
    top: 35%;
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .round-shape-two {
    width: 300px;
    height: 300px;
    right: -270px;
    top: 40%;
  }
}

.shape-six {
  background: rgba($PrimaryRgbColor, 0.2);
  top: 40%;
  right: 85px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation: spin1 2s infinite alternate;
  animation: spin1 2s infinite alternate;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
}

.right-align {
  right: 5%;
  left: inherit;
}

.left-align {
  left: 5%;
  right: inherit;
}

.bottom-align {
  bottom: 0;
  top: inherit;
}

.top-align {
  top: 0;
  bottom: inherit;
}

.round-shape-two.left-minus-align {
  left: -15%;
  right: inherit;
}

@media screen and (max-width: $desk-md-screen) {
  .round-shape-two.left-minus-align {
    left: -450px;
    top: 35%;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .round-shape-two.left-minus-align {
    left: -400px;
    top: 35%;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .round-shape-two.left-minus-align {
    width: 450px;
    height: 450px;
    left: -370px;
    top: 35%;
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .round-shape-two.left-minus-align {
    width: 300px;
    height: 300px;
    left: -270px;
    top: 40%;
  }
}

@keyframes spin1 {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.2);
  }
}

@-webkit-keyframes rotated {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotated {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotatedHalf {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes rotatedHalf {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animationFramesTwo {
  0% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }

  20% {
    transform: translate(73px, -1px) rotate(36deg) scale(0.9);
  }

  40% {
    transform: translate(141px, 72px) rotate(72deg) scale(1);
  }

  60% {
    transform: translate(83px, 122px) rotate(108deg) scale(1.2);
  }

  80% {
    transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
  }

  100% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
}

@-webkit-keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
}

@media screen and (max-width: $desk-md-screen) {
  .round-shape-one {
    top: 20%;
    width: 150px;
    height: 150px;
    right: 8%;
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .round-shape-one {
    right: 12%;
  }
}

/* main banner style9 */
.main-banner-style09 {
  width: 100%;
  position: relative;
  background: $PrimaryColor;
  background: -webkit-linear-gradient(180deg, $PrimaryColor, $SecondaryColor);
  background: -moz-linear-gradient(180deg, $PrimaryColor, $SecondaryColor);
  background: -o-linear-gradient(180deg, $PrimaryColor, $SecondaryColor);
  background: linear-gradient(180deg, $PrimaryColor, $SecondaryColor);

  .container {
    position: absolute;
    left: 50%;
    top: 40%;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 9;
  }

  .btn-play {
    width: 50px;
    height: 50px;
    line-height: 46px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    margin: auto;
    display: inline-block;
    border: 2px solid #fff;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 3;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      background: #fff;
      opacity: .19;
      z-index: 1;
    }

    i {
      padding-left: 5px;
      position: relative;
      z-index: 2;
    }
  }

  .banner-content-img {
    width: 55%;
    height: auto;
    text-align: center;
    position: absolute;
    bottom: -150px;
    right: 0;
    z-index: 9;
    left: 0;
    margin: 0 auto;
  }

  .header-shape {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    img {
      max-width: none;
    }
  }
}

@media screen and (max-width: $desk-xl-screen) {
  .main-banner-style09 .banner-content-img {
    bottom: -125px;
  }
}

@media screen and (max-width: $desk-lg-screen) {
  .main-banner-style09 {
    .container {
      top: 45%;
    }

    .banner-content-img {
      bottom: -150px;
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .main-banner-style09 .banner-content-img {
    bottom: -120px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .main-banner-style09 {
    .container {
      top: 45%;
    }

    .banner-content-img {
      bottom: -40px;
      width: 75%;
    }
  }
}

/* main banner style10 */
.main-banner-style10 {
  position: relative;

  .header-shape {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: 0;
    width: 100%;

    img {
      max-width: none;
    }
  }

  > .container {
    padding-top: 15%;
    position: relative;
    z-index: 9;
  }

  .banner-wrapper-position {
    position: absolute;
    right: 50px;
    top: 130px;
    max-width: 40%;
    width: 100%;
    height: auto;
    z-index: 9;
  }

  .btn-play {
    width: 50px;
    height: 50px;
    line-height: 46px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    margin: auto;
    display: inline-block;
    border: 2px solid #fff;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 3;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      background: #fff;
      opacity: .19;
      z-index: 1;
    }

    i {
      padding-left: 5px;
      position: relative;
      z-index: 2;
    }
  }

  .header-text h1 {
    position: relative;
    font-size: 65px;
    line-height: 85px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 15px;
    letter-spacing: 1px;
  }

  .banner-content-img {
    width: 55%;
    height: 66%;
    right: 30px;
    text-align: right;
    position: absolute;
    bottom: 100px;
    z-index: 9;
  }

  .header-text p {
    line-height: 30px;
  }
}

@media screen and (max-width: $desk-lg-screen) {
  .main-banner-style10 {
    .banner-wrapper-position {
      max-width: 45%;
    }

    .header-text h1 {
      font-size: 58px;
      line-height: 75px;
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .main-banner-style10 > .container {
    padding-top: 18%;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .main-banner-style10 {
    padding: 90px 0 120px 0;

    > .container {
      padding-top: 0;
      position: relative;
      top: 0;
      transform: none;
      left: 0;
    }

    .banner-wrapper-position {
      position: relative;
      right: 0;
      top: 0;
      text-align: center;
      margin: 0 auto 50px auto;
      max-width: 60%;
    }

    .header-text {
      text-align: center;
      padding: 0 7%;

      h1 {
        font-size: 52px;
        line-height: 65px;
      }
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .main-banner-style10 {
    padding: 90px 0;

    .banner-wrapper-position {
      max-width: 65%;
    }

    .header-text {
      padding: 0 15px;

      h1 {
        font-size: 42px;
        line-height: 48px;
      }
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .main-banner-style10 {
    .header-text h1 {
      font-size: 34px;
      line-height: 42px;
    }

    > .container {
      position: absolute;
      left: 50%;
      top: 55%;
      transform: translate(-50%, -50%);
    }
  }
}

/* main banner style11 */
.main-banner-style11 {
  position: relative;
  overflow: hidden;

  > .container {
    position: absolute;
    left: 50%;
    top: 45%;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 9;
  }

  h1 {
    padding: 0 0 25px 0;
    color: #fff;
    font-size: 58px;
    line-height: 70px;
    font-weight: 300;
  }

  p {
    padding: 0 0 30px 0;
    font-size: 22px;
    line-height: 36px;
    color: #fff;
    letter-spacing: 0.5px;
    font-weight: 300;
  }

  .btn-play {
    width: 60px;
    height: 60px;
    line-height: 65px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    margin: auto;
    display: inline-block;
    border: 2px solid #fff;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 3;

    i {
      position: relative;
      z-index: 2;
      font-size: 28px;
    }
  }

  .banner-content-img {
    position: absolute;
    right: 20px;
    top: 120px;
    max-width: 45%;
    width: 100%;
    z-index: 9;
  }

  .header-shape {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      max-width: none;
      height: 100%;
    }
  }
}

@media screen and (max-width: $desk-lg-screen) {
  .main-banner-style11 {
    > .container {
      left: 52%;
    }

    h1 {
      font-size: 52px;
      margin-bottom: 10px;
    }

    p {
      padding-bottom: 10px;
      font-size: 18px;
      line-height: 36px;
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .main-banner-style11 {
    > .container {
      left: 54%;
    }

    h1 {
      font-size: 44px;
    }

    p {
      font-size: 16px;
      line-height: 34px;
    }
  }
}

@media screen and (max-width: $desk-small-screen) {
  .main-banner-style11 {
    min-height: 750px !important;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .main-banner-style11 {
    padding: 70px 0 90px 0;
    min-height: inherit !important;

    > .container {
      left: 50%;
    }

    h1 {
      font-size: 42px;
      line-height: 58px;
    }

    .banner-content-img {
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      top: 0;
      text-align: center;
      height: auto;
      max-width: 80%;
      margin: 0 auto 50px auto;
    }

    > .container {
      position: relative;
      left: 0;
      right: 0;
      margin: 0 auto;
      max-width: 100%;
      top: 0;
      transform: none;
    }

    p {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .main-banner-style11 {
    padding: 30px 0 50px 0;

    h1 {
      font-size: 28px;
      line-height: 48px;
      margin-bottom: 0;
    }

    .banner-content-img {
      max-width: 90%;
      margin-bottom: 30px;
    }

    p {
      font-size: 14px;
      line-height: 28px;
      margin-bottom: 20px;
    }
  }
}

/* main banner style12 */
.main-banner-style12 {
  width: 100%;
  position: relative;
  padding: 10% 0 0 0;

  .container {
    position: relative;
    top: 20%;
    z-index: 9;
  }

  .banner-content-img {
    width: 47%;
    height: auto;
    text-align: center;
    margin: 50px auto 0 auto;
    position: relative;
    z-index: 9;
  }

  .header-shape {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    img {
      max-width: none;
    }
  }

  .bg-shapes-transparent {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;

    img {
      max-width: none;
      width: auto;
      height: 100%;
    }
  }
}

@media screen and (max-width: $desk-lg-screen) {
  .main-banner-style12 {
    padding: 14% 0 0 0;

    .banner-content-img {
      width: 55%;
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .main-banner-style12 {
    padding: 16% 0 0 0;

    .banner-content-img {
      width: 70%;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .main-banner-style12 {
    padding: 20% 0 0 0;

    .banner-content-img {
      width: 75%;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .main-banner-style12 {
    padding: 25% 0 0 0;

    .banner-content-img {
      width: 80%;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .main-banner-style12 {
    padding: 40% 0 0 0;

    .banner-content-img {
      width: 90%;
    }
  }
}

/*==============================
 *    Page title
 *    ================================ */

.page-title-section {
  padding: 200px 0 125px;
  text-align: center;

  h1 {
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1;
    color: #fff;
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 0;
    margin-top: 15px;
    text-align: center;

    li {
      display: inline-block;

      &:last-child a {
        color: #fff;
        font-weight: 500;
        opacity: 0.65;
      }

      &:after {
        content: '\f105';
        font-weight: 700;
        vertical-align: middle;
        color: #fff;
        font-family: Font Awesome\ 5 Free;
        padding: 0 5px 0 10px;
      }

      &:last-child:after {
        content: none;
      }

      a {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .active a, li.active:last-child a {
    color: #fff;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .page-title-section {
    padding: 165px 0 100px 0;

    h1 {
      font-size: 36px;
      line-height: 42px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .page-title-section {
    padding: 140px 0 90px 0;
    margin-top: -71px;

    h1 {
      font-size: 32px;
      line-height: 40px;
    }

    ul {
      margin-top: 5px;

      li a {
        font-size: 13px;
      }
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .page-title-section {
    padding: 140px 0 75px 0;
  }
}

/*==============================
 *    Card styles
 *    ================================ */

/* card style01 */
.card-style01 {
  padding: 0.8rem;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.075);

  h4 {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 0;
  }

  .card-body {
    border-bottom: none;
    padding-right: 0;
    padding-left: 0;

    small {
      letter-spacing: 1px;
      font-size: 12px;
    }
  }

  .card-footer {
    background: none;
    border-top: 1px solid rgba(0, 0, 0, 0.075);
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;

    .read-more {
      font-size: 14px;
      color: $PrimaryColor;
      letter-spacing: 0.8px;
      font-weight: 500;
    }

    i {
      vertical-align: middle;
    }
  }
}

/* card style02 */
.card-style02 {
  box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.05);
  transition: all .4s ease 0s;
  border-radius: .25rem;
  text-align: center;
  border: none;

  .card-body {
    padding: 2.5rem 2rem;
    position: relative;

    .icon {
      display: inline-block;

      i {
        color: rgba($PrimaryRgbColor, 1);
        font-size: 1.8rem;
        background: rgba($PrimaryRgbColor, 0.1);
        border-radius: 50%;
        line-height: 1;
        height: 5rem;
        width: 5rem;
        line-height: 80px;
        display: inline-block;
        transition: all .8s;
        margin-bottom: 1.5rem;
      }
    }
  }

  h3 {
    margin-bottom: 1.2rem;
  }

  span, h3, p, a {
    position: relative;
    z-index: 9;
  }

  .card-body {
    &:before {
      border-radius: 0.25rem;
      background: rgba($PrimaryRgbColor, 1);
      position: absolute;
      content: "";
      top: 99.5%;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      -webkit-transition: all .5s;
      -o-transition: all .5s;
      transition: all .5s;
    }

    &:hover {
      &:before {
        border-radius: 0.25rem;
        top: 0;
        bottom: 0;
        background: rgba($PrimaryRgbColor, 1);
      }

      span i {
        color: rgba($PrimaryRgbColor, 1);
        background: #fff;
      }

      h3, p, a {
        color: #fff;
      }
    }
  }

  &.green .card-body {
    .icon i {
      color: rgba(6, 223, 158, 1);
      background: rgba(6, 223, 158, 0.1);
    }

    &:before {
      background: rgba(6, 223, 158, 1);
    }

    &:hover {
      &:before {
        background: rgba(6, 223, 158, 1);
      }

      span i {
        color: rgba(6, 223, 158, 1);
        background: #fff;
      }
    }
  }

  &.orange .card-body {
    .icon i {
      color: rgba(255, 98, 98, 1);
      background: rgba(255, 98, 98, 0.1);
    }

    &:before {
      background: rgba(255, 98, 98, 1);
    }

    &:hover {
      &:before {
        background: rgba(255, 98, 98, 1);
      }

      span i {
        color: rgba(255, 98, 98, 1);
        background: #fff;
      }
    }
  }

  &.blue .card-body {
    .icon i {
      color: rgba(21, 178, 236, 1);
      background: rgba(21, 178, 236, 0.1);
    }

    &:before {
      background: rgba(21, 178, 236, 1);
    }

    &:hover {
      &:before {
        background: rgba(21, 178, 236, 1);
      }

      span i {
        color: rgba(21, 178, 236, 1);
        background: #fff;
      }
    }
  }

  &.yellow .card-body {
    .icon i {
      color: rgba(255, 204, 111, 1);
      background: rgba(255, 204, 111, 0.1);
    }

    &:before {
      background: rgba(255, 204, 111, 1);
    }

    &:hover {
      &:before {
        background: rgba(255, 204, 111, 1);
      }

      span i {
        color: rgba(255, 204, 111, 1);
        background: #fff;
      }
    }
  }

  &.fill .card-body {
    &:before {
      border-radius: 0.25rem;
      top: 0;
      bottom: 0;
      background: rgba($PrimaryRgbColor, 1);
    }

    span i {
      color: rgba($PrimaryRgbColor, 1);
      background: #fff;
    }

    h3, p, a {
      color: #fff;
    }
  }
}

/* card style03 */
.card-style03 {
  text-align: center;
  padding: 40px 20px;
  transition: all .3s ease-out;
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

  .card-body {
    padding: 0;
  }

  &:hover {
    background-color: #fff;
    box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.18);
    transform: translate(0, -10px);
  }
}

/* card style04 */
.card-style04 {
  border: none;
  text-align: center;
  padding: 40px 20px;
  transition: all .3s ease-out;

  &:hover {
    background-color: #fff;
    box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.18);
    -webkit-box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.18);
    transform: translate(0, -10px);
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px);
  }

  .card-body {
    padding: 0;
  }

  p {
    font-size: 16px;
    line-height: 30px;
  }

  .icon-box5 {
    font-size: 26px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin: auto;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
    animation: one-animated 8s infinite;
    margin-bottom: 40px;
  }

  .service-icon1 {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
    animation: one-animated 8s infinite;
  }
}

@keyframes one-animated {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  }

  25% {
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
  }

  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2);
  }

  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .card-style04 {
    padding: 30px 10px;

    .icon-box5 {
      height: 70px;
      margin-bottom: 25px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .card-style04 p {
    font-size: 15px;
    line-height: 28px;
  }
}

/* card style05 */
.card-style05 {
  border: none;
  text-align: center;
  background: #fff;
  box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.05);
  border-radius: .25rem;
  height: 100%;

  &:hover i {
    color: #fff;

    &:after {
      background: $PrimaryColor;
      transform: rotate(0deg);
    }
  }

  i {
    display: inline-block;
    color: $PrimaryColor;
    font-size: 45px;
    width: 85px;
    height: 85px;
    margin: 0 auto 30px auto;
    line-height: 85px;
    position: relative;
    transition: all 0.4s;
    z-index: 3;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      border-radius: 20px;
      background: rgba($PrimaryRgbColor, 0.15);
      z-index: -1;
      transform: rotate(45deg);
      transition: all 0.4s;
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .card-style05 i {
    font-size: 34px;
    width: 75px;
    height: 75px;
    line-height: 75px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .card-style05 i {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .card-style05 i {
    font-size: 30px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    margin-bottom: 15px;
  }
}

/* card style06 */
.card-style06 {
  background: #fff;
  box-shadow: 0px 3px 10px 0px rgba($PrimaryRgbColor, 0.08);
  border-radius: 4px;
  margin: 0px 10px 0px 0px;
  position: relative;
  z-index: 5;
  padding: 55px 35px 45px 35px;
  transition: all 0.6s linear;
  height: 100%;
  border: none;

  .card-body {
    padding: 0;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    z-index: -3;
    opacity: 0;
    transition: all 0.6s linear;
    border-radius: 4px;
    width: 100%;
    height: 100%;
  }

  &:hover:after {
    opacity: 1;
  }

  .icon {
    font-size: 40px;
  }

  h3 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
  }

  .title-box {
    margin: 0 0 50px 0;
  }

  i {
    transition: all 0.3s linear;
  }

  &:hover {
    opacity: 1;
    transform: translate(0%, 0%);
    transition: all 1.3s linear;

    .box-circle {
      transform: scale(1.2);
    }

    h3, p, .icon {
      color: #fff;
    }

    .box-circle {
      background: rgba(255, 255, 255, 0.1);
    }

    i {
      color: #fff;
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .card-style06 {
    padding: 35px 25px 25px 25px;

    .title-box {
      margin: 0 0 40px 0;
    }

    h3 {
      font-size: 20px;
      margin-bottom: 25px;
    }

    p {
      font-size: 15px;
    }

    .icon {
      font-size: 36px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .card-style06 {
    margin-right: 0;

    .title-box {
      margin-bottom: 30px;
    }

    h3 {
      margin-bottom: 20px;
    }

    .icon {
      font-size: 32px;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .card-style06 {
    padding: 25px;

    h3 {
      font-size: 18px;
    }

    .title-box {
      margin-bottom: 25px;
    }

    h3 {
      margin-bottom: 15px;
    }
  }
}

/* card style07 */
.card-style07 {
  background: #fff;
  border-radius: 0.25rem;
  border: none;
  text-align: center;
  height: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  .card-body {
    padding: 0;
  }

  i {
    color: $PrimaryColor;
  }

  &:hover {
    box-shadow: -1px 9px 18px 0px rgba(75, 81, 91, 0.1);
    background: #ffffff;

    i {
      animation-name: elementor-animation-wobble-to-top-right;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
    }
  }

  &:active, &:focus, &:hover {
    animation-name: elementor-animation-wobble-to-bottom-right;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
  }

  &.bg-light-gray {
    background: #f7f7f7;
  }

  .title-box {
    position: relative;
    padding: 20px 0;
    font-size: 60px;
    line-height: 1;
    width: 2em;
    margin-left: auto;
    margin-right: auto;
  }

  .box-circle-large, .box-circle-small {
    position: absolute;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .box-circle-large {
    left: 0;
    bottom: 0;
    width: 1.37em;
    height: 1.37em;
    background: rgba(78, 74, 200, 0.1);
  }

  .box-circle-small {
    top: 0;
    right: 0;
    width: 0.59em;
    height: 0.59em;
    background: rgba(128, 137, 255, 0.1);
  }

  &:hover {
    background: $PrimaryColor;
    background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);

    .box-circle-large {
      transform: scale(1.2);
    }

    .box-circle-small {
      transform: translate3d(0, -35%, 0) rotate(0deg);
    }

    h3, p, i, a, .text-extra-dark-gray {
      color: #fff;
    }

    .box-circle-large {
      background: rgba(255, 255, 255, 0.1);
    }

    .box-circle-small {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  &.active {
    background: $PrimaryColor;

    h3, p, i {
      color: #fff;
    }

    .box-circle-large {
      background: rgba(255, 255, 255, 0.1);
    }

    .box-circle-small {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

@keyframes elementor-animation-wobble-to-top-right {
  16.65% {
    transform: translate(8px, -8px);
  }

  33.3% {
    transform: translate(-6px, 6px);
  }

  49.95% {
    transform: translate(4px, -4px);
  }

  66.6% {
    transform: translate(-2px, 2px);
  }

  83.25% {
    transform: translate(1px, -1px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .card-style07 .title-box {
    font-size: 50px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .card-style07 .title-box {
    font-size: 42px;
  }
}

/* card style08 */
.card-style08 {
  border: none;
  background: #fff;
  box-shadow: -1px 9px 18px 0px rgba(75, 81, 91, 0.1);
  padding: 60px 25px 75px 25px;
  border-radius: 7px;
  text-align: center;
  height: 100%;
  transition: all 0.2s ease-in-out;

  .card-body {
    padding: 0;
  }

  &:hover {
    box-shadow: -1px 9px 18px 0px rgba(75, 81, 91, 0.1);
    background: #ffffff;
  }

  &:active {
    animation-name: elementor-animation-wobble-to-bottom-right;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
  }
}

.services-block {
  &:focus, &:hover {
    animation-name: elementor-animation-wobble-to-bottom-right;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
  }
}

.card-style08 {
  &.bg-light-gray {
    background: #f7f7f7;
  }

  p {
    margin-bottom: 0;
    line-height: 30px;
  }

  .title-box {
    margin-left: auto;
    margin-right: auto;
  }

  .box-circle-large {
    position: absolute;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    left: 0;
    bottom: 0;
    width: 1.37em;
    height: 1.37em;
  }

  &:hover .box-circle-large {
    transform: scale(1.2);
  }
}

@media screen and (max-width: $desk-md-screen) {
  .card-style08 {
    padding: 50px 25px 65px 25px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .card-style08 {
    padding: 45px 25px 60px 25px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .card-style08 {
    padding: 35px 20px 60px 20px;
  }
}

/* card style09 */
.card-style09 {
  border: none;
  background: none;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  color: rgba(255, 255, 255, 0.85);

  .card-body {
    padding: 0;
  }

  &.line {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 69%;
      height: 2px;
      top: 50px;
      left: 70%;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      border: 1px dashed #fff;
    }
  }

  .icon {
    width: 100px;
    height: 100px;
    margin: auto;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
    margin-bottom: 35px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background: rgba(255, 255, 255, 0.9);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);

    i {
      display: block;
      width: 100px;
      height: 100px;
      line-height: 95px;
      color: $PrimaryColor;
      font-size: 40px;
      -webkit-transform: rotate(315deg);
      -moz-transform: rotate(315deg);
      -o-transform: rotate(315deg);
      transform: rotate(315deg);
    }
  }

  .title {
    font-size: 18px;
    letter-spacing: 1.12px;
    margin-bottom: 15px;
    color: #fff;
  }

  &:hover .icon {
    background: $PrimaryColor;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);

    i {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      color: #fff;
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .card-style09 {
    .icon i {
      width: 90px;
      height: 90px;
      line-height: 90px;
      font-size: 36px;
    }

    .title {
      margin-bottom: 10px;
    }

    .icon {
      width: 90px;
      height: 90px;
      margin-bottom: 25px;
    }

    &.line:after {
      width: 65%;
      left: 73%;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .card-style09 {
    .title {
      font-size: 17px;
    }

    .icon {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;

      i {
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: 32px;
      }
    }

    &.line:after {
      width: 65%;
      left: 75%;
      top: 40px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .card-style09 {
    margin-bottom: 90px;

    .title {
      font-size: 16px;
      margin-bottom: 5px;
    }

    .icon {
      width: 70px;
      height: 70px;
      margin-bottom: 15px;

      i {
        width: 70px;
        height: 70px;
        line-height: 70px;
        font-size: 28px;
      }
    }

    &.line {
      top: 0;

      &:after {
        width: 1px;
        left: 0;
        top: auto;
        bottom: -42%;
        right: 0;
        margin: 0 auto;
        height: 35%;
      }
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .card-style09.line:after {
    bottom: -38%;
  }
}

/* card style10 */
.card-style10 {
  border: none;
  box-shadow: -1px 9px 18px 0px rgba(75, 81, 91, 0.1);
  margin: 30px;
  padding: 60px 40px 60px 40px;

  .card-body {
    padding: 0;
  }

  h4 {
    margin-bottom: 20px;

    a {
      color: #1d184a;
      font-size: 18px;

      &:hover {
        color: $PrimaryColor;
      }
    }
  }

  p {
    line-height: 30px;
  }

  .card-img {
    height: 120px;
    width: 120px;
    line-height: 120px;
    border-radius: 50%;
    background-color: #e6ecff;
    padding: 0 15px;
    margin: 0 auto 30px auto;

    img {
      border-radius: 50%;
      vertical-align: middle;
    }
  }

  a span {
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .card-style10 {
    padding: 50px 30px 50px 30px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .card-style10 {
    padding: 35px 25px 35px 25px;
    margin: 30px 25px;
  }
}

/* card style11 */
.card-style11 {
  border: none;

  .card-body {
    padding: 0;
  }

  &:nth-child(1) {
    border-right: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
  }

  &:nth-child(2) {
    border-bottom: 1px solid #ececec;
  }

  &:nth-child(3) {
    border-right: 1px solid #ececec;
  }

  .inner-box {
    position: relative;
    text-align: center;
    padding: 100px 15px 25px 15px;
    display: inline-block;
    width: 100%;
  }

  .icon-box .feature-icon {
    position: relative;
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .card-style11 .inner-box {
    padding: 80px 0 15px 0;
  }
}

/* card style12 */
.card-style12 {
  border: none;
  text-align: center;

  .card-body {
    padding: 0;
  }

  p {
    margin-bottom: 0;
  }

  .icon-box4 {
    height: 60px;
    width: 60px;
    font-size: 26px;
    box-shadow: 0 14px 26px -12px rgba(103, 58, 183, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(103, 58, 183, 0.2) !important;
    border-radius: 4px;
    position: relative;
    text-align: center;
    display: inline-block;
    line-height: 63px;

    &:after {
      width: 76px;
      height: 76px;
      content: "";
      position: absolute;
      top: -8px;
      left: -8px;
      opacity: 0.8;
      border-radius: 5px;
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
      transition: all 0.3s;
      border: 2px solid transparent;
    }
  }

  &:hover .icon-box4:after {
    -webkit-transform: scale(1);
    transform: scale(1);
    border: 2px solid;
  }

  p {
    line-height: 30px;
    margin: 0 auto;
  }

  h5 {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 15px;
  }

  .bg1 {
    background: $PrimaryColor;
  }

  .bg2 {
    background: #f7864e;
  }

  .bg3 {
    background: #35c3ec;
  }

  .bg4 {
    background: #e8bb20;
  }

  .bg5 {
    background: #f94897;
  }

  .bg6 {
    background: #5abb29;
  }

  &:hover .icon-box4 {
    &.bg1:after {
      border-color: $PrimaryColor;
    }

    &.bg2:after {
      border-color: #f7864e;
    }

    &.bg3:after {
      border-color: #35c3ec;
    }

    &.bg4:after {
      border-color: #e8bb20;
    }

    &.bg5:after {
      border-color: #f94897;
    }

    &.bg6:after {
      border-color: #5abb29;
    }
  }
}

/* card style13 */
.card-style13 {
  border: 1px solid #f4f4f9;
  background-color: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
  padding: 50px;
  height: 100%;
  webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;

  &:hover {
    -webkit-box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.08);
    box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.08);
  }

  .card-body {
    padding: 0;
  }

  h5 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #243f67;
  }

  p {
    margin-bottom: 0;
    line-height: 28px;
  }

  &:hover .service-img {
    -webkit-animation-name: hvr-buzz;
    animation-name: hvr-buzz;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
}

@-webkit-keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
    -moz-transform: translateX(3px) rotate(2deg);
    -ms-transform: translateX(3px) rotate(2deg);
    -o-transform: translateX(3px) rotate(2deg);
  }

  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
    -moz-transform: translateX(-3px) rotate(-2deg);
    -ms-transform: translateX(-3px) rotate(-2deg);
    -o-transform: translateX(-3px) rotate(-2deg);
  }
}

@media screen and (max-width: $desk-md-screen) {
  .card-style13 {
    padding: 40px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .card-style13 {
    padding: 35px;

    h5 {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .card-style13 {
    padding: 30px 25px;
  }
}


/*==============================
 *    Video
 *    ================================ */

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;

  .embed-responsive-item, iframe, embed, object, video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-aspect-ratio: 16 / 9) {
  .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16 / 9) {
  .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}

/*==============================
 *    Mobile App Demo
 *    ================================ */

.image-box {
  position: relative;
  display: block;

  figure {
    margin-bottom: 0;
  }

  &:before {
    position: absolute;
    content: '';
    background: $PrimaryColor;
    background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    width: 310px;
    height: 310px;
    border-radius: 50%;
    left: -130px;
    top: 25%;
  }

  img {
    width: 100%;
  }

  .image-1 {
    position: relative;
    float: left;
    margin-left: -130px;
  }

  .image-2 {
    position: absolute;
    left: -55px;
    bottom: 310px;
    box-shadow: 0 23px 50px rgba(176, 176, 176, 0.35);
  }

  .image-3 {
    position: absolute;
    top: 105px;
    right: 20px;
  }
}

.app-info {
  .about-text:before {
    top: -10px;
    left: 0;
    position: absolute;
    content: "\201E";
    margin-top: -65px;
    font-family: Arial,sans-serif;
    font-size: 110px;
    line-height: 1;
    color: #ddd;
  }

  .about-img img {
    width: 60px;
    border-radius: 50px;
  }
}

@media screen and (max-width: $desk-lg-screen) {
  .image-box {
    &:before {
      width: 275px;
      height: 275px;
      left: -100px;
    }

    .image-2 {
      left: -15px;
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .image-box {
    .image-1 {
      margin-left: -180px;
      left: 90px;
    }

    .image-3 {
      right: 0;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .image-box {
    &:before {
      left: 0;
    }

    .image-1 {
      margin-left: 0;
      left: 0;
    }

    .image-2 {
      left: 50px;
    }
  }

  .app-info {
    padding-right: 50px;
    margin-top: -130px;
    padding-bottom: 50px;

    .about-text:before {
      font-size: 98px;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .image-box {
    &:before {
      content: none;
    }

    .image-3 {
      right: 0;
      width: 30%;
      top: 50px;
    }

    .image-2 {
      left: 0;
      width: 35%;
      bottom: 210px;
    }
  }

  .app-info {
    margin-top: -50px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
}


/* app screenshot slider */
.app_screenshots_slides {
  * {
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
  }

  .single-shot {
    opacity: 0.7;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=70)";
    -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    transform: scale(0.85);
    text-align: center;
  }

  .center .single-shot {
    opacity: 1;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.app-screenshots-area {
  button.owl-dot {
    height: 11px;
    width: 11px;
    border: 2px solid #884bdf;
    border-radius: 50%;
    display: inline-block;
    margin: 0 6px;
    margin-top: 50px;

    &.active {
      background-color: #fe4b8c;
      border-color: #fe4b8c;
    }
  }

  .owl-dots {
    height: 100%;
    width: 100%;
    text-align: center;
    margin-top: 25px;
  }
}

/*==============================
 *    Client section
 *    ================================ */

.clients-style1 img {
  opacity: 1;
}

.clients-style2 img {
  opacity: .6;
  -webkit-transition-duration: .3s;
  -moz-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;

  &:hover {
    opacity: 1;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .section-clients {
    .item {
      text-align: center;
      width: 100%;
    }

    .owl-carousel .owl-item img {
      max-width: 75%;
      display: inline-block;
    }
  }
}

/*==============================
 *    Counter box
 *    ================================ */

/* counter-box01 */
.counter-box01 {
  h3, h4 {
    display: inline-block;
    font-weight: 600;
  }
}

.social-icons a:hover {
  opacity: 1;
}

.counter-box01 {
  position: relative;
  text-align: center;

  h3:after {
    content: '+';
  }

  h4 {
    font-size: 28px;
    line-height: normal;
    margin: 0;

    &:after {
      content: '+';
    }
  }

  h3 {
    font-size: 45px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .counter-box01 {
    p {
      &:before {
        margin: 0;
        position: absolute;
        top: 45px;
      }

      &:after {
        margin: 0;
        left: 14px;
        right: 0;
        top: 45px;
      }
    }

    &.black p:after {
      margin: 0;
      left: 14px;
      right: 0;
      top: 45px;
    }

    h4 {
      font-size: 22px;
    }
  }
}


/* counter-box02 */
.counter-box02 {
  position: relative;

  .right-shape {
    position: absolute;
    right: 0;
    bottom: -7px;
    width: 300px;
  }

  .left-shape {
    position: absolute;
    background: url(../img/bg/bg-03.png) no-repeat center top;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-size: cover;
    opacity: 0.1;
  }
}

@media screen and (max-width: $desk-lg-screen) {
  .counter-box02 .right-shape {
    right: -100px;
    bottom: -34px;
  }
}

/*==============================
 *    Testmonials
 *    ================================ */

/*testimonial style01*/
.testmonial-style01 {
  .avatar-info {
    overflow: hidden;
  }

  h6 {
    color: $PrimaryColor;
    font-weight: 500;
  }

  .avatar-info {
    h4 {
      font-size: 18px;
      margin-bottom: 0;
      color: $PrimaryColor;
    }

    span {
      font-size: 13px;
      font-weight: bold;
    }
  }

  .avatar-photo {
    display: inline-block;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    overflow: hidden;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    max-width: 130px;
    height: 130px;
    width: 100%;
    vertical-align: top;
    margin-top: 15px;

    img {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      transform-origin: center center;
      max-width: none;
      width: auto;
      margin-left: -30px;
      margin-top: -35px;
    }
  }

  .item-inner {
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
  }
}

.testmonials-style1 .custom-dot span {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  background: rgba($PrimaryRgbColor, 0.15);
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;

  &:hover {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    background: $PrimaryColor;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .testmonial-style01 .avatar-info h4 {
    font-size: 16px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .testmonials-style1 .custom-dot {
    margin-top: 10px;
  }
}

/* testimonial style02*/
.testmonial-style02 {
  .author-details {
    .location {
      font-style: italic;
    }

    span {
      display: block;
      text-align: left;
      font-weight: 600;
    }
  }

  p {
    font-size: 18px;
    line-height: 36px;
    margin-bottom: 50px;
    font-style: italic;
    margin-top: -115px;
  }

  .quote {
    font-size: 234px;
    opacity: 0.1;
    line-height: 210px;
  }

  &.owl-theme .owl-nav {
    margin-top: 0;

    .owl-next {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: white;
      -webkit-box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.06);
      box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.06);
      font-size: 20px;
      color: #222d39;
      border: 0px;
      line-height: 50px;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      transition: all 0.2s linear;
      cursor: pointer;
      right: 0;
      padding: 0;
    }

    .owl-prev {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #fff !important;
      -webkit-box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.06);
      box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.06);
      font-size: 20px;
      color: #222d39;
      border: 0px;
      line-height: 50px;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      transition: all 0.2s linear;
      cursor: pointer;
      left: 0;
      padding: 0;
    }

    > div:hover {
      background: $PrimaryColor !important;
      color: #fff;
      -webkit-box-shadow: none;
      box-shadow: none;

      i {
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .testmonial-style02 {
    p {
      font-size: 16px;
      line-height: 34px;
    }

    .quote {
      font-size: 214px;
      line-height: 200px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .testmonial-style02 {
    &.owl-theme .owl-nav {
      margin-top: 0;
    }

    p {
      margin-bottom: 35px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .testmonial-style02 {
    p {
      font-size: 15px;
      line-height: 32px;
    }

    .quote {
      font-size: 175px;
    }
  }
}

/* testimonial style03 */
.testmonial-style03 {
  .testmonial-block3 {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 5px 24px 6px rgba(0, 0, 0, 0.06);
    padding: 45px 40px;
    margin: 30px 24px 40px;
    position: relative;

    p {
      margin-top: 50px;
      margin-bottom: 10px;
      font-size: 15px;
      line-height: 30px;
    }
  }

  .quote {
    position: absolute;
    top: 30px;
    font-size: 124px;
    opacity: 0.2;
    line-height: 130px;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .testmonial-style03 .testmonial-block3 {
    padding: 45px 25px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .testmonial-style03 .testmonial-block3 {
    padding: 35px 20px;
  }
}

/* testimonial style04 */
.testmonial-style04 {
  margin: 0 6%;
  position: relative;
  z-index: 1;
  text-align: center;
  font-weight: 500;

  &:before {
    content: '';
    position: absolute;
    bottom: -30px;
    left: 35px;
    right: 35px;
    height: 200px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 12px 25px 0px rgba(0, 0, 0, 0.05);
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -55px;
    left: 80px;
    right: 80px;
    height: 200px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 15px 22px 0px rgba(0, 0, 0, 0.03);
    z-index: -3;
  }

  .testmonial-block {
    background: #fff;
    padding: 60px 80px;
    box-shadow: 0px 16px 25px 0px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    position: relative;
    z-index: 5;

    .quote {
      position: absolute;
      top: 140px;
      left: 0;
      right: 0;
      font-size: 220px;
      color: #f0f0f0;
      line-height: 28px;
    }

    p {
      font-size: 22px;
      line-height: 44px;
      font-style: italic;
      padding-bottom: 30px;
    }
  }

  .testmonial-carousel {
    margin-top: 70px;
  }

  .testmonial-block h6 {
    font-weight: normal;

    &:before {
      content: "";
      width: 20px;
      height: 1px;
      background: #596982;
      display: inline-block;
      margin-right: 8px;
      vertical-align: middle;
    }
  }

  .testmonial-carousel {
    &.owl-theme .owl-nav {
      margin-top: 50px;
    }

    .owl-nav {
      .owl-prev, .owl-next {
        background: transparent !important;
        box-shadow: none;
        width: auto;
        height: auto;
        margin: 0;
        font-size: 36px;
        color: #596982;
        line-height: normal;
      }
    }

    &.owl-theme .owl-nav [class*='owl-']:hover {
      color: #243f67;
    }

    .owl-nav i {
      font-size: 36px;
      color: #9fa4b8;
      line-height: normal;
      padding: 0;
    }
  }

  .testmonial-block .author-details {
    text-align: center;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .testmonial-style04 .testmonial-block {
    p {
      font-size: 20px;
      line-height: 40px;
    }

    .quote {
      font-size: 180px;
      top: 120px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .testmonial-style04 {
    .testmonial-block {
      padding: 50px 40px;

      p {
        font-size: 18px;
        line-height: 36px;
        padding-bottom: 15px;
      }
    }

    .testmonial-carousel {
      &.owl-theme .owl-nav {
        margin-top: 30px;
      }

      .owl-nav {
        i {
          font-size: 26px;
        }

        .owl-prev, .owl-next {
          font-size: 32px;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .testmonial-style04 {
    margin: 0;

    .testmonial-block {
      padding: 30px 40px;

      p {
        font-size: 16px;
        line-height: 34px;
        padding-bottom: 10px;
      }

      .quote {
        font-size: 150px;
        top: 100px;
      }
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .testmonial-style04 {
    .testmonial-block {
      .quote {
        top: 80px;
      }

      padding: 15px;

      p {
        font-size: 15px;
        line-height: 30px;
      }

      .owl-item img {
        max-width: 70px;
      }
    }

    margin: 0;

    .testmonial-carousel.owl-theme .owl-nav {
      margin-top: 20px;
    }
  }
}


/* testimonial style05 */
.testmonial-style05 {
  .active.center {
    .card {
      background: $PrimaryColor;

      * {
        color: #fff;
      }
    }

    .bg-purple {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .card {
    box-shadow: 0px 0px 16px 0px rgba(187, 187, 187, 0.48);
    margin: 30px 15px;
    border: none !important;
  }
}

/* testimonial style06 */
.testmonial-style06 {
  border-radius: 5px;
  border: 1px solid #eee;
  background: #fff;
  overflow: visible;
  position: relative;

  &:before {
    content: "\f10e";
    font-weight: 700;
    color: $PrimaryColor;
    border-radius: 50px;
    top: -15px;
    font-size: 30px;
    right: 40px;
    font-family: Font Awesome\ 5 Free;
    position: absolute;
  }
}

/* testimonial style07 */
.testmonial-style07 {
  p {
    font-size: 24px;
    margin-bottom: 4rem;
    letter-spacing: 0.5px;
    line-height: 1.9;
    font-weight: 300;
  }

  h4 {
    font-size: 18px;
    margin-bottom: 0;
  }

  small {
    color: #fff;
  }

  .quote {
    font-size: 48px;
    margin-bottom: 2rem;
    color: #fff;
    display: block;
  }

  .owl-nav.disabled + .owl-dots {
    margin-top: 40px;
  }

  .owl-dots .custom-dot {
    span {
      background: rgba(255, 255, 255, 0.15);
      border: none;
    }

    &.active span, &:hover span {
      background: #fff;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .testmonial-style07 p {
    font-size: 20px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .testmonial-style07 p {
    font-size: 18px;
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .testmonial-style07 {
    .quote {
      font-size: 42px;
    }

    p {
      margin-bottom: 3rem;
      font-size: 16px;
    }
  }
}

/* testimonial style08 */
.testmonial-style08 {
  background-image: linear-gradient(to bottom, $PrimaryColor 0%, $PrimaryColor 50%, $PrimaryColor 50%, white 50%, white 100%);
}

/*==============================
 *    Owl carousel
 *    ================================ */

.custom-dot {
  display: inline-block;
  z-index: 1;
  margin-top: 20px;

  span {
    width: 12px;
    height: 12px;
    margin: 5px 5px 0 5px;
    background: none;
    cursor: pointer;
    display: block;
    border: 1px solid #6E6E6F;
    -webkit-backface-visibility: visible;
    -moz-backface-visibility: visible;
    -o-backface-visibility: visible;
    border-radius: 30px;
    transition-duration: .3s;
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;

    &:hover {
      background: #aaa;
      -webkit-backface-visibility: visible;
      -moz-backface-visibility: visible;
      -o-backface-visibility: visible;
      border-radius: 30px;
    }
  }

  &.active span {
    width: 12px;
    height: 12px;
    background: $PrimaryColor;
    border-color: $PrimaryColor;
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s;
  }
}

.owl-nav {
  i {
    color: #1d184a;
    font-size: 18px;
    padding-top: 6px;
  }

  .owl-next {
    color: $PrimaryColor;
    font-size: 43px;
    text-align: center;
    width: 40px;
    height: 40px;
    background: #fff !important;
  }

  .owl-prev {
    color: $PrimaryColor;
    font-size: 43px;
    text-align: center;
    width: 40px;
    height: 40px;
    background: #fff !important;
    left: 0;
    -webkit-transition: all .4s ease 0s;
    -moz-transition: all .4s ease 0s;
    -o-transition: all .4s ease 0s;
    transition: all .4s ease 0s;
    -webkit-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    -moz-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    -o-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
  }

  .owl-next {
    -webkit-transition: all .4s ease 0s;
    -moz-transition: all .4s ease 0s;
    -o-transition: all .4s ease 0s;
    transition: all .4s ease 0s;
    -webkit-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    -moz-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    -o-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .custom-dot {
    margin-top: 15px;
  }
}

/*==============================
 *    Team styles
 *    ================================ */

/* team-style01 */
.team-style01 {
  border: 1px dashed #cee1f8;
  border-radius: 0.25rem;
  background: #fff;
  position: relative;
  transition: all 0.3s ease 0s;

  .team-member-img > img {
    max-width: 150px;
    padding: 6px;
    background: rgba($PrimaryRgbColor, 0.15);
    border-radius: 100px;
  }

  .social-links {
    margin-bottom: 0;

    li {
      display: inline-block;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      a:hover {
        opacity: 0.85;
      }
    }
  }

  .bg-cover {
    background: $PrimaryColor;
    background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    border-radius: 0.25rem;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease 0s;
  }

  &:hover {
    .team-member-img {
      position: relative;
      z-index: 9;

      > img {
        background: rgba(255, 255, 255, 0.15);
      }
    }

    .bg-cover {
      opacity: 1;
    }

    .alt-font {
      color: #fff;
    }

    .bg-theme {
      background: #fff;
    }

    div, .social-links a {
      color: #fff;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .team-style01 .team-member-img > img {
    max-width: 120px;
    padding: 4px;
  }
}

/* team-style02 */
.team-style02 {
  box-shadow: 0px 0px 16px 0px rgba(187, 187, 187, 0.48);
  background: #fff;
  overflow: hidden;
  transition: all .3s ease;
  z-index: 1;
  position: relative;
  border-radius: 4px;

  .team-img {
    overflow: hidden;
    position: relative;

    img {
      transition: all .7s ease-in-out;
    }
  }

  .team-icons {
    position: absolute;
    bottom: 35%;
    left: 0;
    right: 0;
    text-align: center;
    transition: opacity .3s,bottom .3s;
    display: block;
    opacity: 0;

    i {
      background: #fff;
      height: 40px;
      width: 40px;
      line-height: 40px;
      border-radius: 50px;
      margin-left: 10px;
    }
  }

  &:hover .team-icons {
    opacity: 1;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .team-style02 {
    .team-img img {
      width: 100%;
    }

    .team-icons {
      bottom: 120px;
    }
  }
}

/* team style03 */
.team-style03 {
  overflow: hidden;

  .team-img {
    width: 100%;
    height: auto;
    border-radius: 4px;

    img {
      border-radius: 4px;
    }
  }

  .team-details {
    transition: all 0.4s ease-in-out;
    text-align: center;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
    width: 90%;
    margin: -30px auto 20px auto;
    position: relative;
    z-index: 1;
    border-radius: 4px;

    h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 4px;

      a {
        color: #1d184a;
        transition-duration: .0s;
        -ms-transition-duration: .0s;
        -moz-transition-duration: .0s;
        -webkit-transition-duration: .0s;
        -o-transition-duration: .0s;
      }
    }

    span {
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .team-style03 .team-details {
    padding: 15px 10px;

    h4 {
      font-size: 14px;
      margin-bottom: 2px;
    }

    span {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .team-style03 .team-details {
    padding: 15px 10px;
  }
}

/* team style04 */
.team-style04 {
  .team-thumb {
    overflow: hidden;
    border-radius: 5px;
  }

  .team-details {
    position: relative;
    width: calc(100% - 30px);
    margin-top: -45px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    box-shadow: 0 6px 47px 3px rgba(37, 37, 37, 0.05);
  }

  &:hover .team-thumb img {
    transform: scale(1.05);
  }

  .team-thumb img {
    transition: all 0.3s linear 0s;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .team-style04 .team-details {
    width: calc(100% - 30px);
  }
}

/* team style05 */
.team-style05 {
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  position: relative;
  display: block;
  text-align: center;

  .team-img {
    position: relative;
    overflow: hidden;
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: all 0.5s ease;
    background-image: linear-gradient(45deg, rgba($PrimaryRgbColor, 0.48) 0, rgba(0, 132, 233, 0.5) 100%);
  }

  .team-details {
    position: absolute;
    bottom: -35px;
    right: 0;
    left: 0;
    margin: auto 10%;
    z-index: 99;
    padding: 15px 0;
    background: #fff;
  }

  .team-social {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto 10%;
    z-index: 99;
    bottom: 70px;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
    margin-bottom: 0;

    li {
      display: inline-block;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }

      a {
        background: $PrimaryColor;
        background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
        background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
        background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
        background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
        width: 35px;
        height: 35px;
        border-radius: 50px;
        line-height: 35px;
        text-align: center;
        display: inline-block;
        color: #fff;
      }
    }
  }

  &:hover {
    .overlay {
      opacity: 0.6;
    }

    .team-social {
      opacity: 1;
    }

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }
}

/* team style06 */
.team-style06 {
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  position: relative;
  display: block;
  text-align: center;

  .team-img {
    position: relative;
    overflow: hidden;
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: all 0.5s ease;
    background-image: linear-gradient(45deg, rgba($PrimaryRgbColor, 0.48) 0, rgba(0, 132, 233, 0.5) 100%);
  }

  .team-details {
    position: absolute;
    bottom: -35px;
    right: 0;
    left: 0;
    margin: auto 10%;
    z-index: 99;
    padding: 15px 0;
    background: #fff;
  }

  .team-social {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto 10%;
    z-index: 99;
    bottom: 70px;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
    margin-bottom: 0;

    li {
      display: inline-block;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }

      a {
        background: $PrimaryColor;
        background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
        background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
        background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
        background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
        width: 35px;
        height: 35px;
        border-radius: 50px;
        line-height: 35px;
        text-align: center;
        display: inline-block;
        color: #fff;
      }
    }
  }

  &:hover {
    .overlay {
      opacity: 0.6;
    }

    .team-social {
      opacity: 1;
    }

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }
}

/*==============================
 *    Project grid
 *    ================================ */

/* filtering */
.filtering {
  margin-bottom: 10px;

  span {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    color: #333333;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-right: 20px;
    display: inline-block;
    margin-bottom: 5px;

    &:last-child {
      margin: 0;
    }
  }

  .active {
    border-color: $PrimaryColor;
    color: $PrimaryColor;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .filtering span {
    margin-right: 15px;
  }
}

/*==============================
 *    Portfolio
 *    ================================ */

.portfolio {
  overflow: hidden;

  .filtering {
    span {
      margin-right: 20px;
      color: #333;
      letter-spacing: 1px;
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
      border-bottom: 1px solid transparent;

      &:last-child {
        margin: 0;
      }
    }

    .active {
      border-color: $PrimaryColor;
      color: $PrimaryColor;
    }
  }

  .items {
    margin-top: 20px;
  }

  .item-img {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
    }

    &:hover {
      .item-img-overlay {
        opacity: 1;
        transform: translateY(0);
      }

      h6 {
        transform: translateY(0);
      }
    }

    h6 {
      font-weight: 600;
      position: relative;
      font-size: 16px;
      margin-bottom: 0;
      transform: translateY(-15px);
      transition: all 0.5s;
    }

    p {
      margin-bottom: 8px;
    }

    .fa {
      background: $PrimaryColor;
      border: 1px solid $PrimaryColor;
      border-radius: 50%;
      color: #fff;
      display: inline-block;
      height: 30px;
      line-height: 28px;
      width: 30px;
      transition: all 0.5s;

      &:hover {
        background: #fff;
        color: $PrimaryColor;
      }
    }
  }

  .item-img-overlay {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    background: rgba(255, 255, 255, 0.95);
    color: #35424C;
    opacity: 0;
    transition: all 0.5s;
    z-index: 2;
    transform: translateY(10px);
  }
}

.project-single-text {
  h4:after {
    content: " ";
    background: $PrimaryColor;
    height: 2px;
  }

  p {
    font-weight: 400;
    line-height: 1.8;
  }

  h5 {
    color: #1d184a;
    font-size: 18px;
    font-weight: 500;
    margin: 25px 0 20px;

    &:after {
      display: block;
      width: 70px;
      margin-top: 8px;
      content: " ";
      background: $PrimaryColor;
      height: 2px;
    }
  }

  ul li {
    color: #1d184a;
    font-size: 15px;
    font-weight: 500;

    i {
      color: $PrimaryColor;
      font-weight: 500;
      font-size: 15px;
      padding-right: 10px;
    }
  }
}

.project-single-info {
  display: block;
  width: 100%;

  ul {
    list-style: none;

    li {
      color: #777;
      font-size: 15px;
      font-weight: 500;
      border-bottom: 1px dashed #cee1f8;
      padding: 12px 0;
      margin-right: 20px;

      span {
        display: inline-block;
        color: #1d184a;
        font-size: 15px;
        font-weight: 500;
        margin-right: 25px;
        width: 70px;
        max-width: 100%;
      }

      .value {
        width: auto;
        margin-right: 0;
        color: #5a5d80;
      }
    }
  }
}

.project-single-box-img img {
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
  -o-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
}

@media screen and (max-width: $tablet-xs-screen) {
  .portfolio {
    overflow: hidden;

    .filtering {
      margin-bottom: 5px;

      span {
        margin-right: 15px;
        font-size: 12px;
      }
    }
  }
}

/*==============================
 *    Accordion style
 *    ================================ */
.accordion-style01 {
  background: #fff;
  box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.02);
  border-radius: 4px;

  .card {
    .counts {
      display: inline-block;
      width: 30px;
      height: 30px;
      font-weight: 600;
      vertical-align: middle;
      line-height: 30px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -o-border-radius: 100px;
      border-radius: 100px;
      text-align: center;
      background: $PrimaryColor;
      background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
      background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
      background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
      background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
      color: #fff;
      font-size: 12px;
      margin-right: 10px;
    }

    .item-title {
      display: inline-block;
      vertical-align: middle;
      width: 88%;
    }
  }

  .card-header:hover {
    background-color: #F3F8FF;
  }

  .card {
    background: #fff;
    box-shadow: none;
    margin-top: 0 !important;
    border: none !important;

    &:last-child {
      border: none;
    }
  }

  .card-header {
    border: 0px;
    padding: 0;
    transition: all 0.3s ease 0s;
    background: #fff;
  }

  .btn-link {
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
    color: #5a5d80;
    display: block;
    text-decoration: none !important;
    margin: 0;
    cursor: pointer;
    padding: 15px 20px;
    border-bottom: 1px dashed #cee1f8;
    white-space: normal;
  }

  .card:last-child .btn-link.collapsed {
    border-bottom: transparent;
  }

  .btn-link {
    background: $PrimaryColor;
    background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    color: #fff;
    border-bottom: transparent;
    border-left: none !important;

    &.collapsed {
      box-shadow: none;
      background: none;
      color: #5a5d80;
      border-bottom: 1px dashed #cee1f8;
    }
  }

  .btn:not(:disabled):not(.disabled) {
    &.active, &:active {
      background: $PrimaryColor;
      background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
      background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
      background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
      background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
      color: #fff;
      border-bottom: transparent;
      border-left: none !important;
    }
  }

  .btn-link {
    &.collapsed .counts {
      background: transparent;
      border: 1px solid #cee1f8;
      color: $PrimaryColor;
    }

    .counts {
      background: #fff;
      border: 1px solid #cee1f8;
      color: $PrimaryColor;
    }
  }

  .btn:not(:disabled):not(.disabled) {
    &.active .counts, &:active .counts {
      background: #fff;
      border: 1px solid #cee1f8;
      color: $PrimaryColor;
    }
  }

  .card:last-child {
    border-bottom: 1px dashed #cee1f8;

    .show .card-body {
      border-bottom: transparent;
    }
  }

  .card-body {
    line-height: 26px;
    font-size: 15px;
    padding: 20px;
    border-bottom: 1px dashed #cee1f8;
    text-align: left;
  }

  .btn:not(:disabled):not(.disabled):active {
    background: $PrimaryColor;
    background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .accordion-style01 {
    .btn-link {
      font-size: 14px;
      padding: 12px 18px;
    }

    .card .counts {
      margin-right: 8px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .accordion-style01 {
    .card .item-title {
      width: 80%;
    }

    .btn-link {
      font-size: 14px;
      padding: 12px 15px;
    }
  }
}

/*accordion style02*/
.accordion-style02 {
  .card {
    background: transparent;
    box-shadow: none;
    margin-top: 0 !important;
    border: none;
  }

  .card-header {
    border: 0px;
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    background: none;
  }

  .btn-link {
    background: $PrimaryColor !important;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    border: none;
    display: block;
    width: 100%;
    text-align: left;
    white-space: normal;
    border-radius: 4px;
    padding: 15px 40px 15px 15px;
    box-shadow: none;
    text-decoration: none;

    &:hover {
      background: rgba($PrimaryRgbColor, 0.7) !important;
      border: none;
      text-decoration: none;
    }

    &.collapsed:after {
      background: none;
      content: "+";
      right: 15px;
      left: inherit;
      font-size: 20px;
      height: auto;
      transform: none;
      width: auto;
      top: 15px;
    }

    &:after {
      background: none;
      content: "-";
      right: 20px;
      left: inherit;
      font-size: 20px;
      height: auto;
      transform: none;
      width: auto;
      top: 15px;
      position: absolute;
      color: #fff;
    }
  }

  .card-body {
    padding: 20px;
    text-align: left;
    line-height: 2;
    font-size: 14px;
  }

  .btn-link.collapsed {
    background: #ededed !important;
    color: #282b2d;

    &:after {
      background: none;
      content: "+";
      right: 20px;
      left: inherit;
      font-size: 20px;
      height: auto;
      transform: none;
      width: auto;
      top: 15px;
      color: #282b2d !important;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .accordion-style02 .card-body {
    padding: 15px;
  }
}

/*==============================
 *    Tabs
 *    ================================ */

ul.resp-tabs-list {
  margin: 0px;
  padding: 0px;
}

.resp-tabs-list li {
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  padding: 8px 15px;
  margin: 0 4px 0 0;
  list-style: none;
  cursor: pointer;
  min-width: 180px;

  /*color: #000;*/

  &:last-child {
    margin-right: 0;
  }

  i {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    background: rgba($PrimaryRgbColor, 0.15);
    text-align: center;
    font-size: 22px;
    border-radius: 50%;
    margin-bottom: 10px;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    color: $PrimaryColor;
  }
}

.resp-tabs-container {
  padding: 0px;
  background-color: #fff;
  clear: left;
}

.resp-tab-content {
  display: none;
  padding: 50px 0 0 0;
}

.resp-tabs-list li.resp-tab-active {
  padding: 15px 20px 13px 20px;
  color: $PrimaryColor;
  -webkit-border-top-left-radius: 5px;
  -moz-border-top-left-radius: 5px;
  -o-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-top-right-radius: 5px;
  -o-border-top-right-radius: 5px;
  -webkit-border-radius-topleft: 5px;
  -moz-border-radius-topleft: 5px;
  -o-border-radius-topleft: 5px;
  -webkit-border-radius-topright: 5px;
  -moz-border-radius-topright: 5px;
  -o-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  i {
    background: $PrimaryColor;
    background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    color: #fff;
  }
}

.resp-content-active, .resp-accordion-active {
  display: block;
}

.resp-tab-content {
  border: 1px solid #c1c1c1;
  border-top-color: #c1c1c1;
  float: left;
  width: 100%;
}

h2 {
  &.resp-accordion {
    background: #fff !important;
    cursor: pointer;
    display: none;
    font-size: 14px;
    border: 1px solid #e4e4e4;
    border-top: 0px solid #e4e4e4;
    margin: 0px;
    padding: 15px 21px;
    float: left;
    width: 100%;
  }

  &.resp-tab-active {
    border-bottom: 0px solid #e4e4e4 !important;
    background: $PrimaryColor !important;
    background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor) !important;
    background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor) !important;
    background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor) !important;
    background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor) !important;
    color: #fff;
  }

  &.resp-tab-title:last-child {
    border-bottom: 12px solid #e4e4e4 !important;
    background: $PrimaryColor;
    background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  }
}

/* vertical tabs */
.resp-vtabs {
  ul.resp-tabs-list {
    float: left;
    width: 30%;
  }

  .resp-tabs-list li {
    display: block;
    padding: 15px 15px !important;
    margin: 0 0 4px;
    cursor: pointer;
    float: none;
    font-size: 18px;

    p {
      font-size: 16px;
    }
  }

  .resp-tabs-container {
    padding: 0px;
    background-color: #fff;
    border: 1px solid $PrimaryColor !important;
    float: left;
    width: 70%;
    min-height: 250px;
    clear: none;
  }

  .resp-tab-content {
    border: none;
    word-wrap: break-word;
    padding: 0;
  }

  li.resp-tab-active {
    position: relative;
    z-index: 1;
    margin-right: -1px !important;
    padding: 14px 15px 15px 11px !important;
    border-top: 1px solid;
    border: 1px solid $PrimaryColor !important;
    border-left: 4px solid $PrimaryColor !important;
    border-right: 1px #FFF solid !important;
  }
}

.resp-arrow {
  border-color: transparent #1d184a #1d184a transparent;
  border-style: solid;
  border-width: 0 1px 1px 0;
  float: right;
  display: block;
  height: 8px;
  transform: rotate(45deg);
  width: 8px;
  margin-top: 4px;
}

h2.resp-tab-active span.resp-arrow {
  border-color: #fff transparent transparent #fff;
  border-style: solid;
  border-width: 1px 0 0 1px;
  float: right;
  display: block;
  height: 8px;
  transform: rotate(45deg);
  width: 8px;
  margin-top: 7px;
}

/* accordion styles */
.resp-easy-accordion {
  h2.resp-accordion {
    display: block;
  }

  .resp-tab-content {
    border: 1px solid #c1c1c1;

    &:last-child {
      border-bottom: 1px solid #c1c1c1;

      /* !important; */
    }
  }
}

.resp-jfit {
  width: 100%;
  margin: 0px;
}

.resp-tab-content-active {
  display: block;
  border-color: #e4e4e4 !important;
}

h2.resp-accordion {
  &:first-child {
    border-top: 1px solid #e4e4e4;
  }

  &.resp-tab-active {
    border-color: #e4e4e4 !important;
  }
}

/* tab-style01 */
@media screen and (min-width: $desk-sm-screen) {
  .tab-style01 {
  ul.resp-tabs-list {
    text-align: center;

    &.text-left {
      padding-left: 15px;
    }

    li {
      &:last-child {
        margin-right: 0;
      }

      &.resp-tab-active {
        color: $PrimaryColor;
        border-bottom: 3px solid $PrimaryColor;
      }
    }
  }

  .resp-tab-content {
    border-top: 1px dashed #cee1f8 !important;
    border-width: 1px 0 0 0;
    border-color: $PrimaryColor;
    margin-top: -2px;
  }
}

/* tab-style02 */
  .tab-style02 {
  ul.resp-tabs-list {
    text-align: center;

    &.text-left {
      padding-left: 15px;
    }

    li {
      &:last-child {
        margin-right: 0;
      }

      &.resp-tab-active {
        color: $PrimaryColor;
      }
    }
  }

  .resp-tab-content {
    border-width: 0 0 0 0;
    border-color: $PrimaryColor;
    margin-top: 0;
  }

  .tab-box {
    margin-right: -120px;
    z-index: 99;
    position: relative;
    background: #fff;
    box-shadow: 0 0 30px rgba(83, 88, 93, 0.15);
    padding: 3rem;

    a {
      font-size: 14px;
      letter-spacing: 0.8px;
      font-weight: 500;

      &:hover {
        color: #1d184a;
      }
    }

    &.left {
      margin-left: -120px;
      margin-right: 0;
    }
  }

  .tab-img {
    margin-left: -60px;

    &.right {
      margin-right: -60px;
      margin-left: 0;
    }
  }

  .resp-tab-content {
    border-top: none !important;
  }

  .resp-tabs-list li {
    i {
      height: auto;
      width: auto;
      line-height: normal;
      background: none;
      font-size: 30px;
      margin-bottom: 10px;
      display: block;
      color: #5a5d80;
    }

    &.resp-tab-active {
      border-bottom: none;
      position: relative;

      i {
        color: $PrimaryColor;
      }
    }
  }

  ul.resp-tabs-list li.resp-tab-active:before {
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $PrimaryColor;
    bottom: 0;
    position: absolute;
    left: 48%;
  }
}

}
/* change the breakpoint to set the accordion */
@media screen and (max-width: $desk-xs-screen) {
  ul.resp-tabs-list {
    display: none;
  }

  h2.resp-accordion {
    display: block;

    i {
      margin-right: 12px;
      font-size: 18px;
      min-width: 25px;
    }

    &.resp-tab-active i {
      color: #fff;
      display: inline-block;
      vertical-align: top;
    }
  }

  .resp-vtabs {
    .resp-tab-content {
      border: 1px solid #e4e4e4;
    }

    .resp-tabs-container {
      border: none !important;
      float: none;
      width: 100%;
      min-height: 100px;
      clear: none;
    }
  }

  .resp-accordion-closed {
    display: none !important;
  }

  .resp-vtabs .resp-tab-content:last-child {
    border-bottom: 1px solid #e4e4e4 !important;
  }

  .resp-tab-content {
    background-color: #fff;
    padding: 20px 15px;
  }
}

/* tab-style03 */
.tab-style03 {
  ul.resp-tabs-list {
    text-align: center;
  }

  .resp-tabs-list li {
    color: $PrimaryColor;
    border: 2px solid transparent;
    background: rgba($PrimaryRgbColor, 0.15);
    margin: 0;
    padding: 15px 20px 13px 20px;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-left-radius: 0;
    }

    &.resp-tab-active {
      color: #fff;
      background: $PrimaryColor;
    }
  }

  .resp-tab-content {
    border: none;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .tab-style03 .resp-tabs-list li {
    padding: 10px;
    min-width: 150px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .tab-style03 {
    .resp-tab-content {
      border: 1px solid #d5d5d5;
      padding: 40px 35px;
    }

    h2.resp-tab-active {
      background: $PrimaryColor !important;
    }
  }

  .tab-style02 {
    .tab-box {
      margin-right: auto;

      &.left {
        margin-left: auto;
      }
    }

    .tab-img {
      margin-left: auto;

      &.right {
        margin-right: auto;
      }
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .tab-style03 .resp-tab-content {
    padding: 25px 15px;
  }
}


/* tab-style2 */
.tab-style2 {
  text-align: center;

  .resp-tabs-container {
    text-align: left;
  }

  ul.resp-tabs-list {
    text-align: center;
    background: $PrimaryColor;
    padding: 8px;
    display: table;
    border-radius: 10rem;
    width: 55%;
    margin: 0 auto;
  }

  .resp-tabs-list li {
    border: 2px solid transparent;
    margin: 0;
    padding: 0.425rem 1.125rem;
    border-radius: 10rem;
    min-width: auto;
    font-size: 16px;
    font-weight: normal;
    color: #fff;
    display: table-cell;

    &.resp-tab-active {
      color: $PrimaryColor;
      background: #fff;
    }
  }

  .resp-tab-content {
    border: none;
    padding: 4rem 0 0 0;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .tab-style2 .resp-tabs-list li {
    padding: 10px;
    min-width: auto;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .tab-style2 {
    ul.resp-tabs-list {
      display: none;
    }

    .resp-tab-content {
      border: 1px solid #d5d5d5;
      padding: 40px 35px;
    }

    h2.resp-tab-active {
      background: $PrimaryColor !important;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .tab-style2 .resp-tab-content {
    padding: 25px 15px;
  }
}


/* vtab-style1 */
.vtab-style1 {
  .resp-tabs-list li {
    background: #fff;

    &.resp-tab-active {
      background: $PrimaryColor;
      background: -webkit-linear-gradient(180deg, $PrimaryColor, $SecondaryColor);
      background: -moz-linear-gradient(180deg, $PrimaryColor, $SecondaryColor);
      background: -o-linear-gradient(180deg, $PrimaryColor, $SecondaryColor);
      background: linear-gradient(180deg, $PrimaryColor, $SecondaryColor);
      padding: 15px 20px 13px 20px;
      color: $PrimaryColor;
      -webkit-border-top-left-radius: 5px;
      -webkit-border-top-right-radius: 5px;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-topright: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &.resp-vtabs {
    ul.resp-tabs-list {
      width: auto;
      float: unset;
    }

    .resp-tabs-list li {
      -webkit-box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.08);
      box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.08);
      margin-bottom: 35px;
      padding: 20px 35px !important;
      color: #1d184a;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    .resp-tabs-container {
      width: auto;
      float: unset;
      border: none !important;
    }

    li.resp-tab-active {
      color: #fff;
      border: none !important;
      border-left: none !important;
      border-radius: 4px !important;

      h1, h2, h3, h4, h5, h6 {
        color: #fff;
      }
    }
  }

  &.resp-tab-content {
    padding-top: 0;
  }

  .resp-tabs-list {
    li.resp-tab-active .icon-circle {
      background: white;
    }

    .tab-desc {
      display: inline-block;
      vertical-align: middle;
      padding-left: 20px;
      max-width: 70%;
      width: 100%;
    }
  }

  .icon-circle {
    vertical-align: middle;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .vtab-style1.resp-vtabs {
    .resp-tabs-list li {
      margin-bottom: 25px;
      padding: 20px !important;
    }

    .icon-circle {
      width: 65px;
      height: 65px;
      line-height: 75px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .vtab-style1 {
    &.resp-vtabs {
      .resp-tabs-container h2.resp-tab-active {
        background: $PrimaryColor !important;
        background: -webkit-linear-gradient(180deg, $PrimaryColor, $SecondaryColor) !important;
        background: -moz-linear-gradient(180deg, $PrimaryColor, $SecondaryColor) !important;
        background: -o-linear-gradient(180deg, $PrimaryColor, $SecondaryColor) !important;
        background: linear-gradient(180deg, $PrimaryColor, $SecondaryColor) !important;
        color: $PrimaryColor;
        -webkit-border-top-left-radius: 0;
        -webkit-border-top-right-radius: 0;
        -moz-border-radius-topleft: 0;
        -moz-border-radius-topright: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .resp-tab-content {
        padding: 20px 20px 40px 20px;
      }
    }

    .resp-accordion {
      &.resp-tab-active .icon-circle {
        background: white;
      }

      .tab-desc {
        display: inline-block;
        vertical-align: middle;
        padding-left: 20px;
        max-width: 71%;
        width: 100%;
        font-size: 16px;
      }

      &.resp-tab-active {
        .tab-desc, h1, h2, h3, h4, h5, h6 {
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .vtab-style1 .resp-accordion .tab-desc {
    font-size: 15px;
    max-width: 68%;
  }
}

/*==============================
 *    Pricing table
 *    ================================ */

/* price table1 */
.single-plan {
  position: relative;
  z-index: 9;
  border: 1px solid #efefef;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);

  .head-plan {
    border-bottom: 1px dashed #cee1f8;

    i {
      background: rgba($PrimaryRgbColor, 0.15);
      color: $PrimaryColor;
      width: 80px;
      height: 80px;
      line-height: 80px;
      font-size: 32px;
      border-radius: 50%;
      margin-top: 3rem;
    }

    h4 {
      padding: 20px;
      margin: 0;
      color: #000;
      font-weight: 600;
    }
  }
}

.body-plan .price-plan {
  position: relative;
  font-weight: bold;

  h3 {
    margin: 0;
    z-index: 11;
    position: relative;
    font-weight: bold;
    color: #5a5d80;

    sup {
      font-size: 18px;
      top: -15px;
      font-weight: bold;
      margin-right: 2px;
    }
  }

  span {
    text-transform: uppercase;
    font-size: 12px;
    z-index: 11;
    position: relative;
  }
}

.feat-plan {
  margin: 0 0 30px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 15px;
      border-bottom: 1px solid #efefef;
      padding-bottom: 15px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.price-plan i {
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  border-radius: 50%;
  position: absolute;
  bottom: -24px;
  left: 42.6%;
  z-index: 99;
  background: $PrimaryColor;
  background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
  background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
}

.footer-plan {
  padding: 0 0 3rem 0;
}

@media screen and (max-width: $desk-xs-screen) {
  .single-plan .head-plan {
    i {
      width: 70px;
      height: 70px;
      line-height: 70px;
      font-size: 28px;
    }

    h4 {
      padding: 15px;
    }
  }

  .body-plan .price-plan h3 sup {
    font-size: 16px;
  }

  .feat-plan {
    margin: 0 0 20px;
  }

  .body-plan .price-plan {
    padding: 20px 0 10px 0;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .single-plan .head-plan i {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 24px;
  }

  .body-plan .price-plan h3 sup {
    font-size: 14px;
  }
}

/* price table2 */
.price {
  .item {
    padding: 0 0 50px 0;
    transition: all .3s;
    background: #fff;

    &:hover {
      box-shadow: 0px 5px 40px 0px rgba(148, 146, 245, 0.2);
    }
  }

  &.price-style1 .item {
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
  }

  .type {
    padding-top: 45px;
    position: relative;

    i {
      background: #ffffff;
      border: 1px solid $PrimaryColor;
      border-radius: 100%;
      color: $PrimaryColor;
      display: inline-block;
      font-size: 30px;
      height: 80px;
      line-height: 78px;
      margin-bottom: 15px;
      width: 80px;
    }
  }
}

.type .top-btn {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -20px;
}

.price {
  .type h4 {
    color: #1d184a;
    font-weight: 700;
    text-transform: uppercase;
  }

  .value {
    position: relative;

    h3 {
      display: inline-block;
      font-size: 40px;
      font-weight: 700;
      position: relative;
      margin-bottom: 0;
      color: #5a5d80;

      span {
        font-size: 18px;
        position: absolute;
        top: -2px;
        left: -12px;
        color: #5a5d80;
      }
    }

    .per {
      font-size: 13px;
      color: #5a5d80;
    }
  }

  .features {
    ul {
      list-style: none;
    }

    li {
      padding: 20px 0;
      font-weight: 600;
      font-size: 15px;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    }
  }

  .order {
    padding-top: 15px;
    position: relative;
  }

  .item.active {
    background: $PrimaryColor;
    background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);

    h3, h4 {
      color: #fff;
    }

    .value {
      h3 span, .per {
        color: #fff;
      }
    }

    .features li {
      border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
      color: #fff;
    }

    &.bg-theme-90 {
      background: $PrimaryColor;
      background: -webkit-linear-gradient(90deg, $PrimaryColor, $SecondaryColor);
      background: -moz-linear-gradient(90deg, $PrimaryColor, $SecondaryColor);
      background: -o-linear-gradient(90deg, $PrimaryColor, $SecondaryColor);
      background: linear-gradient(90deg, $PrimaryColor, $SecondaryColor);
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .price .value h3 {
    font-size: 36px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .price {
    .item {
      padding-bottom: 40px;
    }

    .type {
      padding-top: 35px;
    }

    .features li {
      padding: 15px 0;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .price {
    .item {
      padding-bottom: 30px;
    }

    .value {
      h3 {
        font-size: 32px;

        span {
          font-size: 13px;
          top: 0px;
          left: -10px;
        }
      }

      .per {
        font-size: 12px;
      }
    }
  }
}

/* price style3 */
.price-table {
  text-align: center;
  background: #fff;
  padding: 55px 55px 140px 55px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  height: 100%;
  margin-bottom: 30px;
  position: relative;
  background: #fff;
  box-shadow: 0px 3px 10px 0px rgba($PrimaryRgbColor, 0.08);
  border-radius: 4px;
  margin: 0px 10px 0px 0px;

  .price-header {
    text-align: center;

    .price {
      font-size: 36px;
      margin-bottom: 15px;
      font-weight: 600;
      letter-spacing: 2px;
      line-height: normal;
    }

    .title {
      font-size: 28px;
      padding-bottom: 5px;
      margin-bottom: 15px;
    }

    .package {
      font-size: 16px;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }

  .price-body {
    border-top: 1px dashed #d8dde1;
    padding-top: 40px;

    .feature {
      font-size: 20px;
      color: #303858;
      padding-bottom: 15px;
    }

    ul li {
      font-size: 16px;
      position: relative;
      line-height: 40px;
      display: block;
      padding-bottom: 5px;
    }
  }

  .price-footer {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
  }

  .trial-button {
    display: block;
    text-align: center;
    color: #818386;
    margin-top: 32px;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .price-table {
    padding: 35px 35px 120px 35px;

    .price-header {
      .title {
        margin-bottom: 10px;
        padding-bottom: 10px;
      }

      .price {
        margin-bottom: 25px;
        font-size: 34px;
      }
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .price-table {
    margin-right: 0;

    .price-header {
      .title {
        padding-bottom: 0;
      }

      .price {
        margin-bottom: 20px;
        font-size: 30px;
      }
    }

    .price-body {
      padding-top: 30px;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .price-table {
    padding: 25px 15px 120px 15px;

    .price-header {
      .price {
        margin-bottom: 15px;
        font-size: 26px;
      }

      .package {
        font-size: 15px;
      }
    }
  }
}

/* price-block */
.price-block {
  position: relative;
  box-shadow: 0px 5px 24px 6px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  transition: all 0.5s ease-in-out 0s;
  height: 100%;

  ul li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.2rem;

    i {
      margin-top: 5px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/*==============================
 *    Widget block
 *    ================================ */

.widget-block {
  border: 1px solid #f5f5f5;
  -webkit-box-shadow: 0 2px 4px 0 rgba(12, 0, 46, 0.04);
  box-shadow: 0 2px 4px 0 rgba(12, 0, 46, 0.04);
  padding: 40px;
  margin-bottom: 50px;
  word-break: break-word;

  &:last-child, &.last {
    margin-bottom: 0;
  }

  h4 {
    font-size: 22px;
    color: $PrimaryColor;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 1.5rem;
    margin-bottom: 3rem;
  }

  .list-style-icon {
    list-style: none;
    margin-bottom: 0;
    padding-bottom: 0;

    li {
      border-bottom: 1px solid #e4e4e4;
      color: #596982;
      padding-left: 35px;
      position: relative;
      margin-bottom: 30px;
      padding-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }

      i {
        position: absolute;
        left: 0;
        top: 4px;
        color: $PrimaryColor;
        font-size: 20px;
      }

      h6 {
        font-size: 16px;
        margin-bottom: 5px;
        color: #243f67;
      }

      p {
        margin-bottom: 0;
        color: #596982;
      }
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .widget-block {
    margin-bottom: 40px;
    padding: 35px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .widget-block {
    h4 {
      font-size: 20px;
      margin-bottom: 30px;
    }

    .list-style-icon li {
      margin-bottom: 25px;
      padding-bottom: 13px;
    }
  }
}

/*==============================
 *    Other
 *    ================================ */

/* casestudy page */
.case-studies .case-study-block1 {
  box-shadow: 0px 0px 16px 0px rgba(187, 187, 187, 0.48);
  margin: 20px;
  transition: all .3s ease-out;

  &:hover {
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.18);
    transform: translate(0, -10px);
  }
}

/* casestudy page */
.case-study-block {
  border: 1px solid #f5f5f5;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  height: 100%;

  &:hover {
    -webkit-box-shadow: 0px 7px 30px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 7px 30px 0px rgba(0, 0, 0, 0.1);
  }

  h4 a {
    color: #243f67;
  }

  strong {
    color: #596982;
  }
}

/* Job listing page*/
.job-content {
  display: table;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 2px 8px 0 rgba(12, 0, 46, 0.04);
  box-shadow: 0 2px 8px 0 rgba(12, 0, 46, 0.04);
  padding: 20px 30px 20px 30px;
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear;
  border-radius: 4px;

  h4 a {
    color: #243f67;
  }

  ul li {
    color: #596982;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .job-content {
    padding: 20px;
  }
}


/* app buttons */
.app-btn {
  border: 1px solid rgba(255, 255, 255, 0.9);
  max-width: 170px;
  width: 100%;
  font-size: 0.9rem;

  .media i {
    font-size: 20px;
  }

  .media-body span {
    font-size: 12px;
  }
}

/*search page*/
.search-form_input {
  color: $PrimaryColor;
}


/*404 page*/
.page-container .title {
  font-size: 250px;
  line-height: 1;
  margin-bottom: 0;
}

@media screen and (max-width: $desk-md-screen) {
  .page-container .title {
    font-size: 200px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .page-container .title {
    font-size: 125px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .page-container .title {
    font-size: 75px;
  }
}

/* coming soon */
.coming-soon .social-links li {
  display: inline-block;
  font-size: 22px;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  a {
    color: #fff;

    &:hover {
      color: rgba(255, 255, 255, 0.65);
    }
  }
}

/* count down */
ul.countdown li {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  display: inline-block;
  padding: 0 30px;
  text-align: center;

  &:last-child {
    border: medium none;
    padding-right: 0;
  }

  span {
    font-size: 36px;
    font-weight: 600;
    line-height: normal;
    position: relative;

    &::before {
      content: "";
      height: 1px;
      position: absolute;
      width: 100%;
    }
  }

  p {
    &.timeRefDays, &.timeRefHours, &.timeRefMinutes, &.timeRefSeconds {
      font-size: 12px;
      font-weight: 500;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .coming-soon .social-links li {
    margin-right: 15px;
    font-size: 16px;
  }

  ul.countdown li {
    width: 50%;
    float: left;
    margin-bottom: 35px;
    padding: 0 8px;

    &.second {
      border-right: none;
    }
  }

  .social-links li {
    margin-right: 15px;
  }
}

/* feature image */
.feature-img {
  text-align: center;
  position: relative;
  z-index: 1;

  .circle {
    bottom: 0;
    left: -86px;
    right: auto;
    width: 500px;
    height: 500px;
    background: #d8a608;
    position: absolute;
    border-radius: 100%;
    z-index: -1;
    opacity: 0.06;

    &.two {
      right: 60px;
      width: 370px;
      height: 370px;
      left: 120px;
      bottom: inherit;
      background: #d8a608;
      top: -40px;
      z-index: -2;
      opacity: 0.06;
      position: absolute;
      border-radius: 100%;
    }
  }

  &.right-align .circle {
    background: #d8a608;
    right: -126px;
    opacity: .06;
    left: auto;

    &.two {
      left: 25px;
      right: auto;
      top: 94px;
      z-index: -2;
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .feature-img .circle {
    width: 450px;
    height: 450px;

    &.two {
      width: 300px;
      height: 300px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .feature-img {
    left: 0;
    right: 0;
    margin: 0 auto;

    &.right-align .circle.two {
      left: auto;
      right: 25px;
      top: -50px;
    }
  }
}

@media screen and (max-width: $mobile-sm-screen) {
  .feature-img .circle {
    width: 80%;
    height: 80%;

    &.two {
      width: 150px;
      height: 150px;
    }
  }
}


/* media style */
.media-style1 {
  transition: all 0.5s ease-in-out 0s;
  box-shadow: 0px 5px 24px 6px rgba(0, 0, 0, 0.06);
  border: none;
  padding: 2.5rem;
  height: 100%;
  border-radius: 0.25rem;
  background: #fff;
  position: relative;

  &:after {
    background: $PrimaryColor;
    background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
    border-radius: 0.25rem;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    opacity: 0;
    transition: all 0.5s ease-in-out 0s;
    z-index: 1;
  }

  &:hover:after {
    opacity: 1;
  }

  i, h3, p {
    position: relative;
    z-index: 9;
  }

  i {
    color: $PrimaryColor;
  }

  &:hover {
    h3, p, i {
      color: #fff;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .media-style1 {
    padding: 1.8rem;
  }
}

/* social-icon01 */
.social-icon01 li {
  display: inline-block;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }

  a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    border: 1px solid #286efb;
    text-align: center;
    border-radius: 50%;
    font-size: 15px;
    display: inline-block;
    color: #007bff;

    &:hover {
      background-color: #007bff;
      color: #ffffff;
    }
  }
}

/* social-icon02 */
.social-icon02 {
  margin-bottom: 0;

  li {
    text-align: center;
    margin-right: 4px;
    display: inline-block;

    a {
      background: $PrimaryColor;
      border: 1px solid $PrimaryColor;
      color: #fff;
      border-radius: 35px;
      height: 35px;
      width: 35px;
      line-height: 35px;
      display: inline-block;

      &:hover {
        background: #fff;
        color: $PrimaryColor;
      }
    }
  }
}

/* social-icon03 */
.social-icon03 li {
  display: inline-block;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  a {
    color: rgba(255, 255, 255, 0.5);
    font-size: 18px;

    &:hover {
      color: #fff;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .social-icon03 li a {
    font-size: 18px;
  }
}

/* cover page */
.login-cover {
  .logo {
    top: 0;
    left: 0;
    right: 0;
  }

  .copyright {
    bottom: 0;
    left: 0;
    right: 0;
  }
}

/* extra-block */
.extra-block .right-img {
  position: absolute;
  max-width: 484px;
  width: 100%;
  bottom: -13px;
  right: 0;
  z-index: 1;
}

@media screen and (max-width: $desk-md-screen) {
  .extra-block .right-img {
    max-width: 384px;
    bottom: -14px;
    right: 0;
  }
}

/*==============================
 *    Blog
 *    ================================ */

/* blog card*/
.blog-card {
  border: 1px solid rgba(0, 0, 0, 0.075);
  border-radius: 2px;
  height: 100%;

  .badge {
    background: rgba($PrimaryRgbColor, 0.1);
    color: $PrimaryColor;
    font-size: 11px;
    padding: 5px 15px;
    line-height: 1.6;
    margin-bottom: 12px;

    &:hover {
      background: rgba($PrimaryRgbColor, 0.2);
    }
  }

  h3 a {
    color: #1d184a;

    &:hover {
      color: $PrimaryColor;
    }
  }

  .card-body {
    padding: 1.5rem;
  }

  .card-footer {
    background: none;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 0.8rem 1.5rem;
    font-weight: 600;

    img {
      max-width: 35px;
    }
  }
}

/* blog card01 */
.blog-card01 {
  border: 1px solid rgba(0, 0, 0, 0.09);
  height: 100%;

  .date {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 76px;
    height: 82px;
    background: rgba(255, 255, 255, 0.9);
    text-align: center;
    z-index: 101;
    color: #282b2d;
    padding: 12px 0;
    border-radius: 3px;
  }

  .month {
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: .167em;
  }

  .day {
    display: block;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
  }

  .card-img {
    overflow: hidden;
    -webkit-border-radius: 3px;
    -webkit-border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius: 3px;
    -moz-border-radius-bottomright: 0;
    -moz-border-radius-bottomleft: 0;
    border-radius: 3px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    img {
      -webkit-border-top-left-radius: 3px;
      -webkit-border-top-right-radius: 3px;
      -moz-border-radius-topleft: 3px;
      -moz-border-radius-topright: 3px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      backface-visibility: hidden;
      -webkit-transition: .6s;
      -moz-transition: .6s;
      transition: .6s;
    }

    &:hover img {
      -webkit-transform: scale(1.04);
      -moz-transform: scale(1.04);
      transform: scale(1.04);
    }
  }

  .card-body {
    padding: 2rem;

    h3 {
      margin-bottom: 20px;
      line-height: 24px;

      a {
        font-size: 18px;
        line-height: 24px;
        color: #232323;

        &:hover {
          color: $PrimaryColor;
        }
      }
    }
  }

  .category {
    margin-bottom: 5px;
    display: block;

    a {
      color: $PrimaryColor;
      font-size: 14px;
      letter-spacing: 0.5px;
      font-weight: 600;

      &:hover {
        color: #232323;
      }
    }
  }
}

/* blog style02 */
.blog-card02 {
  .blog-item {
    float: left;
    width: 100%;
  }

  .blog-item-thumbnail img {
    width: 100%;
    border-radius: 3px;
  }

  .blog-meta {
    text-transform: uppercase;
    width: 80px;
    padding: 16px 25px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    border-radius: 0.25rem;
    overflow: hidden;
    color: #fff;
    background-color: $PrimaryColor;
  }

  .date-day {
    font-weight: 600;
    font-size: 22px;
    line-height: 1;
    margin-bottom: 5px;
  }

  .date-month {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
  }

  .blog-title {
    padding-left: 20px;

    a {
      font-size: 18px;
      line-height: 1.25;
      margin-bottom: 18px;
      font-weight: 600;
    }
  }

  .post-cat {
    color: #999;
    display: block;
    padding-left: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .blog-item-thumbnail {
    margin-bottom: 20px;
  }

  .blog-meta span {
    display: inline-block;
    margin-right: 15px;
  }
}

.blog-grid {
  position: relative;
  background: #fff;
  height: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 16px 0px rgba(187, 187, 187, 0.48);

  span {
    color: $PrimaryColor;
  }

  img {
    width: 100%;
  }
}

.blog-grid-text {
  position: relative;

  > span {
    color: $PrimaryColor;
    font-size: 13px;
    padding-right: 5px;
  }

  h4 {
    line-height: normal;
    margin-bottom: 15px;
  }

  .meta-style2 {
    border-top: 1px dashed #cee1f8;
    padding-top: 15px;

    ul li {
      margin-bottom: 0;
      font-weight: 500;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      margin: 5px 10px 5px 0;

      &:last-child {
        margin-right: 0;
      }

      i {
        font-size: 14px;
        font-weight: 600;
        margin-right: 5px;
      }
    }
  }

  p {
    font-weight: 400;
    padding: 0;
  }
}

.blog-list-left-heading:after, .blog-title-box:after {
  content: '';
  height: 2px;
}

.blog-grid-simple-content a:hover {
  color: #1d184a;

  &:after {
    color: #1d184a;
  }
}

.blog-list-simple {
  margin: 0 0 30px 0;
  padding-bottom: 30px;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.blog-list-simple-text {
  > span {
    color: $PrimaryColor;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  p {
    border-top: 1px solid #ececec;
    font-size: 15px;
    font-weight: 400;
    line-height: 170%;
    margin-bottom: 0;
    padding: 15px 0;
  }

  .meta {
    margin: 0 0 5px 0;
  }

  li {
    display: inline-block;
    font-size: 12px;
    margin: 5px;
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    margin: 5px 0;
    line-height: normal;
  }

  ul {
    margin: 10px 0;

    li {
      font-size: 14px;
      font-weight: 500;
      padding: 3px 0;

      i {
        color: $PrimaryColor;
        margin-right: 5px;
        font-size: 14px;
      }
    }
  }
}

.blog-block {
  box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  border-radius: 5px;

  .blog-text {
    border-bottom: 1px solid #ececec;
  }

  .blog-img {
    position: relative;
    overflow: hidden;
  }

  .blog-bottom-holder img {
    max-width: 42px;
  }
}

.blogs {
  .post {
    margin-bottom: 50px;

    .content {
      padding: 30px;
      background: #fafafa;

      .post-title h5 {
        font-size: 26px;
        line-height: normal;
        font-weight: 500;
        margin-bottom: 0;

        a {
          color: #1d184a;

          &:hover {
            color: #F24259;
          }
        }
      }

      .meta {
        margin-bottom: 15px;

        li {
          display: inline-block;
          font-size: 12px;
          color: #777;
          margin: 5px 5px 0 5px;
        }
      }

      .special {
        padding: 15px;
        margin: 30px 0;
        border-left: 2px solid #111;
        background: #e8e8e8;
        font-size: 16px;
      }

      .btn {
        border-color: #5a5d80;
        color: #5a5d80;
        min-width: auto;
        padding: 6px 20px;
        font-size: 12px;

        &:hover {
          color: #fff;
          border-color: #F24259;
        }
      }
    }

    .share-post {
      margin-top: 30px;
      padding-top: 20px;
      border-top: 1px dashed #ddd;
      text-align: left;

      span {
        font-weight: 700;
      }

      ul {
        float: right;

        li {
          display: inline-block;
          margin: 0 5px 0 5px;
        }
      }
    }

    .post-img img {
      width: 100%;
    }
  }

  .posts .title-g h3 {
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 10px;
  }

  .comments-area .comment-box {
    padding-bottom: 30px;
    margin-bottom: 50px;
    border-bottom: 1px solid #eee;

    &:nth-child(odd) {
      margin-left: 80px;
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: 30px;
    }
  }

  .comment-box {
    .author-thumb {
      width: 80px;
      float: left;
    }

    .comment-info {
      margin-left: 100px;

      h6 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .reply {
        margin-top: 10px;
        font-weight: 600;

        i {
          padding-right: 5px;
          font-size: 12px;
        }
      }
    }
  }
}

.pagination {
  border-radius: 0;
  padding: 0;
  margin: 0;

  ul {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin: 0 auto;
    padding: 0;
  }

  li {
    display: inline;
  }

  a {
    float: left;
    padding: 0 18px;
    line-height: 40px;
    text-decoration: none;
    border: 1px solid #dbdbdb;
    border-left-width: 0;
    background: #fff;

    &:hover {
      background-color: #1d184a;
      color: #fff;
    }
  }

  .active a {
    background-color: #f7f7f7;
    color: #999;
    cursor: default;
  }

  .disabled {
    span {
      color: #999;
      background-color: transparent;
      cursor: default;
    }

    a {
      color: #999;
      background-color: transparent;
      cursor: default;

      &:hover {
        color: #999;
        background-color: transparent;
        cursor: default;
      }
    }
  }

  li:first-child a {
    border-left-width: 1px;
  }
}

.blockquote {
  background: #f1f1f1;
  border-left: 4px solid $PrimaryColor;
  font-size: 16px;
  font-weight: 500;
  margin: 25px 0;
  padding: 20px 30px 30px;

  p {
    font-size: 15px;
    font-weight: 500;
    line-height: 190%;
    color: #1d184a;
    letter-spacing: .5px;
  }

  h4 {
    font-size: 20px;
    font-weight: 400;
    color: #1d184a;
    letter-spacing: .5px;
  }
}

.side-bar {
  .widget {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    .widget-title h6 {
      position: relative;
      padding-bottom: 5px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 20px;

      &:after {
        content: '';
        width: 30px;
        height: 1px;
        background: $PrimaryColor;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      position: relative;
      padding: 0 0 0 12px;
      margin: 0 0 9px 0;
      font-size: 14px;

      &:last-child {
        margin: 0;
      }

      &:after {
        content: "";
        width: 5px;
        height: 1px;
        background-color: #5a5d80;
        position: absolute;
        top: 12px;
        left: 0;
      }
    }

    .social-listing {
      margin-bottom: 0;

      li {
        margin-bottom: 0;
        list-style: none;
        display: inline-block;

        &:after {
          background: none;
        }

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }

  .search form {
    input {
      width: calc(100% - 50px);
      height: 50px;
      padding: 0 10px;
      margin: 0;
      border: 1px solid #d1d1d1;
      background: #f7f7f7;
      min-height: auto;
    }

    button {
      width: 50px;
      height: 50px;
      line-height: 50px;
      color: #fff;
      border: 0;
      float: right;
      border-radius: 0;
      padding: 0;

      &:hover:before {
        border-radius: 0;
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .blogs {
    .post .content {
      .post-title h5 {
        font-size: 22px;
      }

      .special {
        font-size: 16px;
      }
    }

    .posts .title-g h3 {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .blog-list-simple {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .blog-list-simple-text {
    > span {
      font-size: 12px;
    }

    p {
      font-size: 14px;
    }

    h4 {
      font-size: 18px;
    }

    ul li {
      font-size: 12px;
    }
  }

  .blogs {
    .post .content {
      .post-title h5 {
        font-size: 20px;
      }

      .special {
        font-size: 14px;
      }
    }

    .posts .title-g h3 {
      font-size: 18px;
    }
  }
}

/*==============================
 *    Contact
 *    ================================ */

/* quform */
.quform-input {
  position: relative;
}

.quform-element > label {
  font-weight: normal;
  padding-bottom: 5px;
  margin-bottom: 0;
  font-size: 15px;
  color: #6a747b;

  .quform-required {
    color: #cc0101;
    font-size: 10px;
  }
}

.quform-inner input {
  width: 100%;
}

.quform-elements .quform-element {
  textarea {
    margin-bottom: 0;
    padding: 8px 15px;
    vertical-align: top;
  }

  select {
    margin-bottom: 0;
    padding: 8px 15px;
  }
}

.quform-errors {
  padding: 0;
  margin: 0;
  line-height: normal;

  > .quform-error {
    padding: 0;
    background: none;
    border: none;
    float: none;
    color: #f5543f;
    font-size: 11px;
    line-height: normal;
    letter-spacing: normal;
  }
}

.quform-outer-no-js .quform-error {
  padding: 0;
  background: none;
  border: none;
  float: none;
  color: #f5543f;
  font-size: 11px;
  line-height: normal;
  letter-spacing: normal;
}

.quform-has-error {
  input, textarea, select, input[type=file], .custom-file-label {
    border-color: #f5543f;
  }
}

.quform-success-message, .quform-outer-no-js .quform-success-message {
  padding: 0.75rem 1.25rem 0.75rem 3rem;
}

.quform-input .quform-errors-wrap {
  position: absolute;
  right: 8px;
  top: 0;
  line-height: normal;
  z-index: 1;
}

.quform-submit-inner {
  float: none;

  .btn {
    box-shadow: none;
  }
}

.quform-loading-wrap {
  float: none;

  .quform-loading {
    display: inline-block;
  }
}

.light-validation .quform-errors > .quform-error {
  color: #fff;
}

/* contact info */
.contact-info {
  list-style: none;

  .item {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }

    .icon {
      font-size: 20px;
      float: left;
      width: 50px;
      text-align: center;
    }

    .cont {
      margin-left: 55px;

      h6 {
        font-size: 15px;
        margin-bottom: 0;
      }

      p {
        margin: 0;
      }
    }
  }
}

/* newsletter rounded*/
.newsletter-rounded {
  .quform-elements {
    position: relative;
  }

  .quform-submit-inner {
    position: absolute;
    right: 18px;
    top: -54px;
    width: auto;
  }

  .quform-loading-wrap {
    margin-top: 15px;
    margin-bottom: 0;
    margin-left: 0;
  }

  input {
    border: 3px solid rgba(255, 255, 255, 0.1);
    height: 60px;
    padding: 0.5rem 4rem 0.5rem 1.5rem;
  }

  .quform-has-error {
    input, textarea, select {
      border-color: #f5543f;
    }
  }

  .quform-input .quform-errors-wrap {
    right: 15px;
  }

  i {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

/* newsletter style one */
.newsletter-rounded.style-one {
  .quform-submit-inner {
    right: 16px;
    top: -57px;
  }

  input {
    padding: 0.5rem 9rem 0.5rem 1.5rem;
  }

  .butn {
    border-radius: 25px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 17px 28px;

    &:hover:before, &:focus:before {
      border-radius: 25px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 17px 28px;
    }
  }

  .input .butn:active:before {
    border-radius: 25px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 17px 28px;
  }
}

/* newsletter style two */
.newsletter-rounded.style-two {
  .quform-submit-inner {
    right: 12px;
    top: -55px;
  }

  input {
    padding: 0.5rem 7.5rem 0.5rem 1.5rem;
    height: 55px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .butn {
    border-radius: 25px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 18px 28px 17px 28px;

    &:hover:before, &:focus:before {
      border-radius: 23px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 17px 28px;
    }
  }

  .input .butn:active:before {
    border-radius: 23px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 17px 28px;
  }
}

/* quick contact */
.quick-contact {
  .form-control, .quform-elements .quform-element textarea {
    padding-left: 0;
    border-width: 0 0 1px 0;
    border-radius: 0;
    box-shadow: none;
  }
}

/* contact detail */
.contact-map {
  height: 400px;
  width: 100%;
  vertical-align: top;
}

@media screen and (max-width: $desk-xs-screen) {
  .contact-info .item {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .contact-info .item {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}

/*==============================
 *    Footer
 *    ================================ */

footer {
  padding: 50px 0 0;
  background: #1f1f1f;
  color: #939393;

  p {
    margin-bottom: 0;
  }

  address {
    margin-bottom: 5px;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
    line-height: 1;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 70px;
      height: 2px;
      background: $PrimaryColor;
      margin: 12px auto 0 auto;
    }
  }
}

.footer-title-style:after {
  margin-left: auto;
  margin-right: auto;
}

h3.footer-title-style {
  margin-bottom: 20px;
}

footer h3.footer-title-style2 {
  margin-bottom: 28px;
}

h3.footer-title-style2:after {
  width: 100%;
  border-bottom: 1px dashed #999;
  margin-top: 15px;
  background: none;
}

footer h4.footer-title-style3 {
  margin-bottom: 30px;
  font-size: 24px;
}

.footer-bar {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 50px;
  text-align: center;
  background: #191919;
  color: #939393;

  span {
    color: $PrimaryColor;
    font-size: 15px;
    font-weight: 400;
  }

  a {
    color: #939393;

    &:hover {
      color: #fff;
    }
  }
}

footer h3.normal:after {
  content: none;
}

/* footer nav link */
.footer-nav-link li {
  display: inline-block;
  margin-right: 25px;

  &:last-child {
    margin-right: 0;
  }
}

/* footer background image */
.footer-bg-img {
  position: absolute;
  bottom: 0;
  background: url(../img/bg/bg-02.png) no-repeat scroll center 0;
  width: 100%;
  height: 266px;
  background-size: cover;
}

/* footer list style*/
.footer-list {
  margin: 0;
  color: #575a7b;

  li {
    list-style-type: none;
    padding: 6px 0;
    font-size: 15px;
    word-break: break-word;

    &:last-child {
      padding-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    a {
      font-size: 15px;
      font-weight: 400;

      &:before {
        content: '\f105';
        font-weight: 700;
        vertical-align: bottom;
        font-family: Font Awesome\ 5 Free;
        padding-right: 8px;
        -webkit-transition-duration: .3s;
        -moz-transition-duration: .3s;
        -o-transition-duration: .3s;
        transition-duration: .3s;
      }
    }
  }
}

/* footer-list01 */
.footer-list01 {
  margin: 0;

  li {
    list-style-type: none;
    padding: 12px 0 12px 0;
    font-size: 17px;
    color: #596982;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    a {
      font-weight: 400;
      color: #596982;

      &:hover {
        color: #243f67;
      }
    }
  }
}

/* footer-list02 */
.footer-list02 {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    list-style-type: none;
    padding: 0.5rem 0 0.5rem 0;
    font-size: 15px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

/* footer list social icon*/
.footer-social-icons ul {
  margin-bottom: 0;

  li {
    display: inline-block;
    border: 1px solid $PrimaryColor;
    border-radius: 50%;
    color: #fff;
    margin-right: 5px;
    margin-bottom: 5px;
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: 0.3s;

    &:last-child {
      margin-right: 0;
    }

    a {
      color: $PrimaryColor;
      display: block;
      font-size: 15px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      width: 35px;
    }

    &:hover {
      background: $PrimaryColor;
      background: -webkit-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
      background: -moz-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
      background: -o-linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);
      background: linear-gradient(-45deg, $PrimaryColor, $SecondaryColor);

      i {
        color: #fff;
      }
    }
  }
}

/*footer social-icon style01*/
.footer-social-icons01 ul {
  margin-bottom: 0;

  li {
    display: inline-block;
    border: 1px solid #243f67;
    border-radius: 50%;
    color: #243f67;
    margin-right: 5px;
    margin-bottom: 5px;
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: 0.3s;

    &:last-child {
      margin-right: 0;
    }

    a {
      color: #243f67;
      display: block;
      font-size: 15px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      width: 35px;
    }

    &:hover {
      background: #243f67;

      i {
        color: #fff;
      }
    }
  }
}

  /*footer social-icon style02*/
.footer-social-icons2 {
  margin-bottom: 0;

  li {
    display: inline-block;
    color: #232323;
    margin-bottom: 0 !important;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: block;
      font-size: 16px;

      i {
        transition-duration: 0.3s;
      }
    }
  }
}

/*footer social-icon style03*/
.footer-social-icons3 {
  margin-bottom: 0;

  li {
    display: inline-block;
    color: #fff;
    margin-bottom: 0 !important;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    a {
      color: #fff;
      display: block;
      font-size: 16px;

      i {
        -webkit-transition-duration: .3s;
        -moz-transition-duration: .3s;
        -o-transition-duration: .3s;
        transition-duration: 0.3s;
      }
    }

    &:hover i {
      color: #fff;
      opacity: 0.65;
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  footer h4.footer-title-style3 {
    font-size: 22px;
    margin-bottom: 25px;
  }

  .footer-list01 li {
    font-size: 16px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  footer h4.footer-title-style3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  footer h3 {
    font-size: 16px;
  }

  .footer-style1 {
    padding: 70px 0 0 0;
  }

  h3.footer-title-style {
    margin-bottom: 15px;
  }

  footer h3.footer-title-style2 {
    margin-bottom: 20px;
  }

  .footer-bar p {
    font-size: 13px;
  }

  .footer-list li a {
    font-size: 14px;
  }

  .footer-list01 li {
    padding: 8px 0;
    font-size: 15px;
  }
}

/* footer style1*/
.footer-style1 {
  ul {
    list-style: none;
  }

  background: #1d184a;
  padding: 120px 0 0 0;
  color: rgba(255, 255, 255, 0.65);
  font-weight: 300;

  a {
    color: rgba(255, 255, 255, 0.65);

    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }

  h3 {
    font-size: 16px;
    letter-spacing: 2px;
    margin-bottom: 2.5rem;

    &:after {
      content: none;
    }
  }

  ul {
    margin-bottom: 0;

    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: rgba(255, 255, 255, 0.65);

        &:hover {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

  .footer-bottom {
    margin: 120px 0 0 0;
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 14px;
    font-weight: 300;
  }

  .footer-listing li {
    color: rgba(255, 255, 255, 0.65);
    word-break: break-word;

    span {
      display: inline-block;
      vertical-align: top;
    }

    .icon {
      margin-right: 15px;
    }
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .footer-style1 {
    h3 {
      margin-bottom: 20px;
    }

    padding: 90px 0 0 0;

    .footer-bottom {
      margin: 90px 0 0 0;
    }
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .footer-style1 {
    padding: 70px 0 0 0;

    .footer-bottom {
      margin: 60px 0 0 0;
    }
  }
}

/* footer-style2 */
.footer-style2 {
  box-shadow: 0px 0 75px 0 rgba(0, 0, 0, 0.05);

  > .container {
    padding: 120px 15px;
  }
}

@media screen and (max-width: $desk-md-screen) {
  .footer-style2 > .container {
    padding: 90px 15px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .footer-style2 > .container {
    padding: 70px 15px;
  }
}

/* footer-style3 */
.footer-style3 {
  color: #939393;
  padding-top: 240px;
  margin-top: -120px;
  z-index: 0;

  .footer-icon ul li {
    display: inline-block;
    margin-right: 20px;
  }

  ul {
    list-style: none;

    li {
      margin: 10px 0;
      font-size: 16px;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  h3:after {
    content: "";
    background: unset;
    margin: 0;
  }
}

.footer-style3-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  padding: 20px 0;
  margin-top: 100px;
}

.footer-style3 {
  h3 {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  a {
    color: #939393;

    &:hover {
      color: #fff;
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .footer-style3 {
    padding-top: 220px;
    margin-top: -120px;
  }

  .footer-style3-bottom {
    margin-top: 70px;
  }
}

@media screen and (max-width: $desk-xs-screen) {
  .footer-style3 {
    padding-top: 140px;
    margin-top: -90px;
  }

  .footer-style3-bottom {
    margin-top: 50px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .footer-style3 {
    h4 {
      font-size: 16px;
    }

    ul li {
      font-size: 15px;
    }
  }
}


/* footer-style4 */
.footer-style4 {
  padding-top: 0;

  > .container {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  h3 {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 25px;
    line-height: 1;
    position: relative;

    &:after {
      content: none;
    }
  }

  .footer-top-bar {
    border-bottom: 1px solid white;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .footer-bar {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 0;
    background: transparent;
    border-top: 1px solid rgba(255, 255, 255, 0.075);
    color: rgba(255, 255, 255, 0.85);

    a {
      color: #fff;

      &:hover {
        color: rgba(255, 255, 255, 0.65);
      }
    }

    p {
      font-size: 14px;
      margin-top: 5px;
      margin-bottom: 0;
    }
  }

  ul {
    margin-bottom: 0;
  }

  li {
    margin-bottom: 10px;
    list-style: none;
    color: rgba(255, 255, 255, 0.8);

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: rgba(255, 255, 255, 0.8);

      &:hover {
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: $desk-md-screen) {
  .footer-style4 > .container {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media screen and (max-width: $tablet-xs-screen) {
  .footer-style4 {
    > .container {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    h3 {
      margin-bottom: 20px;
    }
  }
}
