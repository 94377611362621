/*-----------------------------------------------------------------------------------

    Theme Name: Amava - Startup Agency and SasS Business Template
    Description: Startup Agency and SasS Business Template
    Author: Chitrakoot Web
    Version: 3.0

-----------------------------------------------------------------------------------*/

/* ----------------------------------

Table of contents
        
    01. Google font
    02. Reset
    03. Typography
    04. Background color
    05. Custom
    06. Border
    07. Background image
    08. Margin
    09. Padding
    10. Display
    11. Position
    12. Width
    13. Responsive Media Query
    
---------------------------------- */

/* ===================================
    Google font
====================================== */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

/* ===================================
    Reset
====================================== */

* { margin: 0; padding: 0; box-sizing: border-box; }
body{ font-family: 'Poppins', sans-serif; -webkit-font-smoothing: antialiased;  font-smoothing: antialiased; font-size:16px; letter-spacing: 0.5px; color:#575a7b; font-weight: 400; line-height: 1.7; }
body, html { -moz-osx-font-smoothing: grayscale; }
a, a:active, a:focus { color: #575a7b; text-decoration: none; transition-timing-function: ease-in-out; -ms-transition-timing-function: ease-in-out; -moz-transition-timing-function: ease-in-out; -webkit-transition-timing-function: ease-in-out; -o-transition-timing-function: ease-in-out; transition-duration: .2s; -ms-transition-duration: .2s; -moz-transition-duration: .2s; -webkit-transition-duration: .2s; -o-transition-duration: .2s;  }
a:hover, a:active{color: #1f194c; text-decoration: none;}
a:focus, a:active, button:focus, button:active,.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {outline: none;}
img {max-width:100%; height:auto; vertical-align: top; }
.form-control { height: 50px; border-radius: 3px; font-size: 15px; }
.form-control:focus { box-shadow: 0 0 10px rgba(55,125,255,.1); border-color: #292dc2; }
.form-control::-webkit-input-placeholder { color: #bdbfd2; opacity: 1; }
.form-control::-moz-placeholder { color: #bdbfd2; opacity: 1; }
.form-control:-ms-input-placeholder { color: #bdbfd2; opacity: 1; }
.form-control::-ms-input-placeholder { color: #bdbfd2; opacity: 1; }
.form-control::placeholder { color: #bdbfd2; opacity: 1; }
input[type="button"], input[type="text"], input[type="email"], input[type="search"], input[type="password"], textarea, input[type="submit"] { -webkit-appearance: none; outline: none;}
input:focus, textarea:focus{ border-color: #585858; outline: none; }
input[type="button"]:focus{ outline: none; }
select::-ms-expand{ display:none;}
iframe { border: 0; vertical-align: top; }
p {margin: 0 0 20px;}
.height-50 { height: 50%; }
.white-space { white-space: normal; }
.word-wrap { word-wrap: break-word; }
.valign { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.transition-hover { transition: all .3s cubic-bezier(.645,.045,.355,1); }
.transition-hover:hover, .transition-hover:focus { transform: translateY(-8px); }
::selection { color: #000; background:#dbdbdb; }
::-moz-selection { color:#000; background:#dbdbdb; }

/* ===================================
    Typography
====================================== */

/* font family */
.alt-font { font-family: 'Poppins', sans-serif; }
.main-font { font-family: 'Poppins', sans-serif; }

/* heading */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {  font-weight: 600; font-family: 'Poppins', sans-serif; color: #1f194c;}

/* font size */
.display-5 {font-size:3.4rem;}
.display-6 {font-size:3.3rem;}
.display-7 {font-size:3.2rem;}
.display-8 {font-size:3.1rem;}
.display-9 {font-size:3.0rem;}
.display-10 {font-size:2.9rem;}
.display-11 {font-size:2.8rem;}
.display-12 {font-size:2.7rem;}
.display-13 {font-size:2.6rem;}
.display-14 {font-size:2.5rem;}
.display-15 {font-size:2.4rem;}
.display-16 {font-size:2.3rem;}
.display-17 {font-size:2.2rem;}
.display-18 {font-size:2.1rem;}
.display-19 {font-size:2.0rem;}
.display-20 {font-size:1.9rem;}
.display-21 {font-size:1.8rem;}
.display-22 {font-size:1.7rem;}
.display-23 {font-size:1.6rem;}
.display-24 {font-size:1.5rem;}
.display-25 {font-size:1.4rem;}
.display-26 {font-size:1.3rem;}
.display-27 {font-size:1.2rem;}
.display-28 {font-size:1.1rem;}
.display-29 {font-size:1.0rem;}
.display-30 {font-size:0.9rem;}
.display-31 {font-size:0.8rem;}
.display-32 {font-size:0.7rem;}
.display-33 {font-size:0.6rem;}
.display-34 {font-size:0.5rem;}

/* text color */
.text-black {color:#000;}
.text-extra-dark-gray {color:#1f194c;}
.text-dark-gray {color:#626262;}
.text-extra-medium-gray {color:#757575;}
.text-medium-gray {color:#939393;}
.text-extra-light-gray {color:#b7b7b7;}
.text-light-gray {color:#d6d5d5;}
.text-very-light-gray {color:#ededed;}
.text-default-color { color: #6f6f6f; }

/* letter spacing */
.no-letter-spacing {letter-spacing:0px;}
.letter-spacing-1 {letter-spacing:1px;}
.letter-spacing-2 {letter-spacing:2px;}
.letter-spacing-3 {letter-spacing:3px;}
.letter-spacing-4 {letter-spacing:4px;}
.letter-spacing-5 {letter-spacing:5px;}

/* font weight */
.font-weight-100 {font-weight:100;}
.font-weight-200 {font-weight:200;}
.font-weight-300 {font-weight:300;}
.font-weight-400 {font-weight:400;}
.font-weight-500 {font-weight:500;}
.font-weight-600 {font-weight:600;}
.font-weight-700 {font-weight:700;}
.font-weight-800 {font-weight:800;}
.font-weight-900 {font-weight:900;}

 /* ===================================
    Background color
====================================== */

.bg-transparent {background-color: transparent;}
.bg-black {background-color:#000;}
.bg-extra-dark-gray {background-color:#1c1c1c;}
.bg-dark-gray {background-color:#757575;}
.bg-extra-medium-gray {background-color:#939393;}
.bg-medium-gray {background-color:#dbdbdb;}
.bg-extra-light-gray {background-color:#e0e0e0;}
.bg-medium-light-gray {background-color:#ededed;}
.bg-light-gray {background-color:#f1f7ff;}
.bg-very-light-gray {background-color:#fafafa;}
.bg-grey {background-color:#f1f7ff;}
.bg-red {background-color:#ed1b24;}
.bg-dark-theme { background: #1f194c; }
.bg-transparent-white { background-color: rgba(255,255,255,0.3); background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 37%, rgba(255,255,255,1) 96%, rgba(255,255,255,1) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(37%, rgba(255,255,255,0)), color-stop(96%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1))); background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 37%, rgba(255,255,255,1) 96%, rgba(255,255,255,1) 100%); background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 37%, rgba(255,255,255,1) 96%, rgba(255,255,255,1) 100%); background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 37%, rgba(255,255,255,1) 96%, rgba(255,255,255,1) 100%); background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 37%, rgba(255,255,255,1) 96%, rgba(255,255,255,1) 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 ); }
.bg-transparent-black { background-color: rgba(0,0,0,0); background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,1) 96%, rgba(0,0,0,1) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,0)), color-stop(37%, rgba(0,0,0,0)), color-stop(96%, rgba(0,0,0,1)), color-stop(100%, rgba(0,0,0,1))); background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,1) 96%, rgba(0,0,0,1) 100%); background: -o-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,1) 96%, rgba(0,0,0,1) 100%); background: -ms-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,1) 96%, rgba(0,0,0,1) 100%); background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,1) 96%, rgba(0,0,0,1) 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 ); }
.bg-white-opacity { background-color: rgba(255, 255, 255, 0.85); }
.bg-black-opacity { background-color: rgba(0, 0, 0, 0.85); }
.bg-black-opacity-light { background-color: rgba(0, 0, 0, 0.5); }

/* ===================================
    Custom
====================================== */

section { padding: 120px 0; overflow: hidden; background: #fff;}
section.md { padding: 90px 0; }

.big-input, .big-textarea, .big-select select { padding: 18px 25px; font-size: 14px; border-radius: 0;}
.medium-input, .medium-textarea, .medium-select select { padding: 12px 20px; font-size: 14px; line-height: normal; border-radius: 0;}
.small-input, .small-textarea { padding: 12px 15px; font-size: 11px; line-height: normal;}
.small-select select {padding: 15px 15px; line-height: normal;}
.medium-input-light, .medium-textarea-light, .medium-select-light select { padding: 12px 25px; font-size: 12px; line-height: normal;}
 
/* data overlay */
[data-overlay-dark],[data-overlay-light] { position: relative;}
[data-overlay-dark] .position-unset,[data-overlay-light] .position-unset { position: unset !important;}
[data-overlay-dark] .container,[data-overlay-light] .container {  position: relative;  z-index: 2;}
[data-overlay-dark]:before,[data-overlay-light]:before {content: ''; position: absolute; width: 100%; height: 100%; top: 0; left: 0;  z-index: 1;}
[data-overlay-dark]:before { background: #000; }
[data-overlay-light]:before { background: #fff;}
[data-overlay-dark="0"]:before,
[data-overlay-light="0"]:before {  opacity: 0; }
[data-overlay-dark="1"]:before,
[data-overlay-light="1"]:before {opacity: 0.1;}
[data-overlay-dark="2"]:before,
[data-overlay-light="2"]:before { opacity: 0.2; }
[data-overlay-dark="3"]:before,
[data-overlay-light="3"]:before { opacity: 0.3; }
[data-overlay-dark="4"]:before,
[data-overlay-light="4"]:before { opacity: 0.4; }
[data-overlay-dark="5"]:before,
[data-overlay-light="5"]:before { opacity: 0.5; }
[data-overlay-dark="55"]:before,
[data-overlay-light="55"]:before { opacity: 0.55; }
[data-overlay-dark="6"]:before,
[data-overlay-light="6"]:before { opacity: 0.6; }
[data-overlay-dark="65"]:before,
[data-overlay-light="65"]:before { opacity: 0.65; }
[data-overlay-dark="7"]:before,
[data-overlay-light="7"]:before { opacity: 0.7; }
[data-overlay-dark="75"]:before,
[data-overlay-light="75"]:before { opacity: 0.75; }
[data-overlay-dark="8"]:before,
[data-overlay-light="8"]:before { opacity: 0.8; }
[data-overlay-dark="85"]:before,
[data-overlay-light="85"]:before { opacity: 0.85; }
[data-overlay-dark="9"]:before,
[data-overlay-light="9"]:before { opacity: 0.9; }
[data-overlay-dark="91"]:before,
[data-overlay-light="91"]:before { opacity: 0.91; }
[data-overlay-dark="92"]:before,
[data-overlay-light="92"]:before { opacity: 0.92; }
[data-overlay-dark="93"]:before,
[data-overlay-light="93"]:before { opacity: 0.93; }
[data-overlay-dark="94"]:before,
[data-overlay-light="94"]:before { opacity: 0.94; }
[data-overlay-dark="95"]:before,
[data-overlay-light="95"]:before { opacity: 0.95; }
[data-overlay-dark="96"]:before,
[data-overlay-light="96"]:before { opacity: 0.96; }
[data-overlay-dark="97"]:before,
[data-overlay-light="97"]:before { opacity: 0.97; }
[data-overlay-dark="98"]:before,
[data-overlay-light="98"]:before { opacity: 0.98; }
[data-overlay-dark="99"]:before,
[data-overlay-light="99"]:before { opacity: 0.99; }
[data-overlay-dark="10"]:before,
[data-overlay-light="10"]:before {  opacity: 1; }

/* separator */
.separator-line-verticle-extra-small {width:1px; height: 8px;}
.separator-line-verticle-small-thick {width:17px; height: 3px;}
.separator-line-verticle-small {width:1px; height: 13px;}
.separator-line-verticle-small-thick2 {width:64px; height: 7px;}
.separator-line-verticle-large {width:1px; height:20px;}
.separator-line-verticle-extra-large {width:1px; height:30px;}
.separator-line-verticle-medium-thick-full {width:8px; height: 40px;}
.separator-line-verticle-large-thick {width:109px; height: 11px;}
.separator-line-horrizontal-medium-light {width:36px; height: 3px;}
.separator-line-horrizontal-medium-light2 {width:50px; height: 1px;}
.separator-line-horrizontal-medium-light3 {width:18%; height: 1px;}
.separator-line-horrizontal-medium-thick {width:50px; height: 5px;}
.separator-line-horrizontal-full {width:100%; height: 1px;}

/* divider */
.divider-full {width:100%; height: 1px; display: inline-block}

/* opacity */
.opacity-very-light {position: absolute; height: 100%; width: 100%; opacity: 0.2; top:0; left: 0;}
.opacity-light {position: absolute; height: 100%; width: 100%; opacity: 0.3; top:0; left: 0;}
.opacity-extra-medium {position: absolute; height: 100%; width: 100%; opacity: 0.5; top:0; left: 0;}
.opacity-medium {position: absolute; height: 100%; width: 100%; opacity: 0.75; top:0; left: 0;}
.opacity-full {position: absolute; height: 100%; width: 100%; opacity: 0.8; top:0; left: 0;}
.opacity-full-dark {position: absolute; height: 100%; width: 100%; opacity: 0.9; top:0; left: 0;}

.opacity1 {opacity:.1;}
.opacity2 {opacity:.2;}
.opacity3 {opacity:.3;}
.opacity4 {opacity:.4;}
.opacity5 {opacity:.5;}
.opacity6 {opacity:.6;}
.opacity7 {opacity:.7;}
.opacity8 {opacity:.8;}
.opacity9 {opacity:.9;}

/* box-shadow */
.box-shadow-light { box-shadow: 0 0 3px rgba(0,0,0,.1);}
.box-shadow { box-shadow: 0 0 3px rgba(0,0,0, .2);}
.box-shadow-dark { box-shadow: 0 0 4px rgba(0,0,0,.4);}
.box-shadow-large { box-shadow: 0 0 12px rgba(0,0,0,.1);}
.box-shadow-primary { -webkit-box-shadow: 0 18px 38px rgba(0,0,0,.2); box-shadow: 0 18px 38px rgba(0,0,0,.2);}
.box-shadow-none{ -webkit-box-shadow: none !important; -moz-box-shadow: none !important; box-shadow: none !important; }

/* text-shadow */
.text-shadow-light { text-shadow: 0 0 3px rgba(0,0,0,.1);}
.text-shadow { text-shadow: 0 0 3px rgba(0,0,0, .2);}
.text-shadow-dark { text-shadow: 0 0 4px rgba(0,0,0,.4);}
.text-shadow-large { text-shadow: 0 0 12px rgba(0,0,0,.1);}
.text-shadow-primary { text-shadow: 0 18px 38px rgba(0,0,0,.2);}
.text-shadow-none{ text-shadow: none !important; }

/* z-index */
.z-index-1111 {z-index:1111;}
.z-index-111 {z-index:111;}
.z-index-1 {z-index:1;}
.z-index-2 {z-index:2;}
.z-index-3 {z-index:3;}
.z-index-4 {z-index:4;}
.z-index-5 {z-index:5;}
.z-index-9 {z-index:9;}
.z-index-99 {z-index:99;}
.z-index-0 {z-index:0;}
.z-index-minus2 {z-index:-2;}

/* verticle align */
.vertical-align-middle {vertical-align: middle;}
.vertical-align-top {vertical-align: top;}
.vertical-align-bottom {vertical-align: bottom;}
.clear-both{ clear:both;}
.line-break { display:block;}
.no-transition *  { transition-timing-function: none; -moz-transition-timing-function: none; -webkit-transition-timing-function: none; -o-transition-timing-function: none; -ms-transition-timing-function: none; transition-duration: 0s; -moz-transition-duration: 0s; -webkit-transition-duration: 0s; -o-transition-duration: 0s; -ms-transition-duration: 0s; }

/* ===================================
    Border
====================================== */

/* boder width */
.border-width-1 {border-width:1px !important;}
.border-width-2 {border-width:2px !important;}
.border-width-3 {border-width:3px !important;}
.border-width-4 {border-width:4px !important;}
.border-width-5 {border-width:5px !important;}
.border-width-6 {border-width:6px !important;}
.border-width-7 {border-width:7px !important;}
.border-width-8 {border-width:8px !important;}
.border-width-9 {border-width:9px !important;}
.border-width-10 {border-width:10px !important;}
.border-width-20 {border-width:20px !important;}

/* border color */
.border-color-white {border-color:#fff !important;}
.border-color-black {border-color:#000 !important;}
.border-color-extra-dark-gray {border-color:#1f194c !important;}
.border-color-medium-dark-gray {border-color:#363636 !important;}
.border-color-dark-gray {border-color:#939393 !important;}
.border-color-extra-medium-gray {border-color:#dbdbdb !important;}
.border-color-medium-gray {border-color:#e4e4e4 !important;}
.border-color-extra-light-gray {border-color:#ededed !important;}
.border-color-light-gray {border-color:#f5f5f5 !important;}
.border-color-light-white { border-color:rgba(255, 255, 255, 0.1) !important; } 
.border-color-light-black { border-color:rgba(0, 0, 0, 0.1) !important; } 

/* boder style */
.border-dotted {border-style: dotted !important;}
.border-dashed {border-style: dashed !important;}
.border-solid {border-style: solid !important;}
.border-double {border-style: double !important;}
.border-groove {border-style: groove !important;}
.border-ridge {border-style: ridge !important;}
.border-inset {border-style: inset !important;}
.border-outset {border-style: outset !important;}
.border-none {border-style: none !important;}
.border-hidden {border-style: hidden !important;}
.border-transperent {border-color: transparent !important;}

/* border radius */
.border-radius-1 {border-radius:1px;}
.border-radius-2 {border-radius:2px;}
.border-radius-3 {border-radius:3px;}
.border-radius-4 {border-radius:4px;}
.border-radius-5 {border-radius:5px;}
.border-radius-6 {border-radius:6px;}
.border-radius-7 {border-radius:7px;}
.border-radius-8 {border-radius:8px;}
.border-radius-9 {border-radius:9px;}
.border-radius-10 {border-radius:10px;}
.border-radius-50 {border-radius:50%;}
.border-radius-100 {border-radius:100%;}
.border-radius-none {border-radius:0 !important;}

/* ===================================
    Background image
====================================== */

.parallax {position: relative; background-size: cover; overflow: hidden; background-attachment: fixed;  }
.fix-background {position: relative;  background-size: cover; animation-duration: 0s; animation-fill-mode: none; -webkit-animation-duration: 0s;  -webkit-animation-fill-mode: none; -moz-animation-duration: 0s;  -moz-animation-fill-mode: none; -ms-animation-duration: 0s; -ms-animation-fill-mode: none; -o-animation-fill-mode: none; overflow: hidden; background-position: center center; background-repeat: no-repeat; background-attachment: fixed; }
.cover-background {position: relative !important; background-size: cover !important; overflow: hidden !important; background-position: center !important; background-repeat: no-repeat !important;}
.background-size-inherit {background-size: inherit;}
.background-attachment-inherit {background-attachment: inherit;}
.background-position-left {background-position: left center;}
.background-position-left-bottom {background-position: left bottom;}
.background-position-right {background-position: right center;}
.background-position-top {background-position: center top !important;}
.up-down-ani {display:table; position: relative; -webkit-animation-name: up-down-animation; -webkit-animation-duration:.7s; -webkit-animation-timing-function: linear; -webkit-animation-delay:.7s; -webkit-animation-iteration-count: infinite; -webkit-animation-direction: alternate; -moz-animation-name: up-down-animation; -moz-animation-duration:.7s; -moz-animation-timing-function: linear; -moz-animation-delay:.7s; -moz-animation-iteration-count: infinite; -ms-animation-name: up-down-animation; -ms-animation-duration:.7s; -ms-animation-timing-function: linear; -ms-animation-delay:.7s; -ms-animation-iteration-count: infinite; -ms-animation-direction: alternate; -o-animation-direction: alternate; -o-animation-name: up-down-animation; -o-animation-duration:.7s; -o-animation-timing-function: linear; -o-animation-delay:.7s; -o-animation-iteration-count: infinite; -o-animation-direction: alternate; animation-direction: alternate; animation-name: up-down-animation; animation-duration:.7s; animation-timing-function: linear; animation-delay:.7s; animation-iteration-count: infinite; animation-direction: alternate; margin:0 auto; }
.background-position-x-50 {background-position-x: 50% !important;}
.background-size-contain {background-size: contain;}

/*==============================
    Margin
================================*/

/* custom margin */
.m-1-6 {
    margin: 1.6rem;
}
.m-1-7 {
    margin: 1.7rem;
}
.m-1-8 {
    margin: 1.8rem;
}
.m-1-9 {
    margin: 1.9rem;
}
.m-2-0 {
    margin: 2.0rem;
}
.m-2-1 {
    margin: 2.1rem;
}
.m-2-2 {
    margin: 2.2rem;
}
.m-2-3 {
    margin: 2.3rem;
}
.m-2-4 {
    margin: 2.4rem;
}
.m-2-5 {
    margin: 2.5rem;
}
.m-2-6 {
    margin: 2.6rem;
}
.m-2-7 {
    margin: 2.7rem;
}
.m-2-8 {
    margin: 2.8rem;
}
.m-2-9 {
    margin: 2.9rem;
}

/* margin all */
.m-6 {
    margin: 3.5rem;
}
.m-7 {
    margin: 4rem;
}
.m-8 {
    margin: 4.5rem;
}
.m-9 {
    margin: 5rem;
}
.m-10 {
    margin: 5.5rem;
}
.m-11 {
    margin: 6rem;
}
.m-12 {
    margin: 6.5rem;
}
.m-13 {
    margin: 7rem;
}
.m-14 {
    margin: 7.5rem;
}
.m-15 {
    margin: 8rem;
}
.m-16 {
    margin: 8.5rem;
}
.m-17 {
    margin: 9rem;
}
.m-18 {
    margin: 9.5rem;
}
.m-19 {
    margin: 10rem;
}
.m-20 {
    margin: 10.5rem;
}
.m-21 {
    margin: 11rem;
}
.m-22 {
    margin: 11.5rem;
}
.m-23 {
    margin: 12rem;
}
.m-24 {
    margin: 12.5rem;
}

/* margin top */
.mt-1-6, .my-1-6 {
    margin-top: 1.6rem;
}
.mt-1-7, .my-1-7 {
    margin-top: 1.7rem;
}
.mt-1-8, .my-1-8 {
    margin-top: 1.8rem;
}
.mt-1-9, .my-1-9 {
    margin-top: 1.9rem;
}
.mt-2-0, .my-2-0 {
    margin-top: 2.0rem;
}
.mt-2-1, .my-2-1 {
    margin-top: 2.1rem;
}
.mt-2-2, .my-2-2 {
    margin-top: 2.2rem;
}
.mt-2-3, .my-2-3 {
    margin-top: 2.3rem;
}
.mt-2-4, .my-2-4 {
    margin-top: 2.4rem;
}
.mt-2-5, .my-2-5 {
    margin-top: 2.5rem;
}
.mt-2-6, .my-2-6 {
    margin-top: 2.6rem;
}
.mt-2-7, .my-2-7 {
    margin-top: 2.7rem;
}
.mt-2-8, .my-2-8 {
    margin-top: 2.8rem;
}
.mt-2-9, .my-2-9 {
    margin-top: 2.9rem;
}

.mt-6, .my-6 {
    margin-top: 3.5rem;
}
.mt-7, .my-7 {
    margin-top: 4rem;
}
.mt-8, .my-8 {
    margin-top: 4.5rem;
}
.mt-9, .my-9 {
    margin-top: 5rem;
}
.mt-10, .my-10 {
    margin-top: 5.5rem;
}
.mt-11, .my-11 {
    margin-top: 6rem;
}
.mt-12, .my-12 {
    margin-top: 6.5rem;
}
.mt-13, .my-13 {
    margin-top: 7rem;
}
.mt-14, .my-14 {
    margin-top: 7.5rem;
}
.mt-15, .my-15 {
    margin-top: 8rem;
}
.mt-16, .my-16 {
    margin-top: 8.5rem;
}
.mt-17, .my-17 {
    margin-top: 9rem;
}
.mt-18, .my-18 {
    margin-top: 9.5rem;
}
.mt-19, .my-19 {
    margin-top: 10rem;
}
.mt-20, .my-20 {
    margin-top: 10.5rem;
}
.mt-21, .my-21 {
    margin-top: 11rem;
}
.mt-22, .my-22 {
    margin-top: 11.5rem;
}
.mt-23, .my-23 {
    margin-top: 12rem;
}
.mt-24, .my-24 {
    margin-top: 12.5rem;
}

/* margin bottom */
.mb-1-6, .my-1-6 {
    margin-bottom: 1.6rem;
}
.mb-1-7, .my-1-7 {
    margin-bottom: 1.7rem;
}
.mb-1-8, .my-1-8 {
    margin-bottom: 1.8rem;
}
.mb-1-9, .my-1-9 {
    margin-bottom: 1.9rem;
}
.mb-2-0, .my-2-0 {
    margin-bottom: 2.0rem;
}
.mb-2-1, .my-2-1 {
    margin-bottom: 2.1rem;
}
.mb-2-2, .my-2-2 {
    margin-bottom: 2.2rem;
}
.mb-2-3, .my-2-3 {
    margin-bottom: 2.3rem;
}
.mb-2-4, .my-2-4 {
    margin-bottom: 2.4rem;
}
.mb-2-5, .my-2-5 {
    margin-bottom: 2.5rem;
}
.mb-2-6, .my-2-6 {
    margin-bottom: 2.6rem;
}
.mb-2-7, .my-2-7 {
    margin-bottom: 2.7rem;
}
.mb-2-8, .my-2-8 {
    margin-bottom: 2.8rem;
}
.mb-2-9, .my-2-9 {
    margin-bottom: 2.9rem;
}

.mb-6, .my-6 {
    margin-bottom: 3.5rem;
}
.mb-7, .my-7 {
    margin-bottom: 4rem;
}
.mb-8, .my-8 {
    margin-bottom: 4.5rem;
}
.mb-9, .my-9 {
    margin-bottom: 5rem;
}
.mb-10, .my-10 {
    margin-bottom: 5.5rem;
}
.mb-11, .my-11 {
    margin-bottom: 6rem;
}
.mb-12, .my-12 {
    margin-bottom: 6.5rem;
}
.mb-13, .my-13 {
    margin-bottom: 7rem;
}
.mb-14, .my-14 {
    margin-bottom: 7.5rem;
}
.mb-15, .my-15 {
    margin-bottom: 8rem;
}
.mb-16, .my-16 {
    margin-bottom: 8.5rem;
}
.mb-17, .my-17 {
    margin-bottom: 9rem;
}
.mb-18, .my-18 {
    margin-bottom: 9.5rem;
}
.mb-19, .my-19 {
    margin-bottom: 10rem;
}
.mb-20, .my-20 {
    margin-bottom: 10.5rem;
}
.mb-21, .my-21 {
    margin-bottom: 11rem;
}
.mb-22, .my-22 {
    margin-bottom: 11.5rem;
}
.mb-23, .my-23 {
    margin-bottom: 12rem;
}
.mb-24, .my-24 {
    margin-bottom: 12.5rem;
}

/* margin left */
.ml-1-6, .mx-1-6 {
    margin-left: 1.6rem;
}
.ml-1-7, .mx-1-7 {
    margin-left: 1.7rem;
}
.ml-1-8, .mx-1-8 {
    margin-left: 1.8rem;
}
.ml-1-9, .mx-1-9 {
    margin-left: 1.9rem;
}
.ml-2-0, .mx-2-0 {
    margin-left: 2.0rem;
}
.ml-2-1, .mx-2-1 {
    margin-left: 2.1rem;
}
.ml-2-2, .mx-2-2 {
    margin-left: 2.2rem;
}
.ml-2-3, .mx-2-3 {
    margin-left: 2.3rem;
}
.ml-2-4, .mx-2-4 {
    margin-left: 2.4rem;
}
.ml-2-5, .mx-2-5 {
    margin-left: 2.5rem;
}
.ml-2-6, .mx-2-6 {
    margin-left: 2.6rem;
}
.ml-2-7, .mx-2-7 {
    margin-left: 2.7rem;
}
.ml-2-8, .mx-2-8 {
    margin-left: 2.8rem;
}
.ml-2-9, .mx-2-9 {
    margin-left: 2.9rem;
}

.ml-6, .mx-6 {
    margin-left: 3.5rem;
}
.ml-7, .mx-7 {
    margin-left: 4rem;
}
.ml-8, .mx-8 {
    margin-left: 4.5rem;
}
.ml-9, .mx-9 {
    margin-left: 5rem;
}
.ml-10, .mx-10 {
    margin-left: 5.5rem;
}
.ml-11, .mx-11 {
    margin-left: 6rem;
}
.ml-12, .mx-12 {
    margin-left: 6.5rem;
}
.ml-13, .mx-13 {
    margin-left: 7rem;
}
.ml-14, .mx-14 {
    margin-left: 7.5rem;
}
.ml-15, .mx-15 {
    margin-left: 8rem;
}
.ml-16, .mx-16 {
    margin-left: 8.5rem;
}
.ml-17, .mx-17 {
    margin-left: 9rem;
}
.ml-18, .mx-18 {
    margin-left: 9.5rem;
}
.ml-19, .mx-19 {
    margin-left: 10rem;
}
.ml-20, .mx-20 {
    margin-left: 10.5rem;
}
.ml-21, .mx-21 {
    margin-left: 11rem;
}
.ml-22, .mx-22 {
    margin-left: 11.5rem;
}
.ml-23, .mx-23 {
    margin-left: 12rem;
}
.ml-24, .mx-24 {
    margin-left: 12.5rem;
}

/* margin right */
.mr-1-6, .mx-1-6 {
    margin-right: 1.6rem;
}
.mr-1-7, .mx-1-7 {
    margin-right: 1.7rem;
}
.mr-1-8, .mx-1-8 {
    margin-right: 1.8rem;
}
.mr-1-9, .mx-1-9 {
    margin-right: 1.9rem;
}
.mr-2-0, .mx-2-0 {
    margin-right: 2.0rem;
}
.mr-2-1, .mx-2-1 {
    margin-right: 2.1rem;
}
.mr-2-2, .mx-2-2 {
    margin-right: 2.2rem;
}
.mr-2-3, .mx-2-3 {
    margin-right: 2.3rem;
}
.mr-2-4, .mx-2-4 {
    margin-right: 2.4rem;
}
.mr-2-5, .mx-2-5 {
    margin-right: 2.5rem;
}
.mr-2-6, .mx-2-6 {
    margin-right: 2.6rem;
}
.mr-2-7, .mx-2-7 {
    margin-right: 2.7rem;
}
.mr-2-8, .mx-2-8 {
    margin-right: 2.8rem;
}
.mr-2-9, .mx-2-9 {
    margin-right: 2.9rem;
}

.mr-6, .mx-6 {
    margin-right: 3.5rem;
}
.mr-7, .mx-7 {
    margin-right: 4rem;
}
.mr-8, .mx-8 {
    margin-right: 4.5rem;
}
.mr-9, .mx-9 {
    margin-right: 5rem;
}
.mr-10, .mx-10 {
    margin-right: 5.5rem;
}
.mr-11, .mx-11 {
    margin-right: 6rem;
}
.mr-12, .mx-12 {
    margin-right: 6.5rem;
}
.mr-13, .mx-13 {
    margin-right: 7rem;
}
.mr-14, .mx-14 {
    margin-right: 7.5rem;
}
.mr-15, .mx-15 {
    margin-right: 8rem;
}
.mr-16, .mx-16 {
    margin-right: 8.5rem;
}
.mr-17, .mx-17 {
    margin-right: 9rem;
}
.mr-18, .mx-18 {
    margin-right: 9.5rem;
}
.mr-19, .mx-19 {
    margin-right: 10rem;
}
.mr-20, .mx-20 {
    margin-right: 10.5rem;
}
.mr-21, .mx-21 {
    margin-right: 11rem;
}
.mr-22, .mx-22 {
    margin-right: 11.5rem;
}
.mr-23, .mx-23 {
    margin-right: 12rem;
}
.mr-24, .mx-24 {
    margin-right: 12.5rem;
}


/*==============================
    Padding
================================*/

/* padding all */

/* custom padding */
.p-1-6 {
    padding: 1.6rem;
}
.p-1-7 {
    padding: 1.7rem;
}
.p-1-8 {
    padding: 1.8rem;
}
.p-1-9 {
    padding: 1.9rem;
}
.p-2-0 {
    padding: 2.0rem;
}
.p-2-1 {
    padding: 2.1rem;
}
.p-2-2 {
    padding: 2.2rem;
}
.p-2-3 {
    padding: 2.3rem;
}
.p-2-4 {
    padding: 2.4rem;
}
.p-2-5 {
    padding: 2.5rem;
}
.p-2-6 {
    padding: 2.6rem;
}
.p-2-7 {
    padding: 2.7rem;
}
.p-2-8 {
    padding: 2.8rem;
}
.p-2-9 {
    padding: 2.9rem;
}

.p-6 {
    padding: 3.5rem;
}
.p-7 {
    padding: 4rem;
}
.p-8 {
    padding: 4.5rem;
}
.p-9 {
    padding: 5rem;
}
.p-10 {
    padding: 5.5rem;
}
.p-11 {
    padding: 6rem;
}
.p-12 {
    padding: 6.5rem;
}
.p-13 {
    padding: 7rem;
}
.p-14 {
    padding: 7.5rem;
}
.p-15 {
    padding: 8rem;
}
.p-16 {
    padding: 8.5rem;
}
.p-17 {
    padding: 9rem;
}
.p-18 {
    padding: 9.5rem;
}
.p-19 {
    padding: 10rem;
}
.p-20 {
    padding: 10.5rem;
}
.p-21 {
    padding: 11rem;
}
.p-22 {
    padding: 11.5rem;
}
.p-23 {
    padding: 12rem;
}
.p-24 {
    padding: 12.5rem;
}

/* padding top */
.pt-1-6, .py-1-6 {
    padding-top: 1.6rem;
}
.pt-1-7, .py-1-7 {
    padding-top: 1.7rem;
}
.pt-1-8, .py-1-8 {
    padding-top: 1.8rem;
}
.pt-1-9, .py-1-9 {
    padding-top: 1.9rem;
}
.pt-2-0, .py-2-0 {
    padding-top: 2.0rem;
}
.pt-2-1, .py-2-1 {
    padding-top: 2.1rem;
}
.pt-2-2, .py-2-2 {
    padding-top: 2.2rem;
}
.pt-2-3, .py-2-3 {
    padding-top: 2.3rem;
}
.pt-2-4, .py-2-4 {
    padding-top: 2.4rem;
}
.pt-2-5, .py-2-5 {
    padding-top: 2.5rem;
}
.pt-2-6, .py-2-5 {
    padding-top: 2.6rem;
}
.pt-2-7, .py-2-7 {
    padding-top: 2.7rem;
}
.pt-2-8, .py-2-8 {
    padding-top: 2.8rem;
}
.pt-2-9, .py-2-9 {
    padding-top: 2.9rem;
}

.pt-6, .py-6 {
    padding-top: 3.5rem;
}
.pt-7, .py-7 {
    padding-top: 4rem;
}
.pt-8, .py-8 {
    padding-top: 4.5rem;
}
.pt-9, .py-9 {
    padding-top: 5rem;
}
.pt-10, .py-10 {
    padding-top: 5.5rem;
}
.pt-11, .py-11 {
    padding-top: 6rem;
}
.pt-12, .py-12 {
    padding-top: 6.5rem;
}
.pt-13, .py-13 {
    padding-top: 7rem;
}
.pt-14, .py-14 {
    padding-top: 7.5rem;
}
.pt-15, .py-15 {
    padding-top: 8rem;
}
.pt-16, .py-16 {
    padding-top: 8.5rem;
}
.pt-17, .py-17 {
    padding-top: 9rem;
}
.pt-18, .py-18 {
    padding-top: 9.5rem;
}
.pt-19, .py-19 {
    padding-top: 10rem;
}
.pt-20, .py-20 {
    padding-top: 10.5rem;
}
.pt-21, .py-21 {
    padding-top: 11rem;
}
.pt-22, .py-22 {
    padding-top: 11.5rem;
}
.pt-23, .py-23 {
    padding-top: 12rem;
}
.pt-24, .py-24 {
    padding-top: 12.5rem;
}

/* padding bottom */
.pb-1-6, .py-1-6 {
    padding-bottom: 1.6rem;
}
.pb-1-7, .py-1-7 {
    padding-bottom: 1.7rem;
}
.pb-1-8, .py-1-8 {
    padding-bottom: 1.8rem;
}
.pb-1-9, .py-1-9 {
    padding-bottom: 1.9rem;
}
.pb-2-0, .py-2-0 {
    padding-bottom: 2.0rem;
}
.pb-2-1, .py-2-1 {
    padding-bottom: 2.1rem;
}
.pb-2-2, .py-2-2 {
    padding-bottom: 2.2rem;
}
.pb-2-3, .py-2-3 {
    padding-bottom: 2.3rem;
}
.pb-2-4, .py-2-4 {
    padding-bottom: 2.4rem;
}
.pb-2-5, .py-2-5 {
    padding-bottom: 2.5rem;
}
.pb-2-6, .py-2-5 {
    padding-bottom: 2.6rem;
}
.pb-2-7, .py-2-7 {
    padding-bottom: 2.7rem;
}
.pb-2-8, .py-2-8 {
    padding-bottom: 2.8rem;
}
.pb-2-9, .py-2-9 {
    padding-bottom: 2.9rem;
}

.pb-6, .py-6 {
    padding-bottom: 3.5rem;
}
.pb-7, .py-7 {
    padding-bottom: 4rem;
}
.pb-8, .py-8 {
    padding-bottom: 4.5rem;
}
.pb-9, .py-9 {
    padding-bottom: 5rem;
}
.pb-10, .py-10 {
    padding-bottom: 5.5rem;
}
.pb-11, .py-11 {
    padding-bottom: 6rem;
}
.pb-12, .py-12 {
    padding-bottom: 6.5rem;
}
.pb-13, .py-13 {
    padding-bottom: 7rem;
}
.pb-14, .py-14 {
    padding-bottom: 7.5rem;
}
.pb-15, .py-15 {
    padding-bottom: 8rem;
}
.pb-16, .py-16 {
    padding-bottom: 8.5rem;
}
.pb-17, .py-17 {
    padding-bottom: 9rem;
}
.pb-18, .py-18 {
    padding-bottom: 9.5rem;
}
.pb-19, .py-19 {
    padding-bottom: 10rem;
}
.pb-20, .py-20 {
    padding-bottom: 10.5rem;
}
.pb-21, .py-21 {
    padding-bottom: 11rem;
}
.pb-22, .py-22 {
    padding-bottom: 11.5rem;
}
.pb-23, .py-23 {
    padding-bottom: 12rem;
}
.pb-24, .py-24 {
    padding-bottom: 12.5rem;
}

/* padding left */
.pl-1-6, .px-1-6 {
    padding-left: 1.6rem;
}
.pl-1-7, .px-1-7 {
    padding-left: 1.7rem;
}
.pl-1-8, .px-1-8 {
    padding-left: 1.8rem;
}
.pl-1-9, .px-1-9 {
    padding-left: 1.9rem;
}
.pl-2-0, .px-2-0 {
    padding-left: 2.0rem;
}
.pl-2-1, .px-2-1 {
    padding-left: 2.1rem;
}
.pl-2-2, .px-2-2 {
    padding-left: 2.2rem;
}
.pl-2-3, .px-2-3 {
    padding-left: 2.3rem;
}
.pl-2-4, .px-2-4 {
    padding-left: 2.4rem;
}
.pl-2-5, .px-2-5 {
    padding-left: 2.5rem;
}
.pl-2-6, .px-2-5 {
    padding-left: 2.6rem;
}
.pl-2-7, .px-2-7 {
    padding-left: 2.7rem;
}
.pl-2-8, .px-2-8 {
    padding-left: 2.8rem;
}
.pl-2-9, .px-2-9 {
    padding-left: 2.9rem;
}

.pl-6, .px-6 {
    padding-left: 3.5rem;
}
.pl-7, .px-7 {
    padding-left: 4rem;
}
.pl-8, .px-8 {
    padding-left: 4.5rem;
}
.pl-9, .px-9 {
    padding-left: 5rem;
}
.pl-10, .px-10 {
    padding-left: 5.5rem;
}
.pl-11, .px-11 {
    padding-left: 6rem;
}
.pl-12, .px-12 {
    padding-left: 6.5rem;
}
.pl-13, .px-13 {
    padding-left: 7rem;
}
.pl-14, .px-14 {
    padding-left: 7.5rem;
}
.pl-15, .px-15 {
    padding-left: 8rem;
}
.pl-16, .px-16 {
    padding-left: 8.5rem;
}
.pl-17, .px-17 {
    padding-left: 9rem;
}
.pl-18, .px-18 {
    padding-left: 9.5rem;
}
.pl-19, .px-19 {
    padding-left: 10rem;
}
.pl-20, .px-20 {
    padding-left: 10.5rem;
}
.pl-21, .px-21 {
    padding-left: 11rem;
}
.pl-22, .px-22 {
    padding-left: 11.5rem;
}
.pl-23, .px-23 {
    padding-left: 12rem;
}
.pl-24, .px-24 {
    padding-left: 12.5rem;
}

/* padding right */
.pr-1-6, .px-1-6 {
    padding-right: 1.6rem;
}
.pr-1-7, .px-1-7 {
    padding-right: 1.7rem;
}
.pr-1-8, .px-1-8 {
    padding-right: 1.8rem;
}
.pr-1-9, .px-1-9 {
    padding-right: 1.9rem;
}
.pr-2-0, .px-2-0 {
    padding-right: 2.0rem;
}
.pr-2-1, .px-2-1 {
    padding-right: 2.1rem;
}
.pr-2-2, .px-2-2 {
    padding-right: 2.2rem;
}
.pr-2-3, .px-2-3 {
    padding-right: 2.3rem;
}
.pr-2-4, .px-2-4 {
    padding-right: 2.4rem;
}
.pr-2-5, .px-2-5 {
    padding-right: 2.5rem;
}
.pr-2-6, .px-2-5 {
    padding-right: 2.6rem;
}
.pr-2-7, .px-2-7 {
    padding-right: 2.7rem;
}
.pr-2-8, .px-2-8 {
    padding-right: 2.8rem;
}
.pr-2-9, .px-2-9 {
    padding-right: 2.9rem;
}

.pr-6, .px-6 {
    padding-right: 3.5rem;
}
.pr-7, .px-7 {
    padding-right: 4rem;
}
.pr-8, .px-8 {
    padding-right: 4.5rem;
}
.pr-9, .px-9 {
    padding-right: 5rem;
}
.pr-10, .px-10 {
    padding-right: 5.5rem;
}
.pr-11, .px-11 {
    padding-right: 6rem;
}
.pr-12, .px-12 {
    padding-right: 6.5rem;
}
.pr-13, .px-13 {
    padding-right: 7rem;
}
.pr-14, .px-14 {
    padding-right: 7.5rem;
}
.pr-15, .px-15 {
    padding-right: 8rem;
}
.pr-16, .px-16 {
    padding-right: 8.5rem;
}
.pr-17, .px-17 {
    padding-right: 9rem;
}
.pr-18, .px-18 {
    padding-right: 9.5rem;
}
.pr-19, .px-19 {
    padding-right: 10rem;
}
.pr-20, .px-20 {
    padding-right: 10.5rem;
}
.pr-21, .px-21 {
    padding-right: 11rem;
}
.pr-22, .px-22 {
    padding-right: 11.5rem;
}
.pr-23, .px-23 {
    padding-right: 12rem;
}
.pr-24, .px-24 {
    padding-right: 12.5rem;
}

/*==============================
    Display
================================*/

.overflow-hidden {overflow:hidden !important;}
.overflow-visible {overflow:visible !important;}
.overflow-auto {overflow:auto !important;}

/*============================== 
    Position
================================*/

.position-inherit {position:inherit !important;}
.right {right:0;}
.right-1 {right:-1px;}
.left {left:0;}
.left-1 {left:-1px;}
.top {top:0;}
.top-1 {top:-1px;}
.bottom {bottom:0;}
.bottom-1 {bottom:-1px;}
 
/*================================
    Width
================================*/

.w-1px {width:1px;}
.w-2px {width:2px;}
.w-3px {width:3px;}
.w-4px {width:4px;}
.w-5px {width:5px;}
.w-6px {width:6px;}
.w-7px {width:7px;}
.w-8px {width:8px;}
.w-9px {width:9px;}
.w-10px {width:10px;}
.w-15px {width:15px;}
.w-20px {width:20px;}
.w-25px {width:25px;}
.w-30px {width:30px;}
.w-35px {width:35px;}
.w-40px {width:40px;}
.w-50px {width:50px;}
.w-55px {width:55px;}
.w-60px {width:60px;}
.w-65px {width:65px;}
.w-70px {width:70px;}
.w-75px {width:75px;}
.w-80px {width:80px;}
.w-85px {width:85px;}
.w-90px {width:90px;}
.w-100px {width:100px;}

.w-10 {width:10%;}
.w-12 {width:12%;}
.w-15 {width:15%;}
.w-20 {width:20%;}
.w-25 {width:25%;}
.w-30 {width:30%;}
.w-35 {width:35%;}
.w-40 {width:40%;}
.w-45 {width:45%;}
.w-50 {width:50%;}
.w-55 {width:55%;}
.w-60 {width:60%;}
.w-65 {width:65%;}
.w-70 {width:70%;}
.w-75 {width:75%;}
.w-80 {width:80%;}
.w-85 {width:85%;}
.w-90 {width:90%;}
.w-95 {width:95%;}
.w-100 {width:100%;}
.w-auto {width: auto;}

/*================================
    Responsive Media Query
================================*/

@media screen and (min-width: 576px) {

    /* width */
    .w-sm-1px {width:1px;}
    .w-sm-2px {width:2px;}
    .w-sm-3px {width:3px;}
    .w-sm-4px {width:4px;}
    .w-sm-5px {width:5px;}
    .w-sm-6px {width:6px;}
    .w-sm-7px {width:7px;}
    .w-sm-8px {width:8px;}
    .w-sm-9px {width:9px;}
    .w-sm-10px {width:10px;}
    .w-sm-15px {width:15px;}
    .w-sm-20px {width:20px;}
    .w-sm-25px {width:25px;}
    .w-sm-30px {width:30px;}
    .w-sm-35px {width:35px;}
    .w-sm-40px {width:40px;}
    .w-sm-50px {width:50px;}
    .w-sm-55px {width:55px;}
    .w-sm-60px {width:60px;}
    .w-sm-65px {width:65px;}
    .w-sm-70px {width:70px;}
    .w-sm-75px {width:75px;}
    .w-sm-80px {width:80px;}
    .w-sm-85px {width:85px;}
    .w-sm-90px {width:90px;}
    .w-sm-100px {width:100px;}

    .w-sm-10 {width:10%;}
    .w-sm-12 {width:12%;}
    .w-sm-15 {width:15%;}
    .w-sm-20 {width:20%;}
    .w-sm-25 {width:25%;}
    .w-sm-30 {width:30%;}
    .w-sm-35 {width:35%;}
    .w-sm-40 {width:40%;}
    .w-sm-45 {width:45%;}
    .w-sm-50 {width:50%;}
    .w-sm-55 {width:55%;}
    .w-sm-60 {width:60%;}
    .w-sm-65 {width:65%;}
    .w-sm-70 {width:70%;}
    .w-sm-75 {width:75%;}
    .w-sm-80 {width:80%;}
    .w-sm-85 {width:85%;}
    .w-sm-90 {width:90%;}
    .w-sm-95 {width:95%;}
    .w-sm-100 {width:100%;}
    .w-sm-auto {width: auto;}

    /* font size */
    .display-sm-1 {font-size:6rem;}
    .display-sm-2 {font-size:5.5rem;}
    .display-sm-3 {font-size:4.5rem;}
    .display-sm-4 {font-size:3.5rem;}
    .display-sm-5 {font-size:3.4rem;}
    .display-sm-6 {font-size:3.3rem;}
    .display-sm-7 {font-size:3.2rem;}
    .display-sm-8 {font-size:3.1rem;}
    .display-sm-9 {font-size:3.0rem;}
    .display-sm-10 {font-size:2.9rem;}
    .display-sm-11 {font-size:2.8rem;}
    .display-sm-12 {font-size:2.7rem;}
    .display-sm-13 {font-size:2.6rem;}
    .display-sm-14 {font-size:2.5rem;}
    .display-sm-15 {font-size:2.4rem;}
    .display-sm-16 {font-size:2.3rem;}
    .display-sm-17 {font-size:2.2rem;}
    .display-sm-18 {font-size:2.1rem;}
    .display-sm-19 {font-size:2.0rem;}
    .display-sm-20 {font-size:1.9rem;}
    .display-sm-21 {font-size:1.8rem;}
    .display-sm-22 {font-size:1.7rem;}
    .display-sm-23 {font-size:1.6rem;}
    .display-sm-24 {font-size:1.5rem;}
    .display-sm-25 {font-size:1.4rem;}
    .display-sm-26 {font-size:1.3rem;}
    .display-sm-27 {font-size:1.2rem;}
    .display-sm-28 {font-size:1.1rem;}
    .display-sm-29 {font-size:1.0rem;}
    .display-sm-30 {font-size:0.9rem;}
    .display-sm-31 {font-size:0.8rem;}
    .display-sm-32 {font-size:0.7rem;}
    .display-sm-33 {font-size:0.6rem;}
    .display-sm-34 {font-size:0.5rem;}

    /* margin */

    /* margin all */
    .m-sm-1-6 {
        margin: 1.6rem;
    }
    .m-sm-1-7 {
        margin: 1.7rem;
    }
    .m-sm-1-8 {
        margin: 1.8rem;
    }
    .m-sm-1-9 {
        margin: 1.9rem;
    }
    .m-sm-2-0 {
        margin: 2.0rem;
    }
    .m-sm-2-1 {
        margin: 2.1rem;
    }
    .m-sm-2-2 {
        margin: 2.2rem;
    }
    .m-sm-2-3 {
        margin: 2.3rem;
    }
    .m-sm-2-4 {
        margin: 2.4rem;
    }
    .m-sm-2-5 {
        margin: 2.5rem;
    }
    .m-sm-2-6 {
        margin: 2.6rem;
    }
    .m-sm-2-7 {
        margin: 2.7rem;
    }
    .m-sm-2-8 {
        margin: 2.8rem;
    }
    .m-sm-2-9 {
        margin: 2.9rem;
    }

    .m-sm-6 {
        margin: 3.5rem;
    }
    .m-sm-7 {
        margin: 4rem;
    }
    .m-sm-8 {
        margin: 4.5rem;
    }
    .m-sm-9 {
        margin: 5rem;
    }
    .m-sm-10 {
        margin: 5.5rem;
    }
    .m-sm-11 {
        margin: 6rem;
    }
    .m-sm-12 {
        margin: 6.5rem;
    }
    .m-sm-13 {
        margin: 7rem;
    }
    .m-sm-14 {
        margin: 7.5rem;
    }
    .m-sm-15 {
        margin: 8rem;
    }
    .m-sm-16 {
        margin: 8.5rem;
    }
    .m-sm-17 {
        margin: 9rem;
    }
    .m-sm-18 {
        margin: 9.5rem;
    }
    .m-sm-19 {
        margin: 10rem;
    }
    .m-sm-20 {
        margin: 10.5rem;
    }
    .m-sm-21 {
        margin: 11rem;
    }
    .m-sm-22 {
        margin: 11.5rem;
    }
    .m-sm-23 {
        margin: 12rem;
    }
    .m-sm-24 {
        margin: 12.5rem;
    }

    /* margin top */
    .mt-sm-1-6, .my-sm-1-6 {
        margin-top: 1.6rem;
    }
    .mt-sm-1-7, .my-sm-1-7 {
        margin-top: 1.7rem;
    }
    .mt-sm-1-8, .my-sm-1-8 {
        margin-top: 1.8rem;
    }
    .mt-sm-1-9, .my-sm-1-9 {
        margin-top: 1.9rem;
    }
    .mt-sm-2-0, .my-sm-2-0 {
        margin-top: 2.0rem;
    }
    .mt-sm-2-1, .my-sm-2-1 {
        margin-top: 2.1rem;
    }
    .mt-sm-2-2, .my-sm-2-2 {
        margin-top: 2.2rem;
    }
    .mt-sm-2-3, .my-sm-2-3 {
        margin-top: 2.3rem;
    }
    .mt-sm-2-4, .my-sm-2-4 {
        margin-top: 2.4rem;
    }
    .mt-sm-2-5, .my-sm-2-5 {
        margin-top: 2.5rem;
    }
    .mt-sm-2-6, .my-sm-2-6 {
        margin-top: 2.6rem;
    }
    .mt-sm-2-7, .my-sm-2-7 {
        margin-top: 2.7rem;
    }
    .mt-sm-2-8, .my-sm-2-8 {
        margin-top: 2.8rem;
    }
    .mt-sm-2-9, .my-sm-2-9 {
        margin-top: 2.9rem;
    }

    .mt-sm-6, .my-sm-6 {
        margin-top: 3.5rem;
    }
    .mt-sm-7, .my-sm-7 {
        margin-top: 4rem;
    }
    .mt-sm-8, .my-sm-8 {
        margin-top: 4.5rem;
    }
    .mt-sm-9, .my-sm-9 {
        margin-top: 5rem;
    }
    .mt-sm-10, .my-sm-10 {
        margin-top: 5.5rem;
    }
    .mt-sm-11, .my-sm-11 {
        margin-top: 6rem;
    }
    .mt-sm-12, .my-sm-12 {
        margin-top: 6.5rem;
    }
    .mt-sm-13, .my-sm-13 {
        margin-top: 7rem;
    }
    .mt-sm-14, .my-sm-14 {
        margin-top: 7.5rem;
    }
    .mt-sm-15, .my-sm-15 {
        margin-top: 8rem;
    }
    .mt-sm-16, .my-sm-16 {
        margin-top: 8.5rem;
    }
    .mt-sm-17, .my-sm-17 {
        margin-top: 9rem;
    }
    .mt-sm-18, .my-sm-18 {
        margin-top: 9.5rem;
    }
    .mt-sm-19, .my-sm-19 {
        margin-top: 10rem;
    }
    .mt-sm-20, .my-sm-20 {
        margin-top: 10.5rem;
    }
    .mt-sm-21, .my-sm-21 {
        margin-top: 11rem;
    }
    .mt-sm-22, .my-sm-22 {
        margin-top: 11.5rem;
    }
    .mt-sm-23, .my-sm-23 {
        margin-top: 12rem;
    }
    .mt-sm-24, .my-sm-24 {
        margin-top: 12.5rem;
    }

    /* margin bottom */
    .mb-sm-1-6, .my-sm-1-6 {
        margin-bottom: 1.6rem;
    }
    .mb-sm-1-7, .my-sm-1-7 {
        margin-bottom: 1.7rem;
    }
    .mb-sm-1-8, .my-sm-1-8 {
        margin-bottom: 1.8rem;
    }
    .mb-sm-1-9, .my-sm-1-9 {
        margin-bottom: 1.9rem;
    }
    .mb-sm-2-0, .my-sm-2-0 {
        margin-bottom: 2.0rem;
    }
    .mb-sm-2-1, .my-sm-2-1 {
        margin-bottom: 2.1rem;
    }
    .mb-sm-2-2, .my-sm-2-2 {
        margin-bottom: 2.2rem;
    }
    .mb-sm-2-3, .my-sm-2-3 {
        margin-bottom: 2.3rem;
    }
    .mb-sm-2-4, .my-sm-2-4 {
        margin-bottom: 2.4rem;
    }
    .mb-sm-2-5, .my-sm-2-5 {
        margin-bottom: 2.5rem;
    }
    .mb-sm-2-6, .my-sm-2-6 {
        margin-bottom: 2.6rem;
    }
    .mb-sm-2-7, .my-sm-2-7 {
        margin-bottom: 2.7rem;
    }
    .mb-sm-2-8, .my-sm-2-8 {
        margin-bottom: 2.8rem;
    }
    .mb-sm-2-9, .my-sm-2-9 {
        margin-bottom: 2.9rem;
    }

    .mb-sm-6, .my-sm-6 {
        margin-bottom: 3.5rem;
    }
    .mb-sm-7, .my-sm-7 {
        margin-bottom: 4rem;
    }
    .mb-sm-8, .my-sm-8 {
        margin-bottom: 4.5rem;
    }
    .mb-sm-9, .my-sm-9 {
        margin-bottom: 5rem;
    }
    .mb-sm-10, .my-sm-10 {
        margin-bottom: 5.5rem;
    }
    .mb-sm-11, .my-sm-11 {
        margin-bottom: 6rem;
    }
    .mb-sm-12, .my-sm-12 {
        margin-bottom: 6.5rem;
    }
    .mb-sm-13, .my-sm-13 {
        margin-bottom: 7rem;
    }
    .mb-sm-14, .my-sm-14 {
        margin-bottom: 7.5rem;
    }
    .mb-sm-15, .my-sm-15 {
        margin-bottom: 8rem;
    }
    .mb-sm-16, .my-sm-16 {
        margin-bottom: 8.5rem;
    }
    .mb-sm-17, .my-sm-17 {
        margin-bottom: 9rem;
    }
    .mb-sm-18, .my-sm-18 {
        margin-bottom: 9.5rem;
    }
    .mb-sm-19, .my-sm-19 {
        margin-bottom: 10rem;
    }
    .mb-sm-20, .my-sm-20 {
        margin-bottom: 10.5rem;
    }
    .mb-sm-21, .my-sm-21 {
        margin-bottom: 11rem;
    }
    .mb-sm-22, .my-sm-22 {
        margin-bottom: 11.5rem;
    }
    .mb-sm-23, .my-sm-23 {
        margin-bottom: 12rem;
    }
    .mb-sm-24, .my-sm-24 {
        margin-bottom: 12.5rem;
    }

    /* margin left */
    .ml-sm-1-6, .mx-sm-1-6 {
        margin-left: 1.6rem;
    }
    .ml-sm-1-7, .mx-sm-1-7 {
        margin-left: 1.7rem;
    }
    .ml-sm-1-8, .mx-sm-1-8 {
        margin-left: 1.8rem;
    }
    .ml-sm-1-9, .mx-sm-1-9 {
        margin-left: 1.9rem;
    }
    .ml-sm-2-0, .mx-sm-2-0 {
        margin-left: 2.0rem;
    }
    .ml-sm-2-1, .mx-sm-2-1 {
        margin-left: 2.1rem;
    }
    .ml-sm-2-2, .mx-sm-2-2 {
        margin-left: 2.2rem;
    }
    .ml-sm-2-3, .mx-sm-2-3 {
        margin-left: 2.3rem;
    }
    .ml-sm-2-4, .mx-sm-2-4 {
        margin-left: 2.4rem;
    }
    .ml-sm-2-5, .mx-sm-2-5 {
        margin-left: 2.5rem;
    }
    .ml-sm-2-6, .mx-sm-2-6 {
        margin-left: 2.6rem;
    }
    .ml-sm-2-7, .mx-sm-2-7 {
        margin-left: 2.7rem;
    }
    .ml-sm-2-8, .mx-sm-2-8 {
        margin-left: 2.8rem;
    }
    .ml-sm-2-9, .mx-sm-2-9 {
        margin-left: 2.9rem;
    }

    .ml-sm-6, .mx-sm-6 {
        margin-left: 3.5rem;
    }
    .ml-sm-7, .mx-sm-7 {
        margin-left: 4rem;
    }
    .ml-sm-8, .mx-sm-8 {
        margin-left: 4.5rem;
    }
    .ml-sm-9, .mx-sm-9 {
        margin-left: 5rem;
    }
    .ml-sm-10, .mx-sm-10 {
        margin-left: 5.5rem;
    }
    .ml-sm-11, .mx-sm-11 {
        margin-left: 6rem;
    }
    .ml-sm-12, .mx-sm-12 {
        margin-left: 6.5rem;
    }
    .ml-sm-13, .mx-sm-13 {
        margin-left: 7rem;
    }
    .ml-sm-14, .mx-sm-14 {
        margin-left: 7.5rem;
    }
    .ml-sm-15, .mx-sm-15 {
        margin-left: 8rem;
    }
    .ml-sm-16, .mx-sm-16 {
        margin-left: 8.5rem;
    }
    .ml-sm-17, .mx-sm-17 {
        margin-left: 9rem;
    }
    .ml-sm-18, .mx-sm-18 {
        margin-left: 9.5rem;
    }
    .ml-sm-19, .mx-sm-19 {
        margin-left: 10rem;
    }
    .ml-sm-20, .mx-sm-20 {
        margin-left: 10.5rem;
    }
    .ml-sm-21, .mx-sm-21 {
        margin-left: 11rem;
    }
    .ml-sm-22, .mx-sm-22 {
        margin-left: 11.5rem;
    }
    .ml-sm-23, .mx-sm-23 {
        margin-left: 12rem;
    }
    .ml-sm-24, .mx-sm-24 {
        margin-left: 12.5rem;
    }

    /* margin right */
    .mr-sm-1-6, .mx-sm-1-6 {
        margin-right: 1.6rem;
    }
    .mr-sm-1-7, .mx-sm-1-7 {
        margin-right: 1.7rem;
    }
    .mr-sm-1-8, .mx-sm-1-8 {
        margin-right: 1.8rem;
    }
    .mr-sm-1-9, .mx-sm-1-9 {
        margin-right: 1.9rem;
    }
    .mr-sm-2-0, .mx-sm-2-0 {
        margin-right: 2.0rem;
    }
    .mr-sm-2-1, .mx-sm-2-1 {
        margin-right: 2.1rem;
    }
    .mr-sm-2-2, .mx-sm-2-2 {
        margin-right: 2.2rem;
    }
    .mr-sm-2-3, .mx-sm-2-3 {
        margin-right: 2.3rem;
    }
    .mr-sm-2-4, .mx-sm-2-4 {
        margin-right: 2.4rem;
    }
    .mr-sm-2-5, .mx-sm-2-5 {
        margin-right: 2.5rem;
    }
    .mr-sm-2-6, .mx-sm-2-6 {
        margin-right: 2.6rem;
    }
    .mr-sm-2-7, .mx-sm-2-7 {
        margin-right: 2.7rem;
    }
    .mr-sm-2-8, .mx-sm-2-8 {
        margin-right: 2.8rem;
    }
    .mr-sm-2-9, .mx-sm-2-9 {
        margin-right: 2.9rem;
    }

    .mr-sm-6, .mx-sm-6 {
        margin-right: 3.5rem;
    }
    .mr-sm-7, .mx-sm-7 {
        margin-right: 4rem;
    }
    .mr-sm-8, .mx-sm-8 {
        margin-right: 4.5rem;
    }
    .mr-sm-9, .mx-sm-9 {
        margin-right: 5rem;
    }
    .mr-sm-10, .mx-sm-10 {
        margin-right: 5.5rem;
    }
    .mr-sm-11, .mx-sm-11 {
        margin-right: 6rem;
    }
    .mr-sm-12, .mx-sm-12 {
        margin-right: 6.5rem;
    }
    .mr-sm-13, .mx-sm-13 {
        margin-right: 7rem;
    }
    .mr-sm-14, .mx-sm-14 {
        margin-right: 7.5rem;
    }
    .mr-sm-15, .mx-sm-15 {
        margin-right: 8rem;
    }
    .mr-sm-16, .mx-sm-16 {
        margin-right: 8.5rem;
    }
    .mr-sm-17, .mx-sm-17 {
        margin-right: 9rem;
    }
    .mr-sm-18, .mx-sm-18 {
        margin-right: 9.5rem;
    }
    .mr-sm-19, .mx-sm-19 {
        margin-right: 10rem;
    }
    .mr-sm-20, .mx-sm-20 {
        margin-right: 10.5rem;
    }
    .mr-sm-21, .mx-sm-21 {
        margin-right: 11rem;
    }
    .mr-sm-22, .mx-sm-22 {
        margin-right: 11.5rem;
    }
    .mr-sm-23, .mx-sm-23 {
        margin-right: 12rem;
    }
    .mr-sm-24, .mx-sm-24 {
        margin-right: 12.5rem;
    }

    /* padding */

    /* padding all */
    .p-sm-1-6 {
        padding: 1.6rem;
    }
    .p-sm-1-7 {
        padding: 1.7rem;
    }
    .p-sm-1-8 {
        padding: 1.8rem;
    }
    .p-sm-1-9 {
        padding: 1.9rem;
    }
    .p-sm-2-0 {
        padding: 2.0rem;
    }
    .p-sm-2-1 {
        padding: 2.1rem;
    }
    .p-sm-2-2 {
        padding: 2.2rem;
    }
    .p-sm-2-3 {
        padding: 2.3rem;
    }
    .p-sm-2-4 {
        padding: 2.4rem;
    }
    .p-sm-2-5 {
        padding: 2.5rem;
    }
    .p-sm-2-6 {
        padding: 2.6rem;
    }
    .p-sm-2-7 {
        padding: 2.7rem;
    }
    .p-sm-2-8 {
        padding: 2.8rem;
    }
    .p-sm-2-9 {
        padding: 2.9rem;
    }

    .p-sm-6 {
        padding: 3.5rem;
    }
    .p-sm-7 {
        padding: 4rem;
    }
    .p-sm-8 {
        padding: 4.5rem;
    }
    .p-sm-9 {
        padding: 5rem;
    }
    .p-sm-10 {
        padding: 5.5rem;
    }
    .p-sm-11 {
        padding: 6rem;
    }
    .p-sm-12 {
        padding: 6.5rem;
    }
    .p-sm-13 {
        padding: 7rem;
    }
    .p-sm-14 {
        padding: 7.5rem;
    }
    .p-sm-15 {
        padding: 8rem;
    }
    .p-sm-16 {
        padding: 8.5rem;
    }
    .p-sm-17 {
        padding: 9rem;
    }
    .p-sm-18 {
        padding: 9.5rem;
    }
    .p-sm-19 {
        padding: 10rem;
    }
    .p-sm-20 {
        padding: 10.5rem;
    }
    .p-sm-21 {
        padding: 11rem;
    }
    .p-sm-22 {
        padding: 11.5rem;
    }
    .p-sm-23 {
        padding: 12rem;
    }
    .p-sm-24 {
        padding: 12.5rem;
    }

    /* padding top */
    .pt-sm-1-6, .py-sm-1-6 {
        padding-top: 1.6rem;
    }
    .pt-sm-1-7, .py-sm-1-7 {
        padding-top: 1.7rem;
    }
    .pt-sm-1-8, .py-sm-1-8 {
        padding-top: 1.8rem;
    }
    .pt-sm-1-9, .py-sm-1-9 {
        padding-top: 1.9rem;
    }
    .pt-sm-2-0, .py-sm-2-0 {
        padding-top: 2.0rem;
    }
    .pt-sm-2-1, .py-sm-2-1 {
        padding-top: 2.1rem;
    }
    .pt-sm-2-2, .py-sm-2-2 {
        padding-top: 2.2rem;
    }
    .pt-sm-2-3, .py-sm-2-3 {
        padding-top: 2.3rem;
    }
    .pt-sm-2-4, .py-sm-2-4 {
        padding-top: 2.4rem;
    }
    .pt-sm-2-5, .py-sm-2-5 {
        padding-top: 2.5rem;
    }
    .pt-sm-2-6, .py-sm-2-6 {
        padding-top: 2.6rem;
    }
    .pt-sm-2-7, .py-sm-2-7 {
        padding-top: 2.7rem;
    }
    .pt-sm-2-8, .py-sm-2-8 {
        padding-top: 2.8rem;
    }
    .pt-sm-2-9, .py-sm-2-9 {
        padding-top: 2.9rem;
    }

    .pt-sm-6, .py-sm-6 {
        padding-top: 3.5rem;
    }
    .pt-sm-7, .py-sm-7 {
        padding-top: 4rem;
    }
    .pt-sm-8, .py-sm-8 {
        padding-top: 4.5rem;
    }
    .pt-sm-9, .py-sm-9 {
        padding-top: 5rem;
    }
    .pt-sm-10, .py-sm-10 {
        padding-top: 5.5rem;
    }
    .pt-sm-11, .py-sm-11 {
        padding-top: 6rem;
    }
    .pt-sm-12, .py-sm-12 {
        padding-top: 6.5rem;
    }
    .pt-sm-13, .py-sm-13 {
        padding-top: 7rem;
    }
    .pt-sm-14, .py-sm-14 {
        padding-top: 7.5rem;
    }
    .pt-sm-15, .py-sm-15 {
        padding-top: 8rem;
    }
    .pt-sm-16, .py-sm-16 {
        padding-top: 8.5rem;
    }
    .pt-sm-17, .py-sm-17 {
        padding-top: 9rem;
    }
    .pt-sm-18, .py-sm-18 {
        padding-top: 9.5rem;
    }
    .pt-sm-19, .py-sm-19 {
        padding-top: 10rem;
    }
    .pt-sm-20, .py-sm-20 {
        padding-top: 10.5rem;
    }
    .pt-sm-21, .py-sm-21 {
        padding-top: 11rem;
    }
    .pt-sm-22, .py-sm-22 {
        padding-top: 11.5rem;
    }
    .pt-sm-23, .py-sm-23 {
        padding-top: 12rem;
    }
    .pt-sm-24, .py-sm-24 {
        padding-top: 12.5rem;
    }

    /* padding bottom */
    .pb-sm-1-6, .py-sm-1-6 {
        padding-bottom: 1.6rem;
    }
    .pb-sm-1-7, .py-sm-1-7 {
        padding-bottom: 1.7rem;
    }
    .pb-sm-1-8, .py-sm-1-8 {
        padding-bottom: 1.8rem;
    }
    .pb-sm-1-9, .py-sm-1-9 {
        padding-bottom: 1.9rem;
    }
    .pb-sm-2-0, .py-sm-2-0 {
        padding-bottom: 2.0rem;
    }
    .pb-sm-2-1, .py-sm-2-1 {
        padding-bottom: 2.1rem;
    }
    .pb-sm-2-2, .py-sm-2-2 {
        padding-bottom: 2.2rem;
    }
    .pb-sm-2-3, .py-sm-2-3 {
        padding-bottom: 2.3rem;
    }
    .pb-sm-2-4, .py-sm-2-4 {
        padding-bottom: 2.4rem;
    }
    .pb-sm-2-5, .py-sm-2-5 {
        padding-bottom: 2.5rem;
    }
    .pb-sm-2-6, .py-sm-2-6 {
        padding-bottom: 2.6rem;
    }
    .pb-sm-2-7, .py-sm-2-7 {
        padding-bottom: 2.7rem;
    }
    .pb-sm-2-8, .py-sm-2-8 {
        padding-bottom: 2.8rem;
    }
    .pb-sm-2-9, .py-sm-2-9 {
        padding-bottom: 2.9rem;
    }

    .pb-sm-6, .py-sm-6 {
        padding-bottom: 3.5rem;
    }
    .pb-sm-7, .py-sm-7 {
        padding-bottom: 4rem;
    }
    .pb-sm-8, .py-sm-8 {
        padding-bottom: 4.5rem;
    }
    .pb-sm-9, .py-sm-9 {
        padding-bottom: 5rem;
    }
    .pb-sm-10, .py-sm-10 {
        padding-bottom: 5.5rem;
    }
    .pb-sm-11, .py-sm-11 {
        padding-bottom: 6rem;
    }
    .pb-sm-12, .py-sm-12 {
        padding-bottom: 6.5rem;
    }
    .pb-sm-13, .py-sm-13 {
        padding-bottom: 7rem;
    }
    .pb-sm-14, .py-sm-14 {
        padding-bottom: 7.5rem;
    }
    .pb-sm-15, .py-sm-15 {
        padding-bottom: 8rem;
    }
    .pb-sm-16, .py-sm-16 {
        padding-bottom: 8.5rem;
    }
    .pb-sm-17, .py-sm-17 {
        padding-bottom: 9rem;
    }
    .pb-sm-18, .py-sm-18 {
        padding-bottom: 9.5rem;
    }
    .pb-sm-19, .py-sm-19 {
        padding-bottom: 10rem;
    }
    .pb-sm-20, .py-sm-20 {
        padding-bottom: 10.5rem;
    }
    .pb-sm-21, .py-sm-21 {
        padding-bottom: 11rem;
    }
    .pb-sm-22, .py-sm-22 {
        padding-bottom: 11.5rem;
    }
    .pb-sm-23, .py-sm-23 {
        padding-bottom: 12rem;
    }
    .pb-sm-24, .py-sm-24 {
        padding-bottom: 12.5rem;
    }

    /* padding left */
    .pl-sm-1-6, .px-sm-1-6 {
        padding-left: 1.6rem;
    }
    .pl-sm-1-7, .px-sm-1-7 {
        padding-left: 1.7rem;
    }
    .pl-sm-1-8, .px-sm-1-8 {
        padding-left: 1.8rem;
    }
    .pl-sm-1-9, .px-sm-1-9 {
        padding-left: 1.9rem;
    }
    .pl-sm-2-0, .px-sm-2-0 {
        padding-left: 2.0rem;
    }
    .pl-sm-2-1, .px-sm-2-1 {
        padding-left: 2.1rem;
    }
    .pl-sm-2-2, .px-sm-2-2 {
        padding-left: 2.2rem;
    }
    .pl-sm-2-3, .px-sm-2-3 {
        padding-left: 2.3rem;
    }
    .pl-sm-2-4, .px-sm-2-4 {
        padding-left: 2.4rem;
    }
    .pl-sm-2-5, .px-sm-2-5 {
        padding-left: 2.5rem;
    }
    .pl-sm-2-6, .px-sm-2-6 {
        padding-left: 2.6rem;
    }
    .pl-sm-2-7, .px-sm-2-7 {
        padding-left: 2.7rem;
    }
    .pl-sm-2-8, .px-sm-2-8 {
        padding-left: 2.8rem;
    }
    .pl-sm-2-9, .px-sm-2-9 {
        padding-left: 2.9rem;
    }

    .pl-sm-6, .px-sm-6 {
        padding-left: 3.5rem;
    }
    .pl-sm-7, .px-sm-7 {
        padding-left: 4rem;
    }
    .pl-sm-8, .px-sm-8 {
        padding-left: 4.5rem;
    }
    .pl-sm-9, .px-sm-9 {
        padding-left: 5rem;
    }
    .pl-sm-10, .px-sm-10 {
        padding-left: 5.5rem;
    }
    .pl-sm-11, .px-sm-11 {
        padding-left: 6rem;
    }
    .pl-sm-12, .px-sm-12 {
        padding-left: 6.5rem;
    }
    .pl-sm-13, .px-sm-13 {
        padding-left: 7rem;
    }
    .pl-sm-14, .px-sm-14 {
        padding-left: 7.5rem;
    }
    .pl-sm-15, .px-sm-15 {
        padding-left: 8rem;
    }
    .pl-sm-16, .px-sm-16 {
        padding-left: 8.5rem;
    }
    .pl-sm-17, .px-sm-17 {
        padding-left: 9rem;
    }
    .pl-sm-18, .px-sm-18 {
        padding-left: 9.5rem;
    }
    .pl-sm-19, .px-sm-19 {
        padding-left: 10rem;
    }
    .pl-sm-20, .px-sm-20 {
        padding-left: 10.5rem;
    }
    .pl-sm-21, .px-sm-21 {
        padding-left: 11rem;
    }
    .pl-sm-22, .px-sm-22 {
        padding-left: 11.5rem;
    }
    .pl-sm-23, .px-sm-23 {
        padding-left: 12rem;
    }
    .pl-sm-24, .px-sm-24 {
        padding-left: 12.5rem;
    }

    /* padding right */
    .pr-sm-1-6, .px-sm-1-6 {
        padding-right: 1.6rem;
    }
    .pr-sm-1-7, .px-sm-1-7 {
        padding-right: 1.7rem;
    }
    .pr-sm-1-8, .px-sm-1-8 {
        padding-right: 1.8rem;
    }
    .pr-sm-1-9, .px-sm-1-9 {
        padding-right: 1.9rem;
    }
    .pr-sm-2-0, .px-sm-2-0 {
        padding-right: 2.0rem;
    }
    .pr-sm-2-1, .px-sm-2-1 {
        padding-right: 2.1rem;
    }
    .pr-sm-2-2, .px-sm-2-2 {
        padding-right: 2.2rem;
    }
    .pr-sm-2-3, .px-sm-2-3 {
        padding-right: 2.3rem;
    }
    .pr-sm-2-4, .px-sm-2-4 {
        padding-right: 2.4rem;
    }
    .pr-sm-2-5, .px-sm-2-5 {
        padding-right: 2.5rem;
    }
    .pr-sm-2-6, .px-sm-2-6 {
        padding-right: 2.6rem;
    }
    .pr-sm-2-7, .px-sm-2-7 {
        padding-right: 2.7rem;
    }
    .pr-sm-2-8, .px-sm-2-8 {
        padding-right: 2.8rem;
    }
    .pr-sm-2-9, .px-sm-2-9 {
        padding-right: 2.9rem;
    }
    
    .pr-sm-6, .px-sm-6 {
        padding-right: 3.5rem;
    }
    .pr-sm-7, .px-sm-7 {
        padding-right: 4rem;
    }
    .pr-sm-8, .px-sm-8 {
        padding-right: 4.5rem;
    }
    .pr-sm-9, .px-sm-9 {
        padding-right: 5rem;
    }
    .pr-sm-10, .px-sm-10 {
        padding-right: 5.5rem;
    }
    .pr-sm-11, .px-sm-11 {
        padding-right: 6rem;
    }
    .pr-sm-12, .px-sm-12 {
        padding-right: 6.5rem;
    }
    .pr-sm-13, .px-sm-13 {
        padding-right: 7rem;
    }
    .pr-sm-14, .px-sm-14 {
        padding-right: 7.5rem;
    }
    .pr-sm-15, .px-sm-15 {
        padding-right: 8rem;
    }
    .pr-sm-16, .px-sm-16 {
        padding-right: 8.5rem;
    }
    .pr-sm-17, .px-sm-17 {
        padding-right: 9rem;
    }
    .pr-sm-18, .px-sm-18 {
        padding-right: 9.5rem;
    }
    .pr-sm-19, .px-sm-19 {
        padding-right: 10rem;
    }
    .pr-sm-20, .px-sm-20 {
        padding-right: 10.5rem;
    }
    .pr-sm-21, .px-sm-21 {
        padding-right: 11rem;
    }
    .pr-sm-22, .px-sm-22 {
        padding-right: 11.5rem;
    }
    .pr-sm-23, .px-sm-23 {
        padding-right: 12rem;
    }
    .pr-sm-24, .px-sm-24 {
        padding-right: 12.5rem;
    }

}

@media screen and (min-width: 768px) {

    /* width */
    .w-md-1px {width:1px;}
    .w-md-2px {width:2px;}
    .w-md-3px {width:3px;}
    .w-md-4px {width:4px;}
    .w-md-5px {width:5px;}
    .w-md-6px {width:6px;}
    .w-md-7px {width:7px;}
    .w-md-8px {width:8px;}
    .w-md-9px {width:9px;}
    .w-md-10px {width:10px;}
    .w-md-15px {width:15px;}
    .w-md-20px {width:20px;}
    .w-md-25px {width:25px;}
    .w-md-30px {width:30px;}
    .w-md-35px {width:35px;}
    .w-md-40px {width:40px;}
    .w-md-50px {width:50px;}
    .w-md-55px {width:55px;}
    .w-md-60px {width:60px;}
    .w-md-65px {width:65px;}
    .w-md-70px {width:70px;}
    .w-md-75px {width:75px;}
    .w-md-80px {width:80px;}
    .w-md-85px {width:85px;}
    .w-md-90px {width:90px;}
    .w-md-100px {width:100px;}

    .w-md-10 {width:10%;}
    .w-md-12 {width:12%;}
    .w-md-15 {width:15%;}
    .w-md-20 {width:20%;}
    .w-md-25 {width:25%;}
    .w-md-30 {width:30%;}
    .w-md-35 {width:35%;}
    .w-md-40 {width:40%;}
    .w-md-45 {width:45%;}
    .w-md-50 {width:50%;}
    .w-md-55 {width:55%;}
    .w-md-60 {width:60%;}
    .w-md-65 {width:65%;}
    .w-md-70 {width:70%;}
    .w-md-75 {width:75%;}
    .w-md-80 {width:80%;}
    .w-md-85 {width:85%;}
    .w-md-90 {width:90%;}
    .w-md-95 {width:95%;}
    .w-md-100 {width:100%;}
    .w-md-auto {width: auto;}

    /* font size */
    .display-md-1 {font-size:6rem;}
    .display-md-2 {font-size:5.5rem;}
    .display-md-3 {font-size:4.5rem;}
    .display-md-4 {font-size:3.5rem;}
    .display-md-5 {font-size:3.4rem;}
    .display-md-6 {font-size:3.3rem;}
    .display-md-7 {font-size:3.2rem;}
    .display-md-8 {font-size:3.1rem;}
    .display-md-9 {font-size:3.0rem;}
    .display-md-10 {font-size:2.9rem;}
    .display-md-11 {font-size:2.8rem;}
    .display-md-12 {font-size:2.7rem;}
    .display-md-13 {font-size:2.6rem;}
    .display-md-14 {font-size:2.5rem;}
    .display-md-15 {font-size:2.4rem;}
    .display-md-16 {font-size:2.3rem;}
    .display-md-17 {font-size:2.2rem;}
    .display-md-18 {font-size:2.1rem;}
    .display-md-19 {font-size:2.0rem;}
    .display-md-20 {font-size:1.9rem;}
    .display-md-21 {font-size:1.8rem;}
    .display-md-22 {font-size:1.7rem;}
    .display-md-23 {font-size:1.6rem;}
    .display-md-24 {font-size:1.5rem;}
    .display-md-25 {font-size:1.4rem;}
    .display-md-26 {font-size:1.3rem;}
    .display-md-27 {font-size:1.2rem;}
    .display-md-28 {font-size:1.1rem;}
    .display-md-29 {font-size:1.0rem;}
    .display-md-30 {font-size:0.9rem;}
    .display-md-31 {font-size:0.8rem;}
    .display-md-32 {font-size:0.7rem;}
    .display-md-33 {font-size:0.6rem;}
    .display-md-34 {font-size:0.5rem;}

    /* margin */

    /* margin all */
    .m-md-1-6 {
        margin: 1.6rem;
    }
    .m-md-1-7 {
        margin: 1.7rem;
    }
    .m-md-1-8 {
        margin: 1.8rem;
    }
    .m-md-1-9 {
        margin: 1.9rem;
    }
    .m-md-2-0 {
        margin: 2.0rem;
    }
    .m-md-2-1 {
        margin: 2.1rem;
    }
    .m-md-2-2 {
        margin: 2.2rem;
    }
    .m-md-2-3 {
        margin: 2.3rem;
    }
    .m-md-2-4 {
        margin: 2.4rem;
    }
    .m-md-2-5 {
        margin: 2.5rem;
    }
    .m-md-2-6 {
        margin: 2.6rem;
    }
    .m-md-2-7 {
        margin: 2.7rem;
    }
    .m-md-2-8 {
        margin: 2.8rem;
    }
    .m-md-2-9 {
        margin: 2.9rem;
    }

    .m-md-6 {
        margin: 3.5rem;
    }
    .m-md-7 {
        margin: 4rem;
    }
    .m-md-8 {
        margin: 4.5rem;
    }
    .m-md-9 {
        margin: 5rem;
    }
    .m-md-10 {
        margin: 5.5rem;
    }
    .m-md-11 {
        margin: 6rem;
    }
    .m-md-12 {
        margin: 6.5rem;
    }
    .m-md-13 {
        margin: 7rem;
    }
    .m-md-14 {
        margin: 7.5rem;
    }
    .m-md-15 {
        margin: 8rem;
    }
    .m-md-16 {
        margin: 8.5rem;
    }
    .m-md-17 {
        margin: 9rem;
    }
    .m-md-18 {
        margin: 9.5rem;
    }
    .m-md-19 {
        margin: 10rem;
    }
    .m-md-20 {
        margin: 10.5rem;
    }
    .m-md-21 {
        margin: 11rem;
    }
    .m-md-22 {
        margin: 11.5rem;
    }
    .m-md-23 {
        margin: 12rem;
    }
    .m-md-24 {
        margin: 12.5rem;
    }

    /* margin top */
    .mt-md-1-6, .my-md-1-6 {
        margin-top: 1.6rem;
    }
    .mt-md-1-7, .my-md-1-7 {
        margin-top: 1.7rem;
    }
    .mt-md-1-8, .my-md-1-8 {
        margin-top: 1.8rem;
    }
    .mt-md-1-9, .my-md-1-9 {
        margin-top: 1.9rem;
    }
    .mt-md-2-0, .my-md-2-0 {
        margin-top: 2.0rem;
    }
    .mt-md-2-1, .my-md-2-1 {
        margin-top: 2.1rem;
    }
    .mt-md-2-2, .my-md-2-2 {
        margin-top: 2.2rem;
    }
    .mt-md-2-3, .my-md-2-3 {
        margin-top: 2.3rem;
    }
    .mt-md-2-4, .my-md-2-4 {
        margin-top: 2.4rem;
    }
    .mt-md-2-5, .my-md-2-5 {
        margin-top: 2.5rem;
    }
    .mt-md-2-6, .my-md-2-6 {
        margin-top: 2.6rem;
    }
    .mt-md-2-7, .my-md-2-7 {
        margin-top: 2.7rem;
    }
    .mt-md-2-8, .my-md-2-8 {
        margin-top: 2.8rem;
    }
    .mt-md-2-9, .my-md-2-9 {
        margin-top: 2.9rem;
    }

    .mt-md-6, .my-md-6 {
        margin-top: 3.5rem;
    }
    .mt-md-7, .my-md-7 {
        margin-top: 4rem;
    }
    .mt-md-8, .my-md-8 {
        margin-top: 4.5rem;
    }
    .mt-md-9, .my-md-9 {
        margin-top: 5rem;
    }
    .mt-md-10, .my-md-10 {
        margin-top: 5.5rem;
    }
    .mt-md-11, .my-md-11 {
        margin-top: 6rem;
    }
    .mt-md-12, .my-md-12 {
        margin-top: 6.5rem;
    }
    .mt-md-13, .my-md-13 {
        margin-top: 7rem;
    }
    .mt-md-14, .my-md-14 {
        margin-top: 7.5rem;
    }
    .mt-md-15, .my-md-15 {
        margin-top: 8rem;
    }
    .mt-md-16, .my-md-16 {
        margin-top: 8.5rem;
    }
    .mt-md-17, .my-md-17 {
        margin-top: 9rem;
    }
    .mt-md-18, .my-md-18 {
        margin-top: 9.5rem;
    }
    .mt-md-19, .my-md-19 {
        margin-top: 10rem;
    }
    .mt-md-20, .my-md-20 {
        margin-top: 10.5rem;
    }
    .mt-md-21, .my-md-21 {
        margin-top: 11rem;
    }
    .mt-md-22, .my-md-22 {
        margin-top: 11.5rem;
    }
    .mt-md-23, .my-md-23 {
        margin-top: 12rem;
    }
    .mt-md-24, .my-md-24 {
        margin-top: 12.5rem;
    }

    /* margin bottom */
    .mb-md-1-6, .my-md-1-6 {
        margin-bottom: 1.6rem;
    }
    .mb-md-1-7, .my-md-1-7 {
        margin-bottom: 1.7rem;
    }
    .mb-md-1-8, .my-md-1-8 {
        margin-bottom: 1.8rem;
    }
    .mb-md-1-9, .my-md-1-9 {
        margin-bottom: 1.9rem;
    }
    .mb-md-2-0, .my-md-2-0 {
        margin-bottom: 2.0rem;
    }
    .mb-md-2-1, .my-md-2-1 {
        margin-bottom: 2.1rem;
    }
    .mb-md-2-2, .my-md-2-2 {
        margin-bottom: 2.2rem;
    }
    .mb-md-2-3, .my-md-2-3 {
        margin-bottom: 2.3rem;
    }
    .mb-md-2-4, .my-md-2-4 {
        margin-bottom: 2.4rem;
    }
    .mb-md-2-5, .my-md-2-5 {
        margin-bottom: 2.5rem;
    }
    .mb-md-2-6, .my-md-2-6 {
        margin-bottom: 2.6rem;
    }
    .mb-md-2-7, .my-md-2-7 {
        margin-bottom: 2.7rem;
    }
    .mb-md-2-8, .my-md-2-8 {
        margin-bottom: 2.8rem;
    }
    .mb-md-2-9, .my-md-2-9 {
        margin-bottom: 2.9rem;
    }

    .mb-md-6, .my-md-6 {
        margin-bottom: 3.5rem;
    }
    .mb-md-7, .my-md-7 {
        margin-bottom: 4rem;
    }
    .mb-md-8, .my-md-8 {
        margin-bottom: 4.5rem;
    }
    .mb-md-9, .my-md-9 {
        margin-bottom: 5rem;
    }
    .mb-md-10, .my-md-10 {
        margin-bottom: 5.5rem;
    }
    .mb-md-11, .my-md-11 {
        margin-bottom: 6rem;
    }
    .mb-md-12, .my-md-12 {
        margin-bottom: 6.5rem;
    }
    .mb-md-13, .my-md-13 {
        margin-bottom: 7rem;
    }
    .mb-md-14, .my-md-14 {
        margin-bottom: 7.5rem;
    }
    .mb-md-15, .my-md-15 {
        margin-bottom: 8rem;
    }
    .mb-md-16, .my-md-16 {
        margin-bottom: 8.5rem;
    }
    .mb-md-17, .my-md-17 {
        margin-bottom: 9rem;
    }
    .mb-md-18, .my-md-18 {
        margin-bottom: 9.5rem;
    }
    .mb-md-19, .my-md-19 {
        margin-bottom: 10rem;
    }
    .mb-md-20, .my-md-20 {
        margin-bottom: 10.5rem;
    }
    .mb-md-21, .my-md-21 {
        margin-bottom: 11rem;
    }
    .mb-md-22, .my-md-22 {
        margin-bottom: 11.5rem;
    }
    .mb-md-23, .my-md-23 {
        margin-bottom: 12rem;
    }
    .mb-md-24, .my-md-24 {
        margin-bottom: 12.5rem;
    }

    /* margin left */
    .ml-md-1-6, .mx-md-1-6 {
        margin-left: 1.6rem;
    }
    .ml-md-1-7, .mx-md-1-7 {
        margin-left: 1.7rem;
    }
    .ml-md-1-8, .mx-md-1-8 {
        margin-left: 1.8rem;
    }
    .ml-md-1-9, .mx-md-1-9 {
        margin-left: 1.9rem;
    }
    .ml-md-2-0, .mx-md-2-0 {
        margin-left: 2.0rem;
    }
    .ml-md-2-1, .mx-md-2-1 {
        margin-left: 2.1rem;
    }
    .ml-md-2-2, .mx-md-2-2 {
        margin-left: 2.2rem;
    }
    .ml-md-2-3, .mx-md-2-3 {
        margin-left: 2.3rem;
    }
    .ml-md-2-4, .mx-md-2-4 {
        margin-left: 2.4rem;
    }
    .ml-md-2-5, .mx-md-2-5 {
        margin-left: 2.5rem;
    }
    .ml-md-2-6, .mx-md-2-6 {
        margin-left: 2.6rem;
    }
    .ml-md-2-7, .mx-md-2-7 {
        margin-left: 2.7rem;
    }
    .ml-md-2-8, .mx-md-2-8 {
        margin-left: 2.8rem;
    }
    .ml-md-2-9, .mx-md-2-9 {
        margin-left: 2.9rem;
    }

    .ml-md-6, .mx-md-6 {
        margin-left: 3.5rem;
    }
    .ml-md-7, .mx-md-7 {
        margin-left: 4rem;
    }
    .ml-md-8, .mx-md-8 {
        margin-left: 4.5rem;
    }
    .ml-md-9, .mx-md-9 {
        margin-left: 5rem;
    }
    .ml-md-10, .mx-md-10 {
        margin-left: 5.5rem;
    }
    .ml-md-11, .mx-md-11 {
        margin-left: 6rem;
    }
    .ml-md-12, .mx-md-12 {
        margin-left: 6.5rem;
    }
    .ml-md-13, .mx-md-13 {
        margin-left: 7rem;
    }
    .ml-md-14, .mx-md-14 {
        margin-left: 7.5rem;
    }
    .ml-md-15, .mx-md-15 {
        margin-left: 8rem;
    }
    .ml-md-16, .mx-md-16 {
        margin-left: 8.5rem;
    }
    .ml-md-17, .mx-md-17 {
        margin-left: 9rem;
    }
    .ml-md-18, .mx-md-18 {
        margin-left: 9.5rem;
    }
    .ml-md-19, .mx-md-19 {
        margin-left: 10rem;
    }
    .ml-md-20, .mx-md-20 {
        margin-left: 10.5rem;
    }
    .ml-md-21, .mx-md-21 {
        margin-left: 11rem;
    }
    .ml-md-22, .mx-md-22 {
        margin-left: 11.5rem;
    }
    .ml-md-23, .mx-md-23 {
        margin-left: 12rem;
    }
    .ml-md-24, .mx-md-24 {
        margin-left: 12.5rem;
    }

    /* margin right */
    .mr-md-1-6, .mx-md-1-6 {
        margin-right: 1.6rem;
    }
    .mr-md-1-7, .mx-md-1-7 {
        margin-right: 1.7rem;
    }
    .mr-md-1-8, .mx-md-1-8 {
        margin-right: 1.8rem;
    }
    .mr-md-1-9, .mx-md-1-9 {
        margin-right: 1.9rem;
    }
    .mr-md-2-0, .mx-md-2-0 {
        margin-right: 2.0rem;
    }
    .mr-md-2-1, .mx-md-2-1 {
        margin-right: 2.1rem;
    }
    .mr-md-2-2, .mx-md-2-2 {
        margin-right: 2.2rem;
    }
    .mr-md-2-3, .mx-md-2-3 {
        margin-right: 2.3rem;
    }
    .mr-md-2-4, .mx-md-2-4 {
        margin-right: 2.4rem;
    }
    .mr-md-2-5, .mx-md-2-5 {
        margin-right: 2.5rem;
    }
    .mr-md-2-6, .mx-md-2-6 {
        margin-right: 2.6rem;
    }
    .mr-md-2-7, .mx-md-2-7 {
        margin-right: 2.7rem;
    }
    .mr-md-2-8, .mx-md-2-8 {
        margin-right: 2.8rem;
    }
    .mr-md-2-9, .mx-md-2-9 {
        margin-right: 2.9rem;
    }

    .mr-md-6, .mx-md-6 {
        margin-right: 3.5rem;
    }
    .mr-md-7, .mx-md-7 {
        margin-right: 4rem;
    }
    .mr-md-8, .mx-md-8 {
        margin-right: 4.5rem;
    }
    .mr-md-9, .mx-md-9 {
        margin-right: 5rem;
    }
    .mr-md-10, .mx-md-10 {
        margin-right: 5.5rem;
    }
    .mr-md-11, .mx-md-11 {
        margin-right: 6rem;
    }
    .mr-md-12, .mx-md-12 {
        margin-right: 6.5rem;
    }
    .mr-md-13, .mx-md-13 {
        margin-right: 7rem;
    }
    .mr-md-14, .mx-md-14 {
        margin-right: 7.5rem;
    }
    .mr-md-15, .mx-md-15 {
        margin-right: 8rem;
    }
    .mr-md-16, .mx-md-16 {
        margin-right: 8.5rem;
    }
    .mr-md-17, .mx-md-17 {
        margin-right: 9rem;
    }
    .mr-md-18, .mx-md-18 {
        margin-right: 9.5rem;
    }
    .mr-md-19, .mx-md-19 {
        margin-right: 10rem;
    }
    .mr-md-20, .mx-md-20 {
        margin-right: 10.5rem;
    }
    .mr-md-21, .mx-md-21 {
        margin-right: 11rem;
    }
    .mr-md-22, .mx-md-22 {
        margin-right: 11.5rem;
    }
    .mr-md-23, .mx-md-23 {
        margin-right: 12rem;
    }
    .mr-md-24, .mx-md-24 {
        margin-right: 12.5rem;
    }

    /* padding */

    /* padding all */
    .p-md-1-6 {
        padding: 1.6rem;
    }
    .p-md-1-7 {
        padding: 1.7rem;
    }
    .p-md-1-8 {
        padding: 1.8rem;
    }
    .p-md-1-9 {
        padding: 1.9rem;
    }
    .p-md-2-0 {
        padding: 2.0rem;
    }
    .p-md-2-1 {
        padding: 2.1rem;
    }
    .p-md-2-2 {
        padding: 2.2rem;
    }
    .p-md-2-3 {
        padding: 2.3rem;
    }
    .p-md-2-4 {
        padding: 2.4rem;
    }
    .p-md-2-5 {
        padding: 2.5rem;
    }
    .p-md-2-6 {
        padding: 2.6rem;
    }
    .p-md-2-7 {
        padding: 2.7rem;
    }
    .p-md-2-8 {
        padding: 2.8rem;
    }
    .p-md-2-9 {
        padding: 2.9rem;
    }

    .p-md-6 {
        padding: 3.5rem;
    }
    .p-md-7 {
        padding: 4rem;
    }
    .p-md-8 {
        padding: 4.5rem;
    }
    .p-md-9 {
        padding: 5rem;
    }
    .p-md-10 {
        padding: 5.5rem;
    }
    .p-md-11 {
        padding: 6rem;
    }
    .p-md-12 {
        padding: 6.5rem;
    }
    .p-md-13 {
        padding: 7rem;
    }
    .p-md-14 {
        padding: 7.5rem;
    }
    .p-md-15 {
        padding: 8rem;
    }
    .p-md-16 {
        padding: 8.5rem;
    }
    .p-md-17 {
        padding: 9rem;
    }
    .p-md-18 {
        padding: 9.5rem;
    }
    .p-md-19 {
        padding: 10rem;
    }
    .p-md-20 {
        padding: 10.5rem;
    }
    .p-md-21 {
        padding: 11rem;
    }
    .p-md-22 {
        padding: 11.5rem;
    }
    .p-md-23 {
        padding: 12rem;
    }
    .p-md-24 {
        padding: 12.5rem;
    }

    /* padding top */
    .pt-md-1-6, .py-md-1-6 {
        padding-top: 1.6rem;
    }
    .pt-md-1-7, .py-md-1-7 {
        padding-top: 1.7rem;
    }
    .pt-md-1-8, .py-md-1-8 {
        padding-top: 1.8rem;
    }
    .pt-md-1-9, .py-md-1-9 {
        padding-top: 1.9rem;
    }
    .pt-md-2-0, .py-md-2-0 {
        padding-top: 2.0rem;
    }
    .pt-md-2-1, .py-md-2-1 {
        padding-top: 2.1rem;
    }
    .pt-md-2-2, .py-md-2-2 {
        padding-top: 2.2rem;
    }
    .pt-md-2-3, .py-md-2-3 {
        padding-top: 2.3rem;
    }
    .pt-md-2-4, .py-md-2-4 {
        padding-top: 2.4rem;
    }
    .pt-md-2-5, .py-md-2-5 {
        padding-top: 2.5rem;
    }
    .pt-md-2-6, .py-md-2-6 {
        padding-top: 2.6rem;
    }
    .pt-md-2-7, .py-md-2-7 {
        padding-top: 2.7rem;
    }
    .pt-md-2-8, .py-md-2-8 {
        padding-top: 2.8rem;
    }
    .pt-md-2-9, .py-md-2-9 {
        padding-top: 2.9rem;
    }

    .pt-md-6, .py-md-6 {
        padding-top: 3.5rem;
    }
    .pt-md-7, .py-md-7 {
        padding-top: 4rem;
    }
    .pt-md-8, .py-md-8 {
        padding-top: 4.5rem;
    }
    .pt-md-9, .py-md-9 {
        padding-top: 5rem;
    }
    .pt-md-10, .py-md-10 {
        padding-top: 5.5rem;
    }
    .pt-md-11, .py-md-11 {
        padding-top: 6rem;
    }
    .pt-md-12, .py-md-12 {
        padding-top: 6.5rem;
    }
    .pt-md-13, .py-md-13 {
        padding-top: 7rem;
    }
    .pt-md-14, .py-md-14 {
        padding-top: 7.5rem;
    }
    .pt-md-15, .py-md-15 {
        padding-top: 8rem;
    }
    .pt-md-16, .py-md-16 {
        padding-top: 8.5rem;
    }
    .pt-md-17, .py-md-17 {
        padding-top: 9rem;
    }
    .pt-md-18, .py-md-18 {
        padding-top: 9.5rem;
    }
    .pt-md-19, .py-md-19 {
        padding-top: 10rem;
    }
    .pt-md-20, .py-md-20 {
        padding-top: 10.5rem;
    }
    .pt-md-21, .py-md-21 {
        padding-top: 11rem;
    }
    .pt-md-22, .py-md-22 {
        padding-top: 11.5rem;
    }
    .pt-md-23, .py-md-23 {
        padding-top: 12rem;
    }
    .pt-md-24, .py-md-24 {
        padding-top: 12.5rem;
    }

    /* padding bottom */
    .pb-md-1-6, .py-md-1-6 {
        padding-bottom: 1.6rem;
    }
    .pb-md-1-7, .py-md-1-7 {
        padding-bottom: 1.7rem;
    }
    .pb-md-1-8, .py-md-1-8 {
        padding-bottom: 1.8rem;
    }
    .pb-md-1-9, .py-md-1-9 {
        padding-bottom: 1.9rem;
    }
    .pb-md-2-0, .py-md-2-0 {
        padding-bottom: 2.0rem;
    }
    .pb-md-2-1, .py-md-2-1 {
        padding-bottom: 2.1rem;
    }
    .pb-md-2-2, .py-md-2-2 {
        padding-bottom: 2.2rem;
    }
    .pb-md-2-3, .py-md-2-3 {
        padding-bottom: 2.3rem;
    }
    .pb-md-2-4, .py-md-2-4 {
        padding-bottom: 2.4rem;
    }
    .pb-md-2-5, .py-md-2-5 {
        padding-bottom: 2.5rem;
    }
    .pb-md-2-6, .py-md-2-6 {
        padding-bottom: 2.6rem;
    }
    .pb-md-2-7, .py-md-2-7 {
        padding-bottom: 2.7rem;
    }
    .pb-md-2-8, .py-md-2-8 {
        padding-bottom: 2.8rem;
    }
    .pb-md-2-9, .py-md-2-9 {
        padding-bottom: 2.9rem;
    }

    .pb-md-6, .py-md-6 {
        padding-bottom: 3.5rem;
    }
    .pb-md-7, .py-md-7 {
        padding-bottom: 4rem;
    }
    .pb-md-8, .py-md-8 {
        padding-bottom: 4.5rem;
    }
    .pb-md-9, .py-md-9 {
        padding-bottom: 5rem;
    }
    .pb-md-10, .py-md-10 {
        padding-bottom: 5.5rem;
    }
    .pb-md-11, .py-md-11 {
        padding-bottom: 6rem;
    }
    .pb-md-12, .py-md-12 {
        padding-bottom: 6.5rem;
    }
    .pb-md-13, .py-md-13 {
        padding-bottom: 7rem;
    }
    .pb-md-14, .py-md-14 {
        padding-bottom: 7.5rem;
    }
    .pb-md-15, .py-md-15 {
        padding-bottom: 8rem;
    }
    .pb-md-16, .py-md-16 {
        padding-bottom: 8.5rem;
    }
    .pb-md-17, .py-md-17 {
        padding-bottom: 9rem;
    }
    .pb-md-18, .py-md-18 {
        padding-bottom: 9.5rem;
    }
    .pb-md-19, .py-md-19 {
        padding-bottom: 10rem;
    }
    .pb-md-20, .py-md-20 {
        padding-bottom: 10.5rem;
    }
    .pb-md-21, .py-md-21 {
        padding-bottom: 11rem;
    }
    .pb-md-22, .py-md-22 {
        padding-bottom: 11.5rem;
    }
    .pb-md-23, .py-md-23 {
        padding-bottom: 12rem;
    }
    .pb-md-24, .py-md-24 {
        padding-bottom: 12.5rem;
    }

    /* padding left */
    .pl-md-1-6, .px-md-1-6 {
        padding-left: 1.6rem;
    }
    .pl-md-1-7, .px-md-1-7 {
        padding-left: 1.7rem;
    }
    .pl-md-1-8, .px-md-1-8 {
        padding-left: 1.8rem;
    }
    .pl-md-1-9, .px-md-1-9 {
        padding-left: 1.9rem;
    }
    .pl-md-2-0, .px-md-2-0 {
        padding-left: 2.0rem;
    }
    .pl-md-2-1, .px-md-2-1 {
        padding-left: 2.1rem;
    }
    .pl-md-2-2, .px-md-2-2 {
        padding-left: 2.2rem;
    }
    .pl-md-2-3, .px-md-2-3 {
        padding-left: 2.3rem;
    }
    .pl-md-2-4, .px-md-2-4 {
        padding-left: 2.4rem;
    }
    .pl-md-2-5, .px-md-2-5 {
        padding-left: 2.5rem;
    }
    .pl-md-2-6, .px-md-2-6 {
        padding-left: 2.6rem;
    }
    .pl-md-2-7, .px-md-2-7 {
        padding-left: 2.7rem;
    }
    .pl-md-2-8, .px-md-2-8 {
        padding-left: 2.8rem;
    }
    .pl-md-2-9, .px-md-2-9 {
        padding-left: 2.9rem;
    }

    .pl-md-6, .px-md-6 {
        padding-left: 3.5rem;
    }
    .pl-md-7, .px-md-7 {
        padding-left: 4rem;
    }
    .pl-md-8, .px-md-8 {
        padding-left: 4.5rem;
    }
    .pl-md-9, .px-md-9 {
        padding-left: 5rem;
    }
    .pl-md-10, .px-md-10 {
        padding-left: 5.5rem;
    }
    .pl-md-11, .px-md-11 {
        padding-left: 6rem;
    }
    .pl-md-12, .px-md-12 {
        padding-left: 6.5rem;
    }
    .pl-md-13, .px-md-13 {
        padding-left: 7rem;
    }
    .pl-md-14, .px-md-14 {
        padding-left: 7.5rem;
    }
    .pl-md-15, .px-md-15 {
        padding-left: 8rem;
    }
    .pl-md-16, .px-md-16 {
        padding-left: 8.5rem;
    }
    .pl-md-17, .px-md-17 {
        padding-left: 9rem;
    }
    .pl-md-18, .px-md-18 {
        padding-left: 9.5rem;
    }
    .pl-md-19, .px-md-19 {
        padding-left: 10rem;
    }
    .pl-md-20, .px-md-20 {
        padding-left: 10.5rem;
    }
    .pl-md-21, .px-md-21 {
        padding-left: 11rem;
    }
    .pl-md-22, .px-md-22 {
        padding-left: 11.5rem;
    }
    .pl-md-23, .px-md-23 {
        padding-left: 12rem;
    }
    .pl-md-24, .px-md-24 {
        padding-left: 12.5rem;
    }

    /* padding right */
    .pr-md-1-6, .px-md-1-6 {
        padding-right: 1.6rem;
    }
    .pr-md-1-7, .px-md-1-7 {
        padding-right: 1.7rem;
    }
    .pr-md-1-8, .px-md-1-8 {
        padding-right: 1.8rem;
    }
    .pr-md-1-9, .px-md-1-9 {
        padding-right: 1.9rem;
    }
    .pr-md-2-0, .px-md-2-0 {
        padding-right: 2.0rem;
    }
    .pr-md-2-1, .px-md-2-1 {
        padding-right: 2.1rem;
    }
    .pr-md-2-2, .px-md-2-2 {
        padding-right: 2.2rem;
    }
    .pr-md-2-3, .px-md-2-3 {
        padding-right: 2.3rem;
    }
    .pr-md-2-4, .px-md-2-4 {
        padding-right: 2.4rem;
    }
    .pr-md-2-5, .px-md-2-5 {
        padding-right: 2.5rem;
    }
    .pr-md-2-6, .px-md-2-6 {
        padding-right: 2.6rem;
    }
    .pr-md-2-7, .px-md-2-7 {
        padding-right: 2.7rem;
    }
    .pr-md-2-8, .px-md-2-8 {
        padding-right: 2.8rem;
    }
    .pr-md-2-9, .px-md-2-9 {
        padding-right: 2.9rem;
    }

    .pr-md-6, .px-md-6 {
        padding-right: 3.5rem;
    }
    .pr-md-7, .px-md-7 {
        padding-right: 4rem;
    }
    .pr-md-8, .px-md-8 {
        padding-right: 4.5rem;
    }
    .pr-md-9, .px-md-9 {
        padding-right: 5rem;
    }
    .pr-md-10, .px-md-10 {
        padding-right: 5.5rem;
    }
    .pr-md-11, .px-md-11 {
        padding-right: 6rem;
    }
    .pr-md-12, .px-md-12 {
        padding-right: 6.5rem;
    }
    .pr-md-13, .px-md-13 {
        padding-right: 7rem;
    }
    .pr-md-14, .px-md-14 {
        padding-right: 7.5rem;
    }
    .pr-md-15, .px-md-15 {
        padding-right: 8rem;
    }
    .pr-md-16, .px-md-16 {
        padding-right: 8.5rem;
    }
    .pr-md-17, .px-md-17 {
        padding-right: 9rem;
    }
    .pr-md-18, .px-md-18 {
        padding-right: 9.5rem;
    }
    .pr-md-19, .px-md-19 {
        padding-right: 10rem;
    }
    .pr-md-20, .px-md-20 {
        padding-right: 10.5rem;
    }
    .pr-md-21, .px-md-21 {
        padding-right: 11rem;
    }
    .pr-md-22, .px-md-22 {
        padding-right: 11.5rem;
    }
    .pr-md-23, .px-md-23 {
        padding-right: 12rem;
    }
    .pr-md-24, .px-md-24 {
        padding-right: 12.5rem;
    }

}

@media screen and (min-width: 992px) {

    /* width */
    .w-lg-1px {width:1px;}
    .w-lg-2px {width:2px;}
    .w-lg-3px {width:3px;}
    .w-lg-4px {width:4px;}
    .w-lg-5px {width:5px;}
    .w-lg-6px {width:6px;}
    .w-lg-7px {width:7px;}
    .w-lg-8px {width:8px;}
    .w-lg-9px {width:9px;}
    .w-lg-10px {width:10px;}
    .w-lg-15px {width:15px;}
    .w-lg-20px {width:20px;}
    .w-lg-25px {width:25px;}
    .w-lg-30px {width:30px;}
    .w-lg-35px {width:35px;}
    .w-lg-40px {width:40px;}
    .w-lg-50px {width:50px;}
    .w-lg-55px {width:55px;}
    .w-lg-60px {width:60px;}
    .w-lg-65px {width:65px;}
    .w-lg-70px {width:70px;}
    .w-lg-75px {width:75px;}
    .w-lg-80px {width:80px;}
    .w-lg-85px {width:85px;}
    .w-lg-90px {width:90px;}
    .w-lg-100px {width:100px;}

    .w-lg-10 {width:10%;}
    .w-lg-12 {width:12%;}
    .w-lg-15 {width:15%;}
    .w-lg-20 {width:20%;}
    .w-lg-25 {width:25%;}
    .w-lg-30 {width:30%;}
    .w-lg-35 {width:35%;}
    .w-lg-40 {width:40%;}
    .w-lg-45 {width:45%;}
    .w-lg-50 {width:50%;}
    .w-lg-55 {width:55%;}
    .w-lg-60 {width:60%;}
    .w-lg-65 {width:65%;}
    .w-lg-70 {width:70%;}
    .w-lg-75 {width:75%;}
    .w-lg-80 {width:80%;}
    .w-lg-85 {width:85%;}
    .w-lg-90 {width:90%;}
    .w-lg-95 {width:95%;}
    .w-lg-100 {width:100%;}
    .w-lg-auto {width: auto;}

    /* font size */
    .display-lg-1 {font-size:6rem;}
    .display-lg-2 {font-size:5.5rem;}
    .display-lg-3 {font-size:4.5rem;}
    .display-lg-4 {font-size:3.5rem;}
    .display-lg-5 {font-size:3.4rem;}
    .display-lg-6 {font-size:3.3rem;}
    .display-lg-7 {font-size:3.2rem;}
    .display-lg-8 {font-size:3.1rem;}
    .display-lg-9 {font-size:3.0rem;}
    .display-lg-10 {font-size:2.9rem;}
    .display-lg-11 {font-size:2.8rem;}
    .display-lg-12 {font-size:2.7rem;}
    .display-lg-13 {font-size:2.6rem;}
    .display-lg-14 {font-size:2.5rem;}
    .display-lg-15 {font-size:2.4rem;}
    .display-lg-16 {font-size:2.3rem;}
    .display-lg-17 {font-size:2.2rem;}
    .display-lg-18 {font-size:2.1rem;}
    .display-lg-19 {font-size:2.0rem;}
    .display-lg-20 {font-size:1.9rem;}
    .display-lg-21 {font-size:1.8rem;}
    .display-lg-22 {font-size:1.7rem;}
    .display-lg-23 {font-size:1.6rem;}
    .display-lg-24 {font-size:1.5rem;}
    .display-lg-25 {font-size:1.4rem;}
    .display-lg-26 {font-size:1.3rem;}
    .display-lg-27 {font-size:1.2rem;}
    .display-lg-28 {font-size:1.1rem;}
    .display-lg-29 {font-size:1.0rem;}
    .display-lg-30 {font-size:0.9rem;}
    .display-lg-31 {font-size:0.8rem;}
    .display-lg-32 {font-size:0.7rem;}
    .display-lg-33 {font-size:0.6rem;}
    .display-lg-34 {font-size:0.5rem;}

    /* margin */

    /* margin all */
    .m-lg-1-6 {
        margin: 1.6rem;
    }
    .m-lg-1-7 {
        margin: 1.7rem;
    }
    .m-lg-1-8 {
        margin: 1.8rem;
    }
    .m-lg-1-9 {
        margin: 1.9rem;
    }
    .m-lg-2-0 {
        margin: 2.0rem;
    }
    .m-lg-2-1 {
        margin: 2.1rem;
    }
    .m-lg-2-2 {
        margin: 2.2rem;
    }
    .m-lg-2-3 {
        margin: 2.3rem;
    }
    .m-lg-2-4 {
        margin: 2.4rem;
    }
    .m-lg-2-5 {
        margin: 2.5rem;
    }
    .m-lg-2-6 {
        margin: 2.6rem;
    }
    .m-lg-2-7 {
        margin: 2.7rem;
    }
    .m-lg-2-8 {
        margin: 2.8rem;
    }
    .m-lg-2-9 {
        margin: 2.9rem;
    }

    .m-lg-6 {
        margin: 3.5rem;
    }
    .m-lg-7 {
        margin: 4rem;
    }
    .m-lg-8 {
        margin: 4.5rem;
    }
    .m-lg-9 {
        margin: 5rem;
    }
    .m-lg-10 {
        margin: 5.5rem;
    }
    .m-lg-11 {
        margin: 6rem;
    }
    .m-lg-12 {
        margin: 6.5rem;
    }
    .m-lg-13 {
        margin: 7rem;
    }
    .m-lg-14 {
        margin: 7.5rem;
    }
    .m-lg-15 {
        margin: 8rem;
    }
    .m-lg-16 {
        margin: 8.5rem;
    }
    .m-lg-17 {
        margin: 9rem;
    }
    .m-lg-18 {
        margin: 9.5rem;
    }
    .m-lg-19 {
        margin: 10rem;
    }
    .m-lg-20 {
        margin: 10.5rem;
    }
    .m-lg-21 {
        margin: 11rem;
    }
    .m-lg-22 {
        margin: 11.5rem;
    }
    .m-lg-23 {
        margin: 12rem;
    }
    .m-lg-24 {
        margin: 12.5rem;
    }

    /* margin top */
    .mt-lg-1-6, .my-lg-1-6 {
        margin-top: 1.6rem;
    }
    .mt-lg-1-7, .my-lg-1-7 {
        margin-top: 1.7rem;
    }
    .mt-lg-1-8, .my-lg-1-8 {
        margin-top: 1.8rem;
    }
    .mt-lg-1-9, .my-lg-1-9 {
        margin-top: 1.9rem;
    }
    .mt-lg-2-0, .my-lg-2-0 {
        margin-top: 2.0rem;
    }
    .mt-lg-2-1, .my-lg-2-1 {
        margin-top: 2.1rem;
    }
    .mt-lg-2-2, .my-lg-2-2 {
        margin-top: 2.2rem;
    }
    .mt-lg-2-3, .my-lg-2-3 {
        margin-top: 2.3rem;
    }
    .mt-lg-2-4, .my-lg-2-4 {
        margin-top: 2.4rem;
    }
    .mt-lg-2-5, .my-lg-2-5 {
        margin-top: 2.5rem;
    }
    .mt-lg-2-6, .my-lg-2-6 {
        margin-top: 2.6rem;
    }
    .mt-lg-2-7, .my-lg-2-7 {
        margin-top: 2.7rem;
    }
    .mt-lg-2-8, .my-lg-2-8 {
        margin-top: 2.8rem;
    }
    .mt-lg-2-9, .my-lg-2-9 {
        margin-top: 2.9rem;
    }

    .mt-lg-6, .my-lg-6 {
        margin-top: 3.5rem;
    }
    .mt-lg-7, .my-lg-7 {
        margin-top: 4rem;
    }
    .mt-lg-8, .my-lg-8 {
        margin-top: 4.5rem;
    }
    .mt-lg-9, .my-lg-9 {
        margin-top: 5rem;
    }
    .mt-lg-10, .my-lg-10 {
        margin-top: 5.5rem;
    }
    .mt-lg-11, .my-lg-11 {
        margin-top: 6rem;
    }
    .mt-lg-12, .my-lg-12 {
        margin-top: 6.5rem;
    }
    .mt-lg-13, .my-lg-13 {
        margin-top: 7rem;
    }
    .mt-lg-14, .my-lg-14 {
        margin-top: 7.5rem;
    }
    .mt-lg-15, .my-lg-15 {
        margin-top: 8rem;
    }
    .mt-lg-16, .my-lg-16 {
        margin-top: 8.5rem;
    }
    .mt-lg-17, .my-lg-17 {
        margin-top: 9rem;
    }
    .mt-lg-18, .my-lg-18 {
        margin-top: 9.5rem;
    }
    .mt-lg-19, .my-lg-19 {
        margin-top: 10rem;
    }
    .mt-lg-20, .my-lg-20 {
        margin-top: 10.5rem;
    }
    .mt-lg-21, .my-lg-21 {
        margin-top: 11rem;
    }
    .mt-lg-22, .my-lg-22 {
        margin-top: 11.5rem;
    }
    .mt-lg-23, .my-lg-23 {
        margin-top: 12rem;
    }
    .mt-lg-24, .my-lg-24 {
        margin-top: 12.5rem;
    }

    /* margin bottom */
    .mb-lg-1-6, .my-lg-1-6 {
        margin-bottom: 1.6rem;
    }
    .mb-lg-1-7, .my-lg-1-7 {
        margin-bottom: 1.7rem;
    }
    .mb-lg-1-8, .my-lg-1-8 {
        margin-bottom: 1.8rem;
    }
    .mb-lg-1-9, .my-lg-1-9 {
        margin-bottom: 1.9rem;
    }
    .mb-lg-2-0, .my-lg-2-0 {
        margin-bottom: 2.0rem;
    }
    .mb-lg-2-1, .my-lg-2-1 {
        margin-bottom: 2.1rem;
    }
    .mb-lg-2-2, .my-lg-2-2 {
        margin-bottom: 2.2rem;
    }
    .mb-lg-2-3, .my-lg-2-3 {
        margin-bottom: 2.3rem;
    }
    .mb-lg-2-4, .my-lg-2-4 {
        margin-bottom: 2.4rem;
    }
    .mb-lg-2-5, .my-lg-2-5 {
        margin-bottom: 2.5rem;
    }
    .mb-lg-2-6, .my-lg-2-6 {
        margin-bottom: 2.6rem;
    }
    .mb-lg-2-7, .my-lg-2-7 {
        margin-bottom: 2.7rem;
    }
    .mb-lg-2-8, .my-lg-2-8 {
        margin-bottom: 2.8rem;
    }
    .mb-lg-2-9, .my-lg-2-9 {
        margin-bottom: 2.9rem;
    }

    .mb-lg-6, .my-lg-6 {
        margin-bottom: 3.5rem;
    }
    .mb-lg-7, .my-lg-7 {
        margin-bottom: 4rem;
    }
    .mb-lg-8, .my-lg-8 {
        margin-bottom: 4.5rem;
    }
    .mb-lg-9, .my-lg-9 {
        margin-bottom: 5rem;
    }
    .mb-lg-10, .my-lg-10 {
        margin-bottom: 5.5rem;
    }
    .mb-lg-11, .my-lg-11 {
        margin-bottom: 6rem;
    }
    .mb-lg-12, .my-lg-12 {
        margin-bottom: 6.5rem;
    }
    .mb-lg-13, .my-lg-13 {
        margin-bottom: 7rem;
    }
    .mb-lg-14, .my-lg-14 {
        margin-bottom: 7.5rem;
    }
    .mb-lg-15, .my-lg-15 {
        margin-bottom: 8rem;
    }
    .mb-lg-16, .my-lg-16 {
        margin-bottom: 8.5rem;
    }
    .mb-lg-17, .my-lg-17 {
        margin-bottom: 9rem;
    }
    .mb-lg-18, .my-lg-18 {
        margin-bottom: 9.5rem;
    }
    .mb-lg-19, .my-lg-19 {
        margin-bottom: 10rem;
    }
    .mb-lg-20, .my-lg-20 {
        margin-bottom: 10.5rem;
    }
    .mb-lg-21, .my-lg-21 {
        margin-bottom: 11rem;
    }
    .mb-lg-22, .my-lg-22 {
        margin-bottom: 11.5rem;
    }
    .mb-lg-23, .my-lg-23 {
        margin-bottom: 12rem;
    }
    .mb-lg-24, .my-lg-24 {
        margin-bottom: 12.5rem;
    }

    /* margin left */
    .ml-lg-1-6, .mx-lg-1-6 {
        margin-left: 1.6rem;
    }
    .ml-lg-1-7, .mx-lg-1-7 {
        margin-left: 1.7rem;
    }
    .ml-lg-1-8, .mx-lg-1-8 {
        margin-left: 1.8rem;
    }
    .ml-lg-1-9, .mx-lg-1-9 {
        margin-left: 1.9rem;
    }
    .ml-lg-2-0, .mx-lg-2-0 {
        margin-left: 2.0rem;
    }
    .ml-lg-2-1, .mx-lg-2-1 {
        margin-left: 2.1rem;
    }
    .ml-lg-2-2, .mx-lg-2-2 {
        margin-left: 2.2rem;
    }
    .ml-lg-2-3, .mx-lg-2-3 {
        margin-left: 2.3rem;
    }
    .ml-lg-2-4, .mx-lg-2-4 {
        margin-left: 2.4rem;
    }
    .ml-lg-2-5, .mx-lg-2-5 {
        margin-left: 2.5rem;
    }
    .ml-lg-2-6, .mx-lg-2-6 {
        margin-left: 2.6rem;
    }
    .ml-lg-2-7, .mx-lg-2-7 {
        margin-left: 2.7rem;
    }
    .ml-lg-2-8, .mx-lg-2-8 {
        margin-left: 2.8rem;
    }
    .ml-lg-2-9, .mx-lg-2-9 {
        margin-left: 2.9rem;
    }

    .ml-lg-6, .mx-lg-6 {
        margin-left: 3.5rem;
    }
    .ml-lg-7, .mx-lg-7 {
        margin-left: 4rem;
    }
    .ml-lg-8, .mx-lg-8 {
        margin-left: 4.5rem;
    }
    .ml-lg-9, .mx-lg-9 {
        margin-left: 5rem;
    }
    .ml-lg-10, .mx-lg-10 {
        margin-left: 5.5rem;
    }
    .ml-lg-11, .mx-lg-11 {
        margin-left: 6rem;
    }
    .ml-lg-12, .mx-lg-12 {
        margin-left: 6.5rem;
    }
    .ml-lg-13, .mx-lg-13 {
        margin-left: 7rem;
    }
    .ml-lg-14, .mx-lg-14 {
        margin-left: 7.5rem;
    }
    .ml-lg-15, .mx-lg-15 {
        margin-left: 8rem;
    }
    .ml-lg-16, .mx-lg-16 {
        margin-left: 8.5rem;
    }
    .ml-lg-17, .mx-lg-17 {
        margin-left: 9rem;
    }
    .ml-lg-18, .mx-lg-18 {
        margin-left: 9.5rem;
    }
    .ml-lg-19, .mx-lg-19 {
        margin-left: 10rem;
    }
    .ml-lg-20, .mx-lg-20 {
        margin-left: 10.5rem;
    }
    .ml-lg-21, .mx-lg-21 {
        margin-left: 11rem;
    }
    .ml-lg-22, .mx-lg-22 {
        margin-left: 11.5rem;
    }
    .ml-lg-23, .mx-lg-23 {
        margin-left: 12rem;
    }
    .ml-lg-24, .mx-lg-24 {
        margin-left: 12.5rem;
    }

    /* margin right */
    .mr-lg-1-6, .mx-lg-1-6 {
        margin-right: 1.6rem;
    }
    .mr-lg-1-7, .mx-lg-1-7 {
        margin-right: 1.7rem;
    }
    .mr-lg-1-8, .mx-lg-1-8 {
        margin-right: 1.8rem;
    }
    .mr-lg-1-9, .mx-lg-1-9 {
        margin-right: 1.9rem;
    }
    .mr-lg-2-0, .mx-lg-2-0 {
        margin-right: 2.0rem;
    }
    .mr-lg-2-1, .mx-lg-2-1 {
        margin-right: 2.1rem;
    }
    .mr-lg-2-2, .mx-lg-2-2 {
        margin-right: 2.2rem;
    }
    .mr-lg-2-3, .mx-lg-2-3 {
        margin-right: 2.3rem;
    }
    .mr-lg-2-4, .mx-lg-2-4 {
        margin-right: 2.4rem;
    }
    .mr-lg-2-5, .mx-lg-2-5 {
        margin-right: 2.5rem;
    }
    .mr-lg-2-6, .mx-lg-2-6 {
        margin-right: 2.6rem;
    }
    .mr-lg-2-7, .mx-lg-2-7 {
        margin-right: 2.7rem;
    }
    .mr-lg-2-8, .mx-lg-2-8 {
        margin-right: 2.8rem;
    }
    .mr-lg-2-9, .mx-lg-2-9 {
        margin-right: 2.9rem;
    }

    .mr-lg-6, .mx-lg-6 {
        margin-right: 3.5rem;
    }
    .mr-lg-7, .mx-lg-7 {
        margin-right: 4rem;
    }
    .mr-lg-8, .mx-lg-8 {
        margin-right: 4.5rem;
    }
    .mr-lg-9, .mx-lg-9 {
        margin-right: 5rem;
    }
    .mr-lg-10, .mx-lg-10 {
        margin-right: 5.5rem;
    }
    .mr-lg-11, .mx-lg-11 {
        margin-right: 6rem;
    }
    .mr-lg-12, .mx-lg-12 {
        margin-right: 6.5rem;
    }
    .mr-lg-13, .mx-lg-13 {
        margin-right: 7rem;
    }
    .mr-lg-14, .mx-lg-14 {
        margin-right: 7.5rem;
    }
    .mr-lg-15, .mx-lg-15 {
        margin-right: 8rem;
    }
    .mr-lg-16, .mx-lg-16 {
        margin-right: 8.5rem;
    }
    .mr-lg-17, .mx-lg-17 {
        margin-right: 9rem;
    }
    .mr-lg-18, .mx-lg-18 {
        margin-right: 9.5rem;
    }
    .mr-lg-19, .mx-lg-19 {
        margin-right: 10rem;
    }
    .mr-lg-20, .mx-lg-20 {
        margin-right: 10.5rem;
    }
    .mr-lg-21, .mx-lg-21 {
        margin-right: 11rem;
    }
    .mr-lg-22, .mx-lg-22 {
        margin-right: 11.5rem;
    }
    .mr-lg-23, .mx-lg-23 {
        margin-right: 12rem;
    }
    .mr-lg-24, .mx-lg-24 {
        margin-right: 12.5rem;
    }

    /* padding */

    /* padding all */
    .p-lg-1-6 {
        padding: 1.6rem;
    }
    .p-lg-1-7 {
        padding: 1.7rem;
    }
    .p-lg-1-8 {
        padding: 1.8rem;
    }
    .p-lg-1-9 {
        padding: 1.9rem;
    }
    .p-lg-2-0 {
        padding: 2.0rem;
    }
    .p-lg-2-1 {
        padding: 2.1rem;
    }
    .p-lg-2-2 {
        padding: 2.2rem;
    }
    .p-lg-2-3 {
        padding: 2.3rem;
    }
    .p-lg-2-4 {
        padding: 2.4rem;
    }
    .p-lg-2-5 {
        padding: 2.5rem;
    }
    .p-lg-2-6 {
        padding: 2.6rem;
    }
    .p-lg-2-7 {
        padding: 2.7rem;
    }
    .p-lg-2-8 {
        padding: 2.8rem;
    }
    .p-lg-2-9 {
        padding: 2.9rem;
    }

    .p-lg-6 {
        padding: 3.5rem;
    }
    .p-lg-7 {
        padding: 4rem;
    }
    .p-lg-8 {
        padding: 4.5rem;
    }
    .p-lg-9 {
        padding: 5rem;
    }
    .p-lg-10 {
        padding: 5.5rem;
    }
    .p-lg-11 {
        padding: 6rem;
    }
    .p-lg-12 {
        padding: 6.5rem;
    }
    .p-lg-13 {
        padding: 7rem;
    }
    .p-lg-14 {
        padding: 7.5rem;
    }
    .p-lg-15 {
        padding: 8rem;
    }
    .p-lg-16 {
        padding: 8.5rem;
    }
    .p-lg-17 {
        padding: 9rem;
    }
    .p-lg-18 {
        padding: 9.5rem;
    }
    .p-lg-19 {
        padding: 10rem;
    }
    .p-lg-20 {
        padding: 10.5rem;
    }
    .p-lg-21 {
        padding: 11rem;
    }
    .p-lg-22 {
        padding: 11.5rem;
    }
    .p-lg-23 {
        padding: 12rem;
    }
    .p-lg-24 {
        padding: 12.5rem;
    }

    /* padding top */
    .pt-lg-1-6, .py-lg-1-6 {
        padding-top: 1.6rem;
    }
    .pt-lg-1-7, .py-lg-1-7 {
        padding-top: 1.7rem;
    }
    .pt-lg-1-8, .py-lg-1-8 {
        padding-top: 1.8rem;
    }
    .pt-lg-1-9, .py-lg-1-9 {
        padding-top: 1.9rem;
    }
    .pt-lg-2-0, .py-lg-2-0 {
        padding-top: 2.0rem;
    }
    .pt-lg-2-1, .py-lg-2-1 {
        padding-top: 2.1rem;
    }
    .pt-lg-2-2, .py-lg-2-2 {
        padding-top: 2.2rem;
    }
    .pt-lg-2-3, .py-lg-2-3 {
        padding-top: 2.3rem;
    }
    .pt-lg-2-4, .py-lg-2-4 {
        padding-top: 2.4rem;
    }
    .pt-lg-2-5, .py-lg-2-5 {
        padding-top: 2.5rem;
    }
    .pt-lg-2-6, .py-lg-2-6 {
        padding-top: 2.6rem;
    }
    .pt-lg-2-7, .py-lg-2-7 {
        padding-top: 2.7rem;
    }
    .pt-lg-2-8, .py-lg-2-8 {
        padding-top: 2.8rem;
    }
    .pt-lg-2-9, .py-lg-2-9 {
        padding-top: 2.9rem;
    }

    .pt-lg-6, .py-lg-6 {
        padding-top: 3.5rem;
    }
    .pt-lg-7, .py-lg-7 {
        padding-top: 4rem;
    }
    .pt-lg-8, .py-lg-8 {
        padding-top: 4.5rem;
    }
    .pt-lg-9, .py-lg-9 {
        padding-top: 5rem;
    }
    .pt-lg-10, .py-lg-10 {
        padding-top: 5.5rem;
    }
    .pt-lg-11, .py-lg-11 {
        padding-top: 6rem;
    }
    .pt-lg-12, .py-lg-12 {
        padding-top: 6.5rem;
    }
    .pt-lg-13, .py-lg-13 {
        padding-top: 7rem;
    }
    .pt-lg-14, .py-lg-14 {
        padding-top: 7.5rem;
    }
    .pt-lg-15, .py-lg-15 {
        padding-top: 8rem;
    }
    .pt-lg-16, .py-lg-16 {
        padding-top: 8.5rem;
    }
    .pt-lg-17, .py-lg-17 {
        padding-top: 9rem;
    }
    .pt-lg-18, .py-lg-18 {
        padding-top: 9.5rem;
    }
    .pt-lg-19, .py-lg-19 {
        padding-top: 10rem;
    }
    .pt-lg-20, .py-lg-20 {
        padding-top: 10.5rem;
    }
    .pt-lg-21, .py-lg-21 {
        padding-top: 11rem;
    }
    .pt-lg-22, .py-lg-22 {
        padding-top: 11.5rem;
    }
    .pt-lg-23, .py-lg-23 {
        padding-top: 12rem;
    }
    .pt-lg-24, .py-lg-24 {
        padding-top: 12.5rem;
    }

    /* padding bottom */
    .pb-lg-1-6, .py-lg-1-6 {
        padding-bottom: 1.6rem;
    }
    .pb-lg-1-7, .py-lg-1-7 {
        padding-bottom: 1.7rem;
    }
    .pb-lg-1-8, .py-lg-1-8 {
        padding-bottom: 1.8rem;
    }
    .pb-lg-1-9, .py-lg-1-9 {
        padding-bottom: 1.9rem;
    }
    .pb-lg-2-0, .py-lg-2-0 {
        padding-bottom: 2.0rem;
    }
    .pb-lg-2-1, .py-lg-2-1 {
        padding-bottom: 2.1rem;
    }
    .pb-lg-2-2, .py-lg-2-2 {
        padding-bottom: 2.2rem;
    }
    .pb-lg-2-3, .py-lg-2-3 {
        padding-bottom: 2.3rem;
    }
    .pb-lg-2-4, .py-lg-2-4 {
        padding-bottom: 2.4rem;
    }
    .pb-lg-2-5, .py-lg-2-5 {
        padding-bottom: 2.5rem;
    }
    .pb-lg-2-6, .py-lg-2-6 {
        padding-bottom: 2.6rem;
    }
    .pb-lg-2-7, .py-lg-2-7 {
        padding-bottom: 2.7rem;
    }
    .pb-lg-2-8, .py-lg-2-8 {
        padding-bottom: 2.8rem;
    }
    .pb-lg-2-9, .py-lg-2-9 {
        padding-bottom: 2.9rem;
    }

    .pb-lg-6, .py-lg-6 {
        padding-bottom: 3.5rem;
    }
    .pb-lg-7, .py-lg-7 {
        padding-bottom: 4rem;
    }
    .pb-lg-8, .py-lg-8 {
        padding-bottom: 4.5rem;
    }
    .pb-lg-9, .py-lg-9 {
        padding-bottom: 5rem;
    }
    .pb-lg-10, .py-lg-10 {
        padding-bottom: 5.5rem;
    }
    .pb-lg-11, .py-lg-11 {
        padding-bottom: 6rem;
    }
    .pb-lg-12, .py-lg-12 {
        padding-bottom: 6.5rem;
    }
    .pb-lg-13, .py-lg-13 {
        padding-bottom: 7rem;
    }
    .pb-lg-14, .py-lg-14 {
        padding-bottom: 7.5rem;
    }
    .pb-lg-15, .py-lg-15 {
        padding-bottom: 8rem;
    }
    .pb-lg-16, .py-lg-16 {
        padding-bottom: 8.5rem;
    }
    .pb-lg-17, .py-lg-17 {
        padding-bottom: 9rem;
    }
    .pb-lg-18, .py-lg-18 {
        padding-bottom: 9.5rem;
    }
    .pb-lg-19, .py-lg-19 {
        padding-bottom: 10rem;
    }
    .pb-lg-20, .py-lg-20 {
        padding-bottom: 10.5rem;
    }
    .pb-lg-21, .py-lg-21 {
        padding-bottom: 11rem;
    }
    .pb-lg-22, .py-lg-22 {
        padding-bottom: 11.5rem;
    }
    .pb-lg-23, .py-lg-23 {
        padding-bottom: 12rem;
    }
    .pb-lg-24, .py-lg-24 {
        padding-bottom: 12.5rem;
    }

    /* padding left */
    .pl-lg-1-6, .px-lg-1-6 {
        padding-left: 1.6rem;
    }
    .pl-lg-1-7, .px-lg-1-7 {
        padding-left: 1.7rem;
    }
    .pl-lg-1-8, .px-lg-1-8 {
        padding-left: 1.8rem;
    }
    .pl-lg-1-9, .px-lg-1-9 {
        padding-left: 1.9rem;
    }
    .pl-lg-2-0, .px-lg-2-0 {
        padding-left: 2.0rem;
    }
    .pl-lg-2-1, .px-lg-2-1 {
        padding-left: 2.1rem;
    }
    .pl-lg-2-2, .px-lg-2-2 {
        padding-left: 2.2rem;
    }
    .pl-lg-2-3, .px-lg-2-3 {
        padding-left: 2.3rem;
    }
    .pl-lg-2-4, .px-lg-2-4 {
        padding-left: 2.4rem;
    }
    .pl-lg-2-5, .px-lg-2-5 {
        padding-left: 2.5rem;
    }
    .pl-lg-2-6, .px-lg-2-6 {
        padding-left: 2.6rem;
    }
    .pl-lg-2-7, .px-lg-2-7 {
        padding-left: 2.7rem;
    }
    .pl-lg-2-8, .px-lg-2-8 {
        padding-left: 2.8rem;
    }
    .pl-lg-2-9, .px-lg-2-9 {
        padding-left: 2.9rem;
    }

    .pl-lg-6, .px-lg-6 {
        padding-left: 3.5rem;
    }
    .pl-lg-7, .px-lg-7 {
        padding-left: 4rem;
    }
    .pl-lg-8, .px-lg-8 {
        padding-left: 4.5rem;
    }
    .pl-lg-9, .px-lg-9 {
        padding-left: 5rem;
    }
    .pl-lg-10, .px-lg-10 {
        padding-left: 5.5rem;
    }
    .pl-lg-11, .px-lg-11 {
        padding-left: 6rem;
    }
    .pl-lg-12, .px-lg-12 {
        padding-left: 6.5rem;
    }
    .pl-lg-13, .px-lg-13 {
        padding-left: 7rem;
    }
    .pl-lg-14, .px-lg-14 {
        padding-left: 7.5rem;
    }
    .pl-lg-15, .px-lg-15 {
        padding-left: 8rem;
    }
    .pl-lg-16, .px-lg-16 {
        padding-left: 8.5rem;
    }
    .pl-lg-17, .px-lg-17 {
        padding-left: 9rem;
    }
    .pl-lg-18, .px-lg-18 {
        padding-left: 9.5rem;
    }
    .pl-lg-19, .px-lg-19 {
        padding-left: 10rem;
    }
    .pl-lg-20, .px-lg-20 {
        padding-left: 10.5rem;
    }
    .pl-lg-21, .px-lg-21 {
        padding-left: 11rem;
    }
    .pl-lg-22, .px-lg-22 {
        padding-left: 11.5rem;
    }
    .pl-lg-23, .px-lg-23 {
        padding-left: 12rem;
    }
    .pl-lg-24, .px-lg-24 {
        padding-left: 12.5rem;
    }

    /* padding right */
    .pr-lg-1-6, .px-lg-1-6 {
        padding-right: 1.6rem;
    }
    .pr-lg-1-7, .px-lg-1-7 {
        padding-right: 1.7rem;
    }
    .pr-lg-1-8, .px-lg-1-8 {
        padding-right: 1.8rem;
    }
    .pr-lg-1-9, .px-lg-1-9 {
        padding-right: 1.9rem;
    }
    .pr-lg-2-0, .px-lg-2-0 {
        padding-right: 2.0rem;
    }
    .pr-lg-2-1, .px-lg-2-1 {
        padding-right: 2.1rem;
    }
    .pr-lg-2-2, .px-lg-2-2 {
        padding-right: 2.2rem;
    }
    .pr-lg-2-3, .px-lg-2-3 {
        padding-right: 2.3rem;
    }
    .pr-lg-2-4, .px-lg-2-4 {
        padding-right: 2.4rem;
    }
    .pr-lg-2-5, .px-lg-2-5 {
        padding-right: 2.5rem;
    }
    .pr-lg-2-6, .px-lg-2-6 {
        padding-right: 2.6rem;
    }
    .pr-lg-2-7, .px-lg-2-7 {
        padding-right: 2.7rem;
    }
    .pr-lg-2-8, .px-lg-2-8 {
        padding-right: 2.8rem;
    }
    .pr-lg-2-9, .px-lg-2-9 {
        padding-right: 2.9rem;
    }

    .pr-lg-6, .px-lg-6 {
        padding-right: 3.5rem;
    }
    .pr-lg-7, .px-lg-7 {
        padding-right: 4rem;
    }
    .pr-lg-8, .px-lg-8 {
        padding-right: 4.5rem;
    }
    .pr-lg-9, .px-lg-9 {
        padding-right: 5rem;
    }
    .pr-lg-10, .px-lg-10 {
        padding-right: 5.5rem;
    }
    .pr-lg-11, .px-lg-11 {
        padding-right: 6rem;
    }
    .pr-lg-12, .px-lg-12 {
        padding-right: 6.5rem;
    }
    .pr-lg-13, .px-lg-13 {
        padding-right: 7rem;
    }
    .pr-lg-14, .px-lg-14 {
        padding-right: 7.5rem;
    }
    .pr-lg-15, .px-lg-15 {
        padding-right: 8rem;
    }
    .pr-lg-16, .px-lg-16 {
        padding-right: 8.5rem;
    }
    .pr-lg-17, .px-lg-17 {
        padding-right: 9rem;
    }
    .pr-lg-18, .px-lg-18 {
        padding-right: 9.5rem;
    }
    .pr-lg-19, .px-lg-19 {
        padding-right: 10rem;
    }
    .pr-lg-20, .px-lg-20 {
        padding-right: 10.5rem;
    }
    .pr-lg-21, .px-lg-21 {
        padding-right: 11rem;
    }
    .pr-lg-22, .px-lg-22 {
        padding-right: 11.5rem;
    }
    .pr-lg-23, .px-lg-23 {
        padding-right: 12rem;
    }
    .pr-lg-24, .px-lg-24 {
        padding-right: 12.5rem;
    }

}

@media screen and (min-width: 1200px) {

    /* width */
    .w-xl-1px {width:1px;}
    .w-xl-2px {width:2px;}
    .w-xl-3px {width:3px;}
    .w-xl-4px {width:4px;}
    .w-xl-5px {width:5px;}
    .w-xl-6px {width:6px;}
    .w-xl-7px {width:7px;}
    .w-xl-8px {width:8px;}
    .w-xl-9px {width:9px;}
    .w-xl-10px {width:10px;}
    .w-xl-15px {width:15px;}
    .w-xl-20px {width:20px;}
    .w-xl-25px {width:25px;}
    .w-xl-30px {width:30px;}
    .w-xl-35px {width:35px;}
    .w-xl-40px {width:40px;}
    .w-xl-50px {width:50px;}
    .w-xl-55px {width:55px;}
    .w-xl-60px {width:60px;}
    .w-xl-65px {width:65px;}
    .w-xl-70px {width:70px;}
    .w-xl-75px {width:75px;}
    .w-xl-80px {width:80px;}
    .w-xl-85px {width:85px;}
    .w-xl-90px {width:90px;}
    .w-xl-100px {width:100px;}

    .w-xl-10 {width:10%;}
    .w-xl-12 {width:12%;}
    .w-xl-15 {width:15%;}
    .w-xl-20 {width:20%;}
    .w-xl-25 {width:25%;}
    .w-xl-30 {width:30%;}
    .w-xl-35 {width:35%;}
    .w-xl-40 {width:40%;}
    .w-xl-45 {width:45%;}
    .w-xl-50 {width:50%;}
    .w-xl-55 {width:55%;}
    .w-xl-60 {width:60%;}
    .w-xl-65 {width:65%;}
    .w-xl-70 {width:70%;}
    .w-xl-75 {width:75%;}
    .w-xl-80 {width:80%;}
    .w-xl-85 {width:85%;}
    .w-xl-90 {width:90%;}
    .w-xl-95 {width:95%;}
    .w-xl-100 {width:100%;}
    .w-xl-auto {width: auto;}

    /* font size */
    .display-xl-1 {font-size:6rem;}
    .display-xl-2 {font-size:5.5rem;}
    .display-xl-3 {font-size:4.5rem;}
    .display-xl-4 {font-size:3.5rem;}
    .display-xl-5 {font-size:3.4rem;}
    .display-xl-6 {font-size:3.3rem;}
    .display-xl-7 {font-size:3.2rem;}
    .display-xl-8 {font-size:3.1rem;}
    .display-xl-9 {font-size:3.0rem;}
    .display-xl-10 {font-size:2.9rem;}
    .display-xl-11 {font-size:2.8rem;}
    .display-xl-12 {font-size:2.7rem;}
    .display-xl-13 {font-size:2.6rem;}
    .display-xl-14 {font-size:2.5rem;}
    .display-xl-15 {font-size:2.4rem;}
    .display-xl-16 {font-size:2.3rem;}
    .display-xl-17 {font-size:2.2rem;}
    .display-xl-18 {font-size:2.1rem;}
    .display-xl-19 {font-size:2.0rem;}
    .display-xl-20 {font-size:1.9rem;}
    .display-xl-21 {font-size:1.8rem;}
    .display-xl-22 {font-size:1.7rem;}
    .display-xl-23 {font-size:1.6rem;}
    .display-xl-24 {font-size:1.5rem;}
    .display-xl-25 {font-size:1.4rem;}
    .display-xl-26 {font-size:1.3rem;}
    .display-xl-27 {font-size:1.2rem;}
    .display-xl-28 {font-size:1.1rem;}
    .display-xl-29 {font-size:1.0rem;}
    .display-xl-30 {font-size:0.9rem;}
    .display-xl-31 {font-size:0.8rem;}
    .display-xl-32 {font-size:0.7rem;}
    .display-xl-33 {font-size:0.6rem;}
    .display-xl-34 {font-size:0.5rem;}

    /* margin */

    /* margin all */
    .m-xl-1-6 {
        margin: 1.6rem;
    }
    .m-xl-1-7 {
        margin: 1.7rem;
    }
    .m-xl-1-8 {
        margin: 1.8rem;
    }
    .m-xl-1-9 {
        margin: 1.9rem;
    }
    .m-xl-2-0 {
        margin: 2.0rem;
    }
    .m-xl-2-1 {
        margin: 2.1rem;
    }
    .m-xl-2-2 {
        margin: 2.2rem;
    }
    .m-xl-2-3 {
        margin: 2.3rem;
    }
    .m-xl-2-4 {
        margin: 2.4rem;
    }
    .m-xl-2-5 {
        margin: 2.5rem;
    }
    .m-xl-2-6 {
        margin: 2.6rem;
    }
    .m-xl-2-7 {
        margin: 2.7rem;
    }
    .m-xl-2-8 {
        margin: 2.8rem;
    }
    .m-xl-2-9 {
        margin: 2.9rem;
    }

    .m-xl-6 {
        margin: 3.5rem;
    }
    .m-xl-7 {
        margin: 4rem;
    }
    .m-xl-8 {
        margin: 4.5rem;
    }
    .m-xl-9 {
        margin: 5rem;
    }
    .m-xl-10 {
        margin: 5.5rem;
    }
    .m-xl-11 {
        margin: 6rem;
    }
    .m-xl-12 {
        margin: 6.5rem;
    }
    .m-xl-13 {
        margin: 7rem;
    }
    .m-xl-14 {
        margin: 7.5rem;
    }
    .m-xl-15 {
        margin: 8rem;
    }
    .m-xl-16 {
        margin: 8.5rem;
    }
    .m-xl-17 {
        margin: 9rem;
    }
    .m-xl-18 {
        margin: 9.5rem;
    }
    .m-xl-19 {
        margin: 10rem;
    }
    .m-xl-20 {
        margin: 10.5rem;
    }
    .m-xl-21 {
        margin: 11rem;
    }
    .m-xl-22 {
        margin: 11.5rem;
    }
    .m-xl-23 {
        margin: 12rem;
    }
    .m-xl-24 {
        margin: 12.5rem;
    }

    /* margin top */
    .mt-xl-1-6, .my-xl-1-6 {
        margin-top: 1.6rem;
    }
    .mt-xl-1-7, .my-xl-1-7 {
        margin-top: 1.7rem;
    }
    .mt-xl-1-8, .my-xl-1-8 {
        margin-top: 1.8rem;
    }
    .mt-xl-1-9, .my-xl-1-9 {
        margin-top: 1.9rem;
    }
    .mt-xl-2-0, .my-xl-2-0 {
        margin-top: 2.0rem;
    }
    .mt-xl-2-1, .my-xl-2-1 {
        margin-top: 2.1rem;
    }
    .mt-xl-2-2, .my-xl-2-2 {
        margin-top: 2.2rem;
    }
    .mt-xl-2-3, .my-xl-2-3 {
        margin-top: 2.3rem;
    }
    .mt-xl-2-4, .my-xl-2-4 {
        margin-top: 2.4rem;
    }
    .mt-xl-2-5, .my-xl-2-5 {
        margin-top: 2.5rem;
    }
    .mt-xl-2-6, .my-xl-2-6 {
        margin-top: 2.6rem;
    }
    .mt-xl-2-7, .my-xl-2-7 {
        margin-top: 2.7rem;
    }
    .mt-xl-2-8, .my-xl-2-8 {
        margin-top: 2.8rem;
    }
    .mt-xl-2-9, .my-xl-2-9 {
        margin-top: 2.9rem;
    }

    .mt-xl-6, .my-xl-6 {
        margin-top: 3.5rem;
    }
    .mt-xl-7, .my-xl-7 {
        margin-top: 4rem;
    }
    .mt-xl-8, .my-xl-8 {
        margin-top: 4.5rem;
    }
    .mt-xl-9, .my-xl-9 {
        margin-top: 5rem;
    }
    .mt-xl-10, .my-xl-10 {
        margin-top: 5.5rem;
    }
    .mt-xl-11, .my-xl-11 {
        margin-top: 6rem;
    }
    .mt-xl-12, .my-xl-12 {
        margin-top: 6.5rem;
    }
    .mt-xl-13, .my-xl-13 {
        margin-top: 7rem;
    }
    .mt-xl-14, .my-xl-14 {
        margin-top: 7.5rem;
    }
    .mt-xl-15, .my-xl-15 {
        margin-top: 8rem;
    }
    .mt-xl-16, .my-xl-16 {
        margin-top: 8.5rem;
    }
    .mt-xl-17, .my-xl-17 {
        margin-top: 9rem;
    }
    .mt-xl-18, .my-xl-18 {
        margin-top: 9.5rem;
    }
    .mt-xl-19, .my-xl-19 {
        margin-top: 10rem;
    }
    .mt-xl-20, .my-xl-20 {
        margin-top: 10.5rem;
    }
    .mt-xl-21, .my-xl-21 {
        margin-top: 11rem;
    }
    .mt-xl-22, .my-xl-22 {
        margin-top: 11.5rem;
    }
    .mt-xl-23, .my-xl-23 {
        margin-top: 12rem;
    }
    .mt-xl-24, .my-xl-24 {
        margin-top: 12.5rem;
    }

    /* margin bottom */
    .mb-xl-1-6, .my-xl-1-6 {
        margin-bottom: 1.6rem;
    }
    .mb-xl-1-7, .my-xl-1-7 {
        margin-bottom: 1.7rem;
    }
    .mb-xl-1-8, .my-xl-1-8 {
        margin-bottom: 1.8rem;
    }
    .mb-xl-1-9, .my-xl-1-9 {
        margin-bottom: 1.9rem;
    }
    .mb-xl-2-0, .my-xl-2-0 {
        margin-bottom: 2.0rem;
    }
    .mb-xl-2-1, .my-xl-2-1 {
        margin-bottom: 2.1rem;
    }
    .mb-xl-2-2, .my-xl-2-2 {
        margin-bottom: 2.2rem;
    }
    .mb-xl-2-3, .my-xl-2-3 {
        margin-bottom: 2.3rem;
    }
    .mb-xl-2-4, .my-xl-2-4 {
        margin-bottom: 2.4rem;
    }
    .mb-xl-2-5, .my-xl-2-5 {
        margin-bottom: 2.5rem;
    }
    .mb-xl-2-6, .my-xl-2-6 {
        margin-bottom: 2.6rem;
    }
    .mb-xl-2-7, .my-xl-2-7 {
        margin-bottom: 2.7rem;
    }
    .mb-xl-2-8, .my-xl-2-8 {
        margin-bottom: 2.8rem;
    }
    .mb-xl-2-9, .my-xl-2-9 {
        margin-bottom: 2.9rem;
    }

    .mb-xl-6, .my-xl-6 {
        margin-bottom: 3.5rem;
    }
    .mb-xl-7, .my-xl-7 {
        margin-bottom: 4rem;
    }
    .mb-xl-8, .my-xl-8 {
        margin-bottom: 4.5rem;
    }
    .mb-xl-9, .my-xl-9 {
        margin-bottom: 5rem;
    }
    .mb-xl-10, .my-xl-10 {
        margin-bottom: 5.5rem;
    }
    .mb-xl-11, .my-xl-11 {
        margin-bottom: 6rem;
    }
    .mb-xl-12, .my-xl-12 {
        margin-bottom: 6.5rem;
    }
    .mb-xl-13, .my-xl-13 {
        margin-bottom: 7rem;
    }
    .mb-xl-14, .my-xl-14 {
        margin-bottom: 7.5rem;
    }
    .mb-xl-15, .my-xl-15 {
        margin-bottom: 8rem;
    }
    .mb-xl-16, .my-xl-16 {
        margin-bottom: 8.5rem;
    }
    .mb-xl-17, .my-xl-17 {
        margin-bottom: 9rem;
    }
    .mb-xl-18, .my-xl-18 {
        margin-bottom: 9.5rem;
    }
    .mb-xl-19, .my-xl-19 {
        margin-bottom: 10rem;
    }
    .mb-xl-20, .my-xl-20 {
        margin-bottom: 10.5rem;
    }
    .mb-xl-21, .my-xl-21 {
        margin-bottom: 11rem;
    }
    .mb-xl-22, .my-xl-22 {
        margin-bottom: 11.5rem;
    }
    .mb-xl-23, .my-xl-23 {
        margin-bottom: 12rem;
    }
    .mb-xl-24, .my-xl-24 {
        margin-bottom: 12.5rem;
    }

    /* margin left */
    .ml-xl-1-6, .mx-xl-1-6 {
        margin-left: 1.6rem;
    }
    .ml-xl-1-7, .mx-xl-1-7 {
        margin-left: 1.7rem;
    }
    .ml-xl-1-8, .mx-xl-1-8 {
        margin-left: 1.8rem;
    }
    .ml-xl-1-9, .mx-xl-1-9 {
        margin-left: 1.9rem;
    }
    .ml-xl-2-0, .mx-xl-2-0 {
        margin-left: 2.0rem;
    }
    .ml-xl-2-1, .mx-xl-2-1 {
        margin-left: 2.1rem;
    }
    .ml-xl-2-2, .mx-xl-2-2 {
        margin-left: 2.2rem;
    }
    .ml-xl-2-3, .mx-xl-2-3 {
        margin-left: 2.3rem;
    }
    .ml-xl-2-4, .mx-xl-2-4 {
        margin-left: 2.4rem;
    }
    .ml-xl-2-5, .mx-xl-2-5 {
        margin-left: 2.5rem;
    }
    .ml-xl-2-6, .mx-xl-2-6 {
        margin-left: 2.6rem;
    }
    .ml-xl-2-7, .mx-xl-2-7 {
        margin-left: 2.7rem;
    }
    .ml-xl-2-8, .mx-xl-2-8 {
        margin-left: 2.8rem;
    }
    .ml-xl-2-9, .mx-xl-2-9 {
        margin-left: 2.9rem;
    }

    .ml-xl-6, .mx-xl-6 {
        margin-left: 3.5rem;
    }
    .ml-xl-7, .mx-xl-7 {
        margin-left: 4rem;
    }
    .ml-xl-8, .mx-xl-8 {
        margin-left: 4.5rem;
    }
    .ml-xl-9, .mx-xl-9 {
        margin-left: 5rem;
    }
    .ml-xl-10, .mx-xl-10 {
        margin-left: 5.5rem;
    }
    .ml-xl-11, .mx-xl-11 {
        margin-left: 6rem;
    }
    .ml-xl-12, .mx-xl-12 {
        margin-left: 6.5rem;
    }
    .ml-xl-13, .mx-xl-13 {
        margin-left: 7rem;
    }
    .ml-xl-14, .mx-xl-14 {
        margin-left: 7.5rem;
    }
    .ml-xl-15, .mx-xl-15 {
        margin-left: 8rem;
    }
    .ml-xl-16, .mx-xl-16 {
        margin-left: 8.5rem;
    }
    .ml-xl-17, .mx-xl-17 {
        margin-left: 9rem;
    }
    .ml-xl-18, .mx-xl-18 {
        margin-left: 9.5rem;
    }
    .ml-xl-19, .mx-xl-19 {
        margin-left: 10rem;
    }
    .ml-xl-20, .mx-xl-20 {
        margin-left: 10.5rem;
    }
    .ml-xl-21, .mx-xl-21 {
        margin-left: 11rem;
    }
    .ml-xl-22, .mx-xl-22 {
        margin-left: 11.5rem;
    }
    .ml-xl-23, .mx-xl-23 {
        margin-left: 12rem;
    }
    .ml-xl-24, .mx-xl-24 {
        margin-left: 12.5rem;
    }

    /* margin right */
    .mr-xl-1-6, .mx-xl-1-6 {
        margin-right: 1.6rem;
    }
    .mr-xl-1-7, .mx-xl-1-7 {
        margin-right: 1.7rem;
    }
    .mr-xl-1-8, .mx-xl-1-8 {
        margin-right: 1.8rem;
    }
    .mr-xl-1-9, .mx-xl-1-9 {
        margin-right: 1.9rem;
    }
    .mr-xl-2-0, .mx-xl-2-0 {
        margin-right: 2.0rem;
    }
    .mr-xl-2-1, .mx-xl-2-1 {
        margin-right: 2.1rem;
    }
    .mr-xl-2-2, .mx-xl-2-2 {
        margin-right: 2.2rem;
    }
    .mr-xl-2-3, .mx-xl-2-3 {
        margin-right: 2.3rem;
    }
    .mr-xl-2-4, .mx-xl-2-4 {
        margin-right: 2.4rem;
    }
    .mr-xl-2-5, .mx-xl-2-5 {
        margin-right: 2.5rem;
    }
    .mr-xl-2-6, .mx-xl-2-6 {
        margin-right: 2.6rem;
    }
    .mr-xl-2-7, .mx-xl-2-7 {
        margin-right: 2.7rem;
    }
    .mr-xl-2-8, .mx-xl-2-8 {
        margin-right: 2.8rem;
    }
    .mr-xl-2-9, .mx-xl-2-9 {
        margin-right: 2.9rem;
    }

    .mr-xl-6, .mx-xl-6 {
        margin-right: 3.5rem;
    }
    .mr-xl-7, .mx-xl-7 {
        margin-right: 4rem;
    }
    .mr-xl-8, .mx-xl-8 {
        margin-right: 4.5rem;
    }
    .mr-xl-9, .mx-xl-9 {
        margin-right: 5rem;
    }
    .mr-xl-10, .mx-xl-10 {
        margin-right: 5.5rem;
    }
    .mr-xl-11, .mx-xl-11 {
        margin-right: 6rem;
    }
    .mr-xl-12, .mx-xl-12 {
        margin-right: 6.5rem;
    }
    .mr-xl-13, .mx-xl-13 {
        margin-right: 7rem;
    }
    .mr-xl-14, .mx-xl-14 {
        margin-right: 7.5rem;
    }
    .mr-xl-15, .mx-xl-15 {
        margin-right: 8rem;
    }
    .mr-xl-16, .mx-xl-16 {
        margin-right: 8.5rem;
    }
    .mr-xl-17, .mx-xl-17 {
        margin-right: 9rem;
    }
    .mr-xl-18, .mx-xl-18 {
        margin-right: 9.5rem;
    }
    .mr-xl-19, .mx-xl-19 {
        margin-right: 10rem;
    }
    .mr-xl-20, .mx-xl-20 {
        margin-right: 10.5rem;
    }
    .mr-xl-21, .mx-xl-21 {
        margin-right: 11rem;
    }
    .mr-xl-22, .mx-xl-22 {
        margin-right: 11.5rem;
    }
    .mr-xl-23, .mx-xl-23 {
        margin-right: 12rem;
    }
    .mr-xl-24, .mx-xl-24 {
        margin-right: 12.5rem;
    }

    /* padding */

    /* padding all */
    .p-xl-1-6 {
        padding: 1.6rem;
    }
    .p-xl-1-7 {
        padding: 1.7rem;
    }
    .p-xl-1-8 {
        padding: 1.8rem;
    }
    .p-xl-1-9 {
        padding: 1.9rem;
    }
    .p-xl-2-0 {
        padding: 2.0rem;
    }
    .p-xl-2-1 {
        padding: 2.1rem;
    }
    .p-xl-2-2 {
        padding: 2.2rem;
    }
    .p-xl-2-3 {
        padding: 2.3rem;
    }
    .p-xl-2-4 {
        padding: 2.4rem;
    }
    .p-xl-2-5 {
        padding: 2.5rem;
    }
    .p-xl-2-6 {
        padding: 2.6rem;
    }
    .p-xl-2-7 {
        padding: 2.7rem;
    }
    .p-xl-2-8 {
        padding: 2.8rem;
    }
    .p-xl-2-9 {
        padding: 2.9rem;
    }

    .p-xl-6 {
        padding: 3.5rem;
    }
    .p-xl-7 {
        padding: 4rem;
    }
    .p-xl-8 {
        padding: 4.5rem;
    }
    .p-xl-9 {
        padding: 5rem;
    }
    .p-xl-10 {
        padding: 5.5rem;
    }
    .p-xl-11 {
        padding: 6rem;
    }
    .p-xl-12 {
        padding: 6.5rem;
    }
    .p-xl-13 {
        padding: 7rem;
    }
    .p-xl-14 {
        padding: 7.5rem;
    }
    .p-xl-15 {
        padding: 8rem;
    }
    .p-xl-16 {
        padding: 8.5rem;
    }
    .p-xl-17 {
        padding: 9rem;
    }
    .p-xl-18 {
        padding: 9.5rem;
    }
    .p-xl-19 {
        padding: 10rem;
    }
    .p-xl-20 {
        padding: 10.5rem;
    }
    .p-xl-21 {
        padding: 11rem;
    }
    .p-xl-22 {
        padding: 11.5rem;
    }
    .p-xl-23 {
        padding: 12rem;
    }
    .p-xl-24 {
        padding: 12.5rem;
    }

    /* padding top */
    .pt-xl-1-6, .py-xl-1-6 {
        padding-top: 1.6rem;
    }
    .pt-xl-1-7, .py-xl-1-7 {
        padding-top: 1.7rem;
    }
    .pt-xl-1-8, .py-xl-1-8 {
        padding-top: 1.8rem;
    }
    .pt-xl-1-9, .py-xl-1-9 {
        padding-top: 1.9rem;
    }
    .pt-xl-2-0, .py-xl-2-0 {
        padding-top: 2.0rem;
    }
    .pt-xl-2-1, .py-xl-2-1 {
        padding-top: 2.1rem;
    }
    .pt-xl-2-2, .py-xl-2-2 {
        padding-top: 2.2rem;
    }
    .pt-xl-2-3, .py-xl-2-3 {
        padding-top: 2.3rem;
    }
    .pt-xl-2-4, .py-xl-2-4 {
        padding-top: 2.4rem;
    }
    .pt-xl-2-5, .py-xl-2-5 {
        padding-top: 2.5rem;
    }
    .pt-xl-2-6, .py-xl-2-6 {
        padding-top: 2.6rem;
    }
    .pt-xl-2-7, .py-xl-2-7 {
        padding-top: 2.7rem;
    }
    .pt-xl-2-8, .py-xl-2-8 {
        padding-top: 2.8rem;
    }
    .pt-xl-2-9, .py-xl-2-9 {
        padding-top: 2.9rem;
    }

    .pt-xl-6, .py-xl-6 {
        padding-top: 3.5rem;
    }
    .pt-xl-7, .py-xl-7 {
        padding-top: 4rem;
    }
    .pt-xl-8, .py-xl-8 {
        padding-top: 4.5rem;
    }
    .pt-xl-9, .py-xl-9 {
        padding-top: 5rem;
    }
    .pt-xl-10, .py-xl-10 {
        padding-top: 5.5rem;
    }
    .pt-xl-11, .py-xl-11 {
        padding-top: 6rem;
    }
    .pt-xl-12, .py-xl-12 {
        padding-top: 6.5rem;
    }
    .pt-xl-13, .py-xl-13 {
        padding-top: 7rem;
    }
    .pt-xl-14, .py-xl-14 {
        padding-top: 7.5rem;
    }
    .pt-xl-15, .py-xl-15 {
        padding-top: 8rem;
    }
    .pt-xl-16, .py-xl-16 {
        padding-top: 8.5rem;
    }
    .pt-xl-17, .py-xl-17 {
        padding-top: 9rem;
    }
    .pt-xl-18, .py-xl-18 {
        padding-top: 9.5rem;
    }
    .pt-xl-19, .py-xl-19 {
        padding-top: 10rem;
    }
    .pt-xl-20, .py-xl-20 {
        padding-top: 10.5rem;
    }
    .pt-xl-21, .py-xl-21 {
        padding-top: 11rem;
    }
    .pt-xl-22, .py-xl-22 {
        padding-top: 11.5rem;
    }
    .pt-xl-23, .py-xl-23 {
        padding-top: 12rem;
    }
    .pt-xl-24, .py-xl-24 {
        padding-top: 12.5rem;
    }

    /* padding bottom */
    .pb-xl-1-6, .py-xl-1-6 {
        padding-bottom: 1.6rem;
    }
    .pb-xl-1-7, .py-xl-1-7 {
        padding-bottom: 1.7rem;
    }
    .pb-xl-1-8, .py-xl-1-8 {
        padding-bottom: 1.8rem;
    }
    .pb-xl-1-9, .py-xl-1-9 {
        padding-bottom: 1.9rem;
    }
    .pb-xl-2-0, .py-xl-2-0 {
        padding-bottom: 2.0rem;
    }
    .pb-xl-2-1, .py-xl-2-1 {
        padding-bottom: 2.1rem;
    }
    .pb-xl-2-2, .py-xl-2-2 {
        padding-bottom: 2.2rem;
    }
    .pb-xl-2-3, .py-xl-2-3 {
        padding-bottom: 2.3rem;
    }
    .pb-xl-2-4, .py-xl-2-4 {
        padding-bottom: 2.4rem;
    }
    .pb-xl-2-5, .py-xl-2-5 {
        padding-bottom: 2.5rem;
    }
    .pb-xl-2-6, .py-xl-2-6 {
        padding-bottom: 2.6rem;
    }
    .pb-xl-2-7, .py-xl-2-7 {
        padding-bottom: 2.7rem;
    }
    .pb-xl-2-8, .py-xl-2-8 {
        padding-bottom: 2.8rem;
    }
    .pb-xl-2-9, .py-xl-2-9 {
        padding-bottom: 2.9rem;
    }

    .pb-xl-6, .py-xl-6 {
        padding-bottom: 3.5rem;
    }
    .pb-xl-7, .py-xl-7 {
        padding-bottom: 4rem;
    }
    .pb-xl-8, .py-xl-8 {
        padding-bottom: 4.5rem;
    }
    .pb-xl-9, .py-xl-9 {
        padding-bottom: 5rem;
    }
    .pb-xl-10, .py-xl-10 {
        padding-bottom: 5.5rem;
    }
    .pb-xl-11, .py-xl-11 {
        padding-bottom: 6rem;
    }
    .pb-xl-12, .py-xl-12 {
        padding-bottom: 6.5rem;
    }
    .pb-xl-13, .py-xl-13 {
        padding-bottom: 7rem;
    }
    .pb-xl-14, .py-xl-14 {
        padding-bottom: 7.5rem;
    }
    .pb-xl-15, .py-xl-15 {
        padding-bottom: 8rem;
    }
    .pb-xl-16, .py-xl-16 {
        padding-bottom: 8.5rem;
    }
    .pb-xl-17, .py-xl-17 {
        padding-bottom: 9rem;
    }
    .pb-xl-18, .py-xl-18 {
        padding-bottom: 9.5rem;
    }
    .pb-xl-19, .py-xl-19 {
        padding-bottom: 10rem;
    }
    .pb-xl-20, .py-xl-20 {
        padding-bottom: 10.5rem;
    }
    .pb-xl-21, .py-xl-21 {
        padding-bottom: 11rem;
    }
    .pb-xl-22, .py-xl-22 {
        padding-bottom: 11.5rem;
    }
    .pb-xl-23, .py-xl-23 {
        padding-bottom: 12rem;
    }
    .pb-xl-24, .py-xl-24 {
        padding-bottom: 12.5rem;
    }

    /* padding left */
    .pl-xl-1-6, .px-xl-1-6 {
        padding-left: 1.6rem;
    }
    .pl-xl-1-7, .px-xl-1-7 {
        padding-left: 1.7rem;
    }
    .pl-xl-1-8, .px-xl-1-8 {
        padding-left: 1.8rem;
    }
    .pl-xl-1-9, .px-xl-1-9 {
        padding-left: 1.9rem;
    }
    .pl-xl-2-0, .px-xl-2-0 {
        padding-left: 2.0rem;
    }
    .pl-xl-2-1, .px-xl-2-1 {
        padding-left: 2.1rem;
    }
    .pl-xl-2-2, .px-xl-2-2 {
        padding-left: 2.2rem;
    }
    .pl-xl-2-3, .px-xl-2-3 {
        padding-left: 2.3rem;
    }
    .pl-xl-2-4, .px-xl-2-4 {
        padding-left: 2.4rem;
    }
    .pl-xl-2-5, .px-xl-2-5 {
        padding-left: 2.5rem;
    }
    .pl-xl-2-6, .px-xl-2-6 {
        padding-left: 2.6rem;
    }
    .pl-xl-2-7, .px-xl-2-7 {
        padding-left: 2.7rem;
    }
    .pl-xl-2-8, .px-xl-2-8 {
        padding-left: 2.8rem;
    }
    .pl-xl-2-9, .px-xl-2-9 {
        padding-left: 2.9rem;
    }

    .pl-xl-6, .px-xl-6 {
        padding-left: 3.5rem;
    }
    .pl-xl-7, .px-xl-7 {
        padding-left: 4rem;
    }
    .pl-xl-8, .px-xl-8 {
        padding-left: 4.5rem;
    }
    .pl-xl-9, .px-xl-9 {
        padding-left: 5rem;
    }
    .pl-xl-10, .px-xl-10 {
        padding-left: 5.5rem;
    }
    .pl-xl-11, .px-xl-11 {
        padding-left: 6rem;
    }
    .pl-xl-12, .px-xl-12 {
        padding-left: 6.5rem;
    }
    .pl-xl-13, .px-xl-13 {
        padding-left: 7rem;
    }
    .pl-xl-14, .px-xl-14 {
        padding-left: 7.5rem;
    }
    .pl-xl-15, .px-xl-15 {
        padding-left: 8rem;
    }
    .pl-xl-16, .px-xl-16 {
        padding-left: 8.5rem;
    }
    .pl-xl-17, .px-xl-17 {
        padding-left: 9rem;
    }
    .pl-xl-18, .px-xl-18 {
        padding-left: 9.5rem;
    }
    .pl-xl-19, .px-xl-19 {
        padding-left: 10rem;
    }
    .pl-xl-20, .px-xl-20 {
        padding-left: 10.5rem;
    }
    .pl-xl-21, .px-xl-21 {
        padding-left: 11rem;
    }
    .pl-xl-22, .px-xl-22 {
        padding-left: 11.5rem;
    }
    .pl-xl-23, .px-xl-23 {
        padding-left: 12rem;
    }
    .pl-xl-24, .px-xl-24 {
        padding-left: 12.5rem;
    }

    /* padding right */
    .pr-xl-1-6, .px-xl-1-6 {
        padding-right: 1.6rem;
    }
    .pr-xl-1-7, .px-xl-1-7 {
        padding-right: 1.7rem;
    }
    .pr-xl-1-8, .px-xl-1-8 {
        padding-right: 1.8rem;
    }
    .pr-xl-1-9, .px-xl-1-9 {
        padding-right: 1.9rem;
    }
    .pr-xl-2-0, .px-xl-2-0 {
        padding-right: 2.0rem;
    }
    .pr-xl-2-1, .px-xl-2-1 {
        padding-right: 2.1rem;
    }
    .pr-xl-2-2, .px-xl-2-2 {
        padding-right: 2.2rem;
    }
    .pr-xl-2-3, .px-xl-2-3 {
        padding-right: 2.3rem;
    }
    .pr-xl-2-4, .px-xl-2-4 {
        padding-right: 2.4rem;
    }
    .pr-xl-2-5, .px-xl-2-5 {
        padding-right: 2.5rem;
    }
    .pr-xl-2-6, .px-xl-2-6 {
        padding-right: 2.6rem;
    }
    .pr-xl-2-7, .px-xl-2-7 {
        padding-right: 2.7rem;
    }
    .pr-xl-2-8, .px-xl-2-8 {
        padding-right: 2.8rem;
    }
    .pr-xl-2-9, .px-xl-2-9 {
        padding-right: 2.9rem;
    }

    .pr-xl-6, .px-xl-6 {
        padding-right: 3.5rem;
    }
    .pr-xl-7, .px-xl-7 {
        padding-right: 4rem;
    }
    .pr-xl-8, .px-xl-8 {
        padding-right: 4.5rem;
    }
    .pr-xl-9, .px-xl-9 {
        padding-right: 5rem;
    }
    .pr-xl-10, .px-xl-10 {
        padding-right: 5.5rem;
    }
    .pr-xl-11, .px-xl-11 {
        padding-right: 6rem;
    }
    .pr-xl-12, .px-xl-12 {
        padding-right: 6.5rem;
    }
    .pr-xl-13, .px-xl-13 {
        padding-right: 7rem;
    }
    .pr-xl-14, .px-xl-14 {
        padding-right: 7.5rem;
    }
    .pr-xl-15, .px-xl-15 {
        padding-right: 8rem;
    }
    .pr-xl-16, .px-xl-16 {
        padding-right: 8.5rem;
    }
    .pr-xl-17, .px-xl-17 {
        padding-right: 9rem;
    }
    .pr-xl-18, .px-xl-18 {
        padding-right: 9.5rem;
    }
    .pr-xl-19, .px-xl-19 {
        padding-right: 10rem;
    }
    .pr-xl-20, .px-xl-20 {
        padding-right: 10.5rem;
    }
    .pr-xl-21, .px-xl-21 {
        padding-right: 11rem;
    }
    .pr-xl-22, .px-xl-22 {
        padding-right: 11.5rem;
    }
    .pr-xl-23, .px-xl-23 {
        padding-right: 12rem;
    }
    .pr-xl-24, .px-xl-24 {
        padding-right: 12.5rem;
    }

}

@media (max-width: 1199px) {
    
    section { padding: 90px 0; }
    section.md { padding: 70px 0; }
    .md-height-auto { height: auto; }

    /* verticle align */
    .md-vertical-align-middle {vertical-align: middle ;}
    .md-vertical-align-top {vertical-align: top ;}
    .md-vertical-align-bottom {vertical-align: bottom ;}
    .md-absolute-middle-inherit {left: 0; top: 0; position: relative; -ms-transform: translateX(0) translateY(0); -moz-transform: translateX(0) translateY(0); -webkit-transform: translateX(0) translateY(0); transform: translateX(0) translateY(0); -o-transform: translateX(0) translateY(0); }

    /* image position*/ 
    .md-background-image-left { background-position: left;}
    .md-background-image-right { background-position: right;}
    .md-background-image-center{ background-position: center;}

    /* display */
    .md-overflow-hidden {overflow:hidden !important;}
    .md-overflow-auto {overflow:auto !important;}
    .md-display-table {display:table !important;}
    .md-display-table-cell {display:table-cell !important;}
    .md-display-flex-inherit { -ms-box-orient: inherit; display: inherit; display: inherit; display: inherit; display: inherit; display: inherit; display: inherit;  flex-flow: inherit; -webkit-flex-flow: inherit; -moz-flex-flow: inherit; -ms-flex-flow: inherit; -o-flex-flow: inherit;}

    /* position */
    .md-position-inherit {position:inherit}
    .md-position-relative {position:relative;}
    .md-position-absolute {position:absolute;}
    .md-position-fixed {position:fixed;}

}

@media (max-width: 1050px) {
    .parallax {background-attachment: inherit;}
}

@media (max-width: 991px) {

    .sm-height-auto { height: auto; }

    /* custom */
    section { padding: 70px 0;}
    section.md { padding: 50px 0; }

    /* verticle align */
    .sm-vertical-align-middle {vertical-align: middle;}
    .sm-vertical-align-top {vertical-align: top;}
    .sm-vertical-align-bottom {vertical-align: bottom;}
    .sm-absolute-middle-inherit {left: 0; top: 0; position: relative; -ms-transform: translateX(0) translateY(0); -moz-transform: translateX(0) translateY(0); -webkit-transform: translateX(0) translateY(0); transform: translateX(0) translateY(0); -o-transform: translateX(0) translateY(0); }    

    /* image position*/ 
    .sm-background-image-left { background-position: left;}
    .sm-background-image-right { background-position: right;}
    .sm-background-image-center{ background-position: center;}

    /* display */
    .sm-overflow-hidden {overflow:hidden !important;}
    .sm-overflow-auto {overflow:auto !important;}
    .sm-display-table {display:table !important;}
    .sm-display-table-cell {display:table-cell !important;}
    .sm-display-flex-inherit { -ms-box-orient: inherit; display: inherit; display: inherit; display: inherit; display: inherit; display: inherit; display: inherit;  flex-flow: inherit; -webkit-flex-flow: inherit; -moz-flex-flow: inherit; -ms-flex-flow: inherit; -o-flex-flow: inherit;}

    /* clear-both */
    .sm-clear-both {clear: both;}

    /* position */
    .sm-position-inherit {position:inherit !important;}
    .sm-position-initial {position:initial !important;}
    .sm-position-relative {position:relative !important;}
    .sm-position-absolute {position:absolute !important;}
    .sm-position-fixed {position:fixed !important;}

    /* parallax */
    .parallax {background-position: center;}
   
    /* opacity */
    .sm-opacity1 {opacity:.1;}
    .sm-opacity2 {opacity:.2;}
    .sm-opacity3 {opacity:.3;}
    .sm-opacity4 {opacity:.4;}
    .sm-opacity5 {opacity:.5;}
    .sm-opacity6 {opacity:.6;}
    .sm-opacity7 {opacity:.7;}
    .sm-opacity8 {opacity:.8;}
    .sm-opacity9 {opacity:.9;}
    .sm-opacity10 {opacity:1;}
 
}

@media (max-width: 767px) {

    body { font-size: 15px; }
    .xs-height-auto { height: auto; }

    /* opacity */
    .xs-opacity1 {opacity:.1;}
    .xs-opacity2 {opacity:.2;}
    .xs-opacity3 {opacity:.3;}
    .xs-opacity4 {opacity:.4;}
    .xs-opacity5 {opacity:.5;}
    .xs-opacity6 {opacity:.6;}
    .xs-opacity7 {opacity:.7;}
    .xs-opacity8 {opacity:.8;}
    .xs-opacity9 {opacity:.9;}
    .xs-opacity10 {opacity:1;}

    /* verticle align */
    .xs-vertical-align-middle {vertical-align: middle;}
    .xs-vertical-align-top {vertical-align: top;}
    .xs-vertical-align-bottom {vertical-align: bottom;}
    .xs-absolute-middle-inherit {left: 0; top: 0; position: relative; -ms-transform: translateX(0) translateY(0); -moz-transform: translateX(0) translateY(0); -webkit-transform: translateX(0) translateY(0); transform: translateX(0) translateY(0); -o-transform: translateX(0) translateY(0); }    

    /* image position*/ 
    .xs-background-image-left { background-position: left !important;}
    .xs-background-image-right { background-position: right !important;}
    .xs-background-image-center { background-position: center !important;}
 
    /* display */
    .xs-overflow-hidden {overflow:hidden !important;}
    .xs-overflow-auto {overflow:auto !important;}
    .xs-display-table {display:table !important;}
    .xs-display-table-cell {display:table-cell !important;}
    .xs-display-flex-inherit { -ms-box-orient: inherit; display: inherit; display: inherit; display: inherit; display: inherit; display: inherit; display: inherit;  flex-flow: inherit; -webkit-flex-flow: inherit; -moz-flex-flow: inherit; -ms-flex-flow: inherit; -o-flex-flow: inherit;}

    /* position */
    .xs-position-inherit {position:inherit}
    .xs-position-relative {position:relative;}
    .xs-position-absolute {position:absolute;}
    .xs-position-fixed {position:fixed;}

}

/* ipad Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .parallax {background-attachment: inherit; background-position: center;}
}

/* ipad Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .parallax {background-attachment: inherit; background-position: center;}
}
