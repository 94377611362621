/* Should you need to overwrite any of our SCSS or LESS or SASS code need to add any custom code, We recommend that you add your code into this file, so whenever theme update is available and you update the theme, You will not lose your hard work :) */
section {
    padding: 100px 0 60px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #00516c;
}

.title-section h2 {
    display: inline-block;
    position: relative;
    &:after {
        content: "";
        width: 70px;
        height: 5px;
        background: #00516c;
        display: block;
        margin: 15px auto 0;
    }
}

.card-style08 {
    padding: 60px 30px 40px 30px;
    p {
        line-height: 25px;
        strong {
            color: #00516c;
        }
    }
    &:hover {
        .icon-circle {
            @include transform(scale(1.1));
        }
    }
    .icon-circle {
        background: rgba(0, 163, 181, 0.2);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        img {
            max-width: 50%;
        }
    }
}

.social-icon01 li a {
    color: white;
    border-color: white;
    &:hover {
        background: white;
        color: #007bff;
    }
}

.copyright {
    font-size: 0.8rem;
}

body.blog,
body.single-post,
.page-template-template-crb-our-partners-page,
.page-template-template-crb-our-offer-page,
.page-template-template-crb-contact-page {
    header {
        position: absolute;
        width: 100%;
    }
}

section ul:not(.slick-dots, .pagination ul, .meta) {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    li {
        position: relative;
        padding: 0 0 0px 40px;
        margin: 0 0 10px 0;
        font-size: 15px;
        &:before {
            content: "\f00c";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            color: #00a3b5;
            font-size: 12px;
            background: rgba(0,132,233,0.1);
            margin-right: 12px;
            border-radius: 30px;
            padding: 6px;
            position: absolute;
            left: 0;
            top: 2px;
            line-height: 12px;
        }
    }
}

.text-content {
    h2, h3, h4 {
        margin-bottom: 25px;
    }
    h2 {
        font-size: 1.6rem;
    }
    h3 {
        font-size: 1.4rem;
    }
    h4 {
        font-size: 1.2rem;
    }
}

.card {
    h3 {
        font-size: 1.6rem;
        margin-bottom: 25px;
    }
}

footer p {
    margin-bottom: 15px;
    i {
        margin-right: 5px;
    }
}

.contact-infos {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    p {
        margin: 0;
    }
    i {
        margin-right: 10px;
    }
}

.page-template-default .transparent-header .navbar-nav > li {
    & > a {
        color: #1d184a !important;
    }

    &.current > a
    , &.active > a {
        color: #00a3b5 !important;
    }
}
